import React, { useEffect } from 'react';
import { capitalizeFirstLetter, useSetState } from 'utils/functions.utils';
import './orders_card.component.scss';
import {
  Assets,
  Functions,
  Models,
  PrimaryButton,
} from '../../utils/imports.utils';
import { SwiperComponent } from '../../utils/imports.utils';
import { paymentType, sareeRate } from 'utils/constant.utils';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface OrderComponent {
  quantity?: number;
  address?: any;
  totalAmount?: number;
  payType?: string;
  status?: boolean;
  orderId?: string;
  data?: any[];
  orderStatus?: string;
  _id?: any;
  expressDelivery?: boolean;
}

const OrdersCardComponent = (props: OrderComponent) => {
  const {
    quantity,
    address,
    totalAmount,
    payType,
    data,
    orderId,
    status,
    orderStatus,
  } = props;
  props;
  const params = {
    spaceBetween: 30,
    centeredSlides: true,
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false,
    // },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    //   renderBullet: (index, className) => {
    //     return '<span class="' + className + '"></span>';
    //   },
    // },
    noSwiping: true,
  };

  // state
  const [state, setState] = useSetState({
    user_data: '',
  });

  //redux

  const userData = useSelector((state:any)=>state.user)
  // route

  const navigate = useNavigate();

  // hooks
 
  // api call

  return (
    <div style={{ minHeight: `${props.orderStatus === "REACHED" && userData?.user?.role === 'pleater' ? '260px' : '250px'}` }} className={`${data && data?.length > 0 && 'order_card_wrapper'}`}>
      <div className="order_card_container">
        {status ? (
          <>
            <div
              className="swiper_wrap"
              onClick={() => navigate(`/order_details?id=${props._id}`)}>
              <SwiperComponent params={params}>
                {data?.map((item: any) => {
                  let sarees = item.images;
                  return sarees.map((images: any, index: number) => {
                    return (
                      <div key={index}>
                        <img
                          src={images}
                          className="saree_image"
                          alt="saree_image"
                        />
                      </div>
                    );
                  });
                })}
              </SwiperComponent>
              {props.expressDelivery && (
              <div className='express_delivery_badge_container'>
                <div>
                  <img src={Assets.express_courier} width={10} height={10} />
                </div>
                <div className='express_courier_badge_content'>
                  EXPRESS DELIVERY
                </div>
              </div>
              )}
            </div>

            <div className="address_wrap">
              <div onClick={() => navigate(`/order_details?id=${props._id}`)}>
                <div className="request-id_quantity_wrap">
                  <div className="request-id_wrap">{orderId}</div>
                  <div className="quantity_wrap">Quantity : {quantity}</div>
                </div>
                <div className="address">{address.map_address}</div>
                <div className="payment_wrap">
                  <div className="lightning_icon">
                    <img
                      src={Assets.lightning_fill}
                      className="lightning_icon"
                    />
                  </div>
                  <div className="pay_on_delivery">
                    {/* {payType === 'COD' ? 'Pay on delivery' : 'Paid online'} */}
                    {capitalizeFirstLetter(
                      orderStatus === 'ORDER CANCELLED'
                        ? 'Pickup delayed'
                        : orderStatus === 'CANCELLED'
                          ? 'Dispatch delayed'
                          : orderStatus ? orderStatus.toLowerCase():"",
                    )}
                  </div>
                </div>
              </div>
              <div className="total_wrap">
                <div className="total_text_wrap">
                  <div className="total_text">Total :</div>
                  <div className="value">{`Rs. ${totalAmount}`}</div>
                </div>
                <div className="reject_accept_buttons_wrap">
                  {userData.user.role === 'pleater' &&
                  orderStatus === 'REACHED' ? (
                    <>
                      <PrimaryButton
                        onClick={() =>
                          navigate(`/deliver_sarees?order_id=${props._id}`)
                        }
                        text="Deliver"
                        width="65px"
                        fontSize={'10px'}
                        fontWeight={'500'}
                        color={'#ffff'}
                        className={'Deliver'}
                        // className="reject_button_wrap"
                      />
                    </>
                  ) : (
                    <>
                      {orderStatus === 'DELIVERED' && (
                        <PrimaryButton
                          text="Delivered"
                          width="65px"
                          fontSize={'10px'}
                          fontWeight={'500'}
                          color={'#00D715'}
                          className={'delivered_button'}
                          // className="reject_button_wrap"
                        />
                      )}
                    </>
                  )}
                  {/* {orderStatus === 'DELIVERED' && (
                    <PrimaryButton
                      text="Delivered"
                      width="65px"
                      fontSize={'10px'}
                      fontWeight={'500'}
                      color={'#00D715'}
                      className={'delivered_button'}
                      // className="reject_button_wrap"
                    />
                  )} */}
                  {/* <PrimaryButton
                  text="Accept"
                  width="65px"
                  fontSize={'10px'}
                  className="accept_button_wrap"
                  fontWeight={'500'}
                /> */}
                  {/* <PrimaryButton text='Deliver' width='65px' fontSize={'10px'} className='accept_button_wrap' fontWeight={'500'} /> */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="no_history_found">No History Found</div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersCardComponent;
