import test from 'models/test.model';
import auth from 'models/auth.model';
import samplework from 'models/sample_work.model';
import address from 'models/address.model';
import coupon from 'models/coupon.model';
import orders from 'models/orders.model';
import review from 'models/review.model';
import payout from 'models/payout.model';
import notification from 'models/notification.model';
import todaydeal from 'models/todaydeal.model';
import config from 'models/config.model';
import feedback from 'models/feedback.model'

export const Models = {
  test,
  auth,
  samplework,
  address,
  coupon,
  orders,
  review,
  payout,
  notification,
  todaydeal,
  config,
  feedback
};

export default Models;
