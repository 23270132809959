import instance from 'utils/axios.utils';

const notification = {
  getNotification: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default notification;
