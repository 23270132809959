import React, { useEffect } from 'react';
import './notification.screen.scss';
import {
  Assets,
  NotificationComponent,
  Constants,
  Footer,
  Models,
  Header,
  Functions,
} from '../../utils/imports.utils';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
import Notiflix from 'notiflix';
import moment from 'moment';

function Notification() {
  // route
  const navigate = useNavigate();

  // state

  const [state, setState] = useSetState({
    data: '',
    notification_data: [],
  });

  // getUser
  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      setState({ data: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // getNotification

  const getNotification = async () => {
    Notiflix.Loading.pulse('Loading...');
    try {
      const res: any = await Models.notification.getNotification();
      let sortNotification = res.data.docs.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setState({ notification_data: sortNotification });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Notiflix.Loading.remove();
    }
  };

  useEffect(() => {
    getUser();
    getNotification();
  }, []);
  return (
    <div>
      <div className="notify_container">
        <div className="heading_container">
          <Header title="Notifications" />
        </div>
        <div className="notification_wrapper">
          {state.notification_data.length > 0 ? (
            state.notification_data?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => navigate(`${item.redirect_to}`)}>
                  <NotificationComponent
                    status={item.title}
                    image={item.image}
                    orderId={item.merge_fields.id}
                    reason={item.body}
                    review={''}
                    time={moment(item.created_at).fromNow()}
                  />
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '80%',
              }}>
              No notifications found
            </div>
          )}
        </div>
      </div>
      <div className="details_footer_container">
        <Footer />
      </div>
    </div>
  );
}
export default Notification;
