import payout from '../models/payout.admin_model';
import users from '../models/users.admin_model';
import bookings from '../models/bookings.admin_model';
import dashboard from '../models/dashboard.admin_model';
import admin_notification from '../models/notification.admin_model';
import coupon from '../models/coupon.admin_model'

export const AdminModel = {
  payout,
  users,
  bookings,
  admin_notification,
  dashboard,
  coupon
};

export default AdminModel;
