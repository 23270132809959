export const sampleConstant = 'sampleConstant';
import { Assets } from '../utils/imports.utils';
import { estimatedTime } from './functions.utils';

export const timeZones = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay',
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg',
];

export const req_status = [
  'On hold',
  'Open',
  'Cancelled',
  'Filled',
  // 'Shortlist',
  // 'Offer accepted',
];

export const skills = [
  'Computer Programming and Coding',
  'Object-Oriented Design',
  'Software Testing and Debugging',
  'Teamwork',
];

export const candidate_status = [
  'Applied',
  'Request for phone screening',
  'Confirm for phone screening',
  'Screening',
  'Longlist',
  'Shortlist',
  'Request for interview availability',
  'Confirm interview availability',
  'Offered',
  'Offer accepted',
  'Offer declined',
  'No show',
  'Withdrawn',
  // 'Interviewing',
  // 'Offered',
  'Joined',
];

export const job_types = [
  'Permanent',
  'Contract',
  'Contract To Hire',
  'Fixed Term',
  'Internship',
];
export const job_role_type = ['Full Time', 'Part Time', 'Seasonal'];

export const job_location = ['Onsite', 'Offsite'];
export const headcount_type = ['New', ' Replacement'];
export const gender = ['Male', 'Female', 'Other'];
export const currency = ['USD', 'INR'];
export const notice_period = [
  { label: 'Immediate', value: 0 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
];

export const willing_to_relocate = ['Yes', 'No'];
export const remote_work_preference = ['Yes', 'No', 'Hybrid'];
export const education_level = ['Bachelor', 'Master'];
export const reasons_for_job_change = ['Another Offer'];
export const behaviorData = [
  'Communication',
  'Problem solving',
  'Multitaksing',
];
export const interview_rounds = [
  'Round 1',
  'Round 2',
  'Round 3',
  'Round 4',
  'Round 5',
];
export const overall_feedback = ['On hold'];
export enum CANDIDATE_STATUS {
  APPLIED = 'Applied',
  REQUEST_FOR_PHONE_SCREENING = 'Request for phone screening',
  CONFIRM_FOR_PHONE_SCREENING = 'Confirm for phone screening',
  SCREENING = 'Screening',
  LONGLIST = 'Longlist',
  SHORTLIST = 'Shortlist',
  REQUEST_FOR_INTERVIEW_AVAILABILITY = 'Request for interview availability',
  CONFIRM_INTERVIEW_AVAILABILITY = 'Confirm interview availability',
  OFFERED = 'Offered',
  OFFER_ACCEPTED = 'Offer accepted',
  OFFER_DECLINED = 'Offer declined',
  INTERVIEWING = 'Interviewing',
  NO_SHOW = 'No show',
  WITHDRAWN = 'Withdrawn',
  JOINED = 'Joined',
}

export enum REQUISITION_STATUS {
  OPEN = 'Open',
  ON_HOLD = 'On hold',
  CANCELLED = 'Cancelled',
  FILLED = 'Filled',
  DRAFT = 'Draft',
}

export enum MapTypeId {
  Roadmap = 'roadmap',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
  Terrain = 'terrain',
}

export enum InterviewMedium {
  IN_PERSON = 'In person',
  PHONE = 'Phone',
  VIDEO = 'Video call',
}

export const feminine_coded_words = [
  'agree',
  'affectionate',
  'child',
  'cheer',
  'collab',
  'commit',
  'communal',
  'compassion',
  'connect',
  'considerate',
  'cooperat',
  'co-operat',
  'depend',
  'emotiona',
  'empath',
  'feel',
  'flatterable',
  'gentle',
  'honest',
  'interpersonal',
  'interdependen',
  'interpersona',
  'inter-personal',
  'inter-dependen',
  'inter-persona',
  'kind',
  'kinship',
  'loyal',
  'modesty',
  'nag',
  'nurtur',
  'pleasant',
  'polite',
  'quiet',
  'respon',
  'sensitiv',
  'submissive',
  'support',
  'sympath',
  'tender',
  'together',
  'trust',
  'understand',
  'warm',
  'whin',
  'enthusias',
  'inclusive',
  'yield',
  'share',
  'sharin',
];

export const masculine_coded_words = [
  'active',
  'adventurous',
  'aggress',
  'ambitio',
  'analy',
  'assert',
  'athlet',
  'autonom',
  'battle',
  'boast',
  'challeng',
  'champion',
  'compet',
  'confident',
  'courag',
  'decid',
  'decision',
  'decisive',
  'defend',
  'determin',
  'domina',
  'dominant',
  'driven',
  'fearless',
  'fight',
  'force',
  'greedy',
  'head-strong',
  'headstrong',
  'hierarch',
  'hostil',
  'impulsive',
  'independen',
  'individual',
  'intellect',
  'lead',
  'logic',
  'objective',
  'opinion',
  'outspoken',
  'persist',
  'principle',
  'reckless',
  'self-confiden',
  'self-relian',
  'self-sufficien',
  'selfconfiden',
  'selfrelian',
  'selfsufficien',
  'stubborn',
  'superior',
  'unreasonab',
];

export const hyphenated_coded_words = [
  'co-operat',
  'inter-personal',
  'inter-dependen',
  'inter-persona',
  'self-confiden',
  'self-relian',
  'self-sufficien',
];

export const possible_codings = [
  'strongly feminine-coded',
  'feminine-coded',
  'neutral',
  'masculine-coded',
  'strongly masculine-coded',
];

export const explanations = {
  'strongly feminine-coded':
    'This job ad uses more words that are subtly coded as feminine than words that are subtly coded as masculine (according to the research). Fortunately, the research suggests this will have only a slight effect on how appealing the job is to men, and will encourage women applicants.',
  'feminine-coded':
    'This job ad uses more words that are subtly coded as feminine than words that are subtly coded as masculine (according to the research). Fortunately, the research suggests this will have only a slight effect on how appealing the job is to men, and will encourage women applicants.',
  neutral:
    "This job ad uses an equal number of words that are subtly coded as masculine and feminine (according to the research). It probably won't be off-putting to men or women applicants.",
  'masculine-coded':
    'This job ad uses more words that are subtly coded as masculine than words that are subtly coded as feminine (according to the research). It risks putting women off applying, but will probably encourage men to apply.',
  'strongly masculine-coded':
    'This job ad uses more words that are subtly coded as masculine than words that are subtly coded as feminine (according to the research). It risks putting women off applying, but will probably encourage men to apply.',
  empty:
    "This job ad doesn't use any words that are subtly coded as masculine or feminine (according to the research). It probably won't be off-putting to men or women applicants.",
};

export const notification_data = [
  {
    status: 'Item reached us',
    orderId: 'Your order ID 3456 has been received',
    time: '2m ago',
  },
  {
    status: 'Item reached us',
    orderId: 'Your order ID 3456 has been received',
    time: 'Yesterday 12.30 PM',
    reason: 'View reason',
  },
  {
    status: 'Item reached us',
    orderId: 'Your order ID 3456 has been received',
    time: 'Yesterday 12.30 PM',
    review: 'Add review',
  },
];

export const paymentType = [
  // { value: 'ONLINE', label: 'Pay Online', img: Assets.gpay },
  { value: 'COD', label: 'Pay on delivery', img: Assets.pay_on_delivery },
];

export const sareeRate:any = {
  firstOne: 499,
  secondOne: 300,
};

export const deliveryCharge = 100;

export const expressDelivery = 99;

export const pleaterCharge = 200;

export const relaxDeliveryCharge = 90

export const FIRSTPLEAT = "FIRSTPLEAT"

export enum COUPON_TYPE {
  REFERRAL = 'REFERRAL',
  FIRST_TIME = 'FIRST_TIME',
}

export const Orders_data = [
  {
    requestId: 'ID000145',
    quantity: '3',
    address: 'No:234,Pillayar Kovil street,Chennai-34',
    totalAmount: '165',
  },
  {
    requestId: 'ID000145',
    quantity: '3',
    address: 'No:234,Pillayar Kovil street,Chennai-34',
    totalAmount: '165',
  },
];

export const bodyCheckboxData = [
  {
    value: 'Skubby',
    label: 'Skubby',
    disabled: Assets.lean_uncheck,
    active: Assets.lean_checked,
  },
  {
    value: 'Plumpy',
    label: 'Plumpy',
    disabled: Assets.avg_uncheck,
    active: Assets.avg_checked,
  },
  {
    value: 'Chunky',
    label: 'Chunky',
    disabled: Assets.above_avg_uncheck,
    active: Assets.above_avg_checked,
  },
];

export const bodyHeight = [
  {
    value: 'below 5ft',
    label: 'Below 5ft',
  },
  {
    value: '5 to 5.5ft',
    label: '5 to 5.5ft',
  },
  {
    value: 'above 5.5ft',
    label: 'Above 5.5ft',
  },
];

export const textData = [
  'Your nearest Dunzo or Porter or BlueDart delivery partner will pick up your saree(s) order shortly.',
  'Please send it in a bigger size bag/cover to ensure the intactness of the saree.',
  'Please do not give cash to the delivery partners, as it is paid by Pleat!.',
  'The order will be delivered within 24 hours of booking.',
  'Special occasions and weather might affect the pick-up/delivery.',
  'Please confirm to proceed with your booking.',
];

export const quoteError = [
  'unserviceable_location_error',
  'different_city_error',
  'Apologies, our services are limited to serviceable areas with in the city only.',
  "Pickup Location is not serviceable",
  'Location not serviceable',
  'Pleater not found this location',
];

export const hipNotesContent = [
  "To provide your accurate hip size, note that it is different from your waist size. Use a measuring tape to measure around the widest part of your hip or consider measuring around your shoulder for reference."
]

export const numOfPleatContent  = [
  "For selecting the number of pleats, choose according to your build. If lean to medium, choose 9-12 pleats. If medium to healthy, choose 6-9 pleats. Or feel free to experiment with your style the way you want!"
]
export enum OTP_SHOW_LOGIC {
  WAITING_FOR_PICKUP = 'WAITING FOR PICKUP',
  PICKED_UP = 'PICKED UP',
  REACHED = 'REACHED',
  WAITING_FOR_DISPATCH = 'WAITING FOR DISPATCH',
  DISPATCH = 'DISPATCH',
  DELIVERED = 'DELIVERED',
}

export enum DELIVERY_VENDOR{
  SHIPROCKET = "SHIPROCKET",
}


export const courierCardData = [
  // {
  //   serviceHeading: "EXPRESS DELIVERY",
  //   deliveryTimingContent: "Delivery within 12hrs",
  //   deliveryFeeContent: `Additional fee of ${expressDelivery} per saree will be applicable`,
  //   icon:Assets.express_courier,
  //   businessHours: `Delivery will be made between 6 AM in the morning and 9 PM in the evening.`,
  // },
  {
    serviceHeading: "STANDARD DELIVERY",
    deliveryTimingContent: "Delivery within 2 Business Days",
    deliveryFeeContent: "No extra charges",
    icon:Assets.standard_delivery
  },
  {
    serviceHeading: "RELAX DELIVERY",
    deliveryTimingContent: "Delivery within 6 - 7 Business days",
    deliveryFeeContent: `Additional fee of ₹${relaxDeliveryCharge} per order will be applicable`,
    icon:Assets.relax_delivery
  },
];


export enum COURIER_DELIVERY_TYPES {
  EXPRESS_DELIVERY = "EXPRESS DELIVERY",
  STANDARD_DELIVERY = "STANDARD DELIVERY",
  RELAX_DELIVERY = "RELAX DELIVERY",
}


export const nonBusinessHours = {
  from: '21:00',
  to: '06:00'
}

export enum DELIVERY_STATUS {
  ORDER_PLACED = 'ORDER PLACED',
  WAITING_FOR_PICKUP = 'WAITING FOR PICKUP',
  PICKED_UP = 'PICKED UP',
  REACHED = 'REACHED',
  WAITING_FOR_DISPATCH = 'WAITING FOR DISPATCH',
  DISPATCH = 'DISPATCH',
  DELIVERED = 'DELIVERED',
  ORDER_CANCELLED = 'ORDER CANCELLED',
  CANCELLED = 'CANCELLED'
}
export const GOOGLE_MAPS_API_KEY = "AIzaSyB9oN_MqcvmtJg1qbua2PhkwiXICawJdZI"
