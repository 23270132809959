export const TEST_ACTION = 'TEST_ACTION';
export const CONVERSATION = 'CONVERSATION';
export const GET_USER = 'GET_USER';
export const BOOKING_DATA = 'BOOKING_DATA';
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const TOTAL_PRICE = 'TOTAL_PRICE';
export const COUPON_VERIFY = 'COUPON_VERIFY';
export const ITEM_PRICE = 'ITEM_PRICE';
export const EXPRESS_DELIVERY = 'EXPRESS_DELIVERY';
export const ACTIVE_TAB = 'ACTIVE_TAB';
export const TAB_ACTIVE_DATA = 'TAB_ACTIVE_DATA';
export const OFFER_REDUCE_AMOUNT = 'OFFER_REDUCE_AMOUNT';
export const USED_COUPON = "USED_COUPON";
export const STORE_BODY_SIZE_DATA = 'STORE_BODY_SIZE_DATA';
export const STORE_BODY_HEIGHT = 'STORE_BODY_HEIGHT';
export const STORE_NOTES = 'STORE_NOTES';
export const NUM_OF_PLEATES = "NUM_OF_PLEATES";
export const STORE_PREVIOUS_BOOKING_DATA = 'STORE_PREVIOUS_BOOKING_DATA';
export const APPLY_FOR_ALL ="APPLY_FOR_ALL"
export const START_DATE ="START_DATE"
export const END_DATE ="END_DATE"
