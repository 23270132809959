import instance from 'utils/axios.utils';

const samplesWork = {
  getSamplesWork: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'samplework/get_many_samplework';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getSampleWorkById: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'samplework/get_samplework';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default samplesWork;
