import React from 'react';
import './get_started.screen.scss';
import {
  Assets,
  PrimaryButton,
  SwiperComponent,
} from '../../utils/imports.utils';
import '../../common_components/hoc/mobile_screen.scss';


function Startedpage() {
  return (
    <div className='mobile_screen'>
      <SwiperComponent>
        <div>
          <div className="get_started_container">
            <div className="get_started_skip">Skip</div>
            <div className="get_started_landing_img">
              <img
                width={'100%'}
                src={Assets.bookOrder}
                alt="get_started_image"
              />
            </div>
            <div className="get_started_content_heading">Book Your Order</div>
            <div className="get_started_content">
              Book an order for a perfect pleating service for your saree type
            </div>
            <div className="get_started_box">
              <div className="box1"></div>
              <div className="box"></div>
              <div className="box"></div>
            </div>
            <div className="get_started_button" id="button1">
              <PrimaryButton text={'Next'} />
            </div>
          </div>
        </div>
        <div>
          <div className="get_started_container">
            <div className="get_started_skip">Skip</div>
            <div className="get_started_landing_img">
              <img
                width={'100%'}
                src={Assets.bookOrder}
                alt="get_started_image"
              />
            </div>
            <div className="get_started_content_heading">Book Your Order</div>
            <div className="get_started_content">
              Book an order for a perfect pleating service for your saree type
            </div>
            <div className="get_started_box">
              <div className="box"></div>
              <div className="box1"></div>
              <div className="box"></div>
            </div>
            <div className="get_started_button" id="button1">
              <PrimaryButton text={'Next'} />
            </div>
          </div>
        </div>
        <div>
          <div className="get_started_container">
            <div className="get_started_skip">Skip</div>
            <div className="get_started_landing_img">
              <img
                width={'100%'}
                src={Assets.bookOrder}
                alt="get_started_image"
              />
            </div>
            <div className="get_started_content_heading">Book Your Order</div>
            <div className="get_started_content">
              Book an order for a perfect pleating service for your saree type
            </div>
            <div className="get_started_box">
              <div className="box"></div>
              <div className="box"></div>
              <div className="box1"></div>
            </div>
            <div className="get_started_button" id="button1">
              <PrimaryButton text={'Next'} />
            </div>
          </div>
        </div>
      </SwiperComponent>
    </div>
  );
}

export default Startedpage;
