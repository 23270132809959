import React from 'react';
import { Assets } from 'utils/imports.utils';
import './sample_works.components.scss';

interface ISampleWork {
  text?: String;
  title: String;
  type?: String;
  source: any;
}

const SampleWork = (props: ISampleWork) => {
  // Redux

  // State

  //Hooks

  // Network req

  //Logic

  return (
    <div className="sampleworks_container">
      <div className="sampleworks_video_container">
        <img src={props.source} />
      </div>
      <div className="sampleworks_footer">
        <div className="sampleworks_footer_wrapper">
          <div className="sampleworks_footer_text1">{props.title}</div>
          <div className="sampleworks_footer_text2">{props.type}</div>
        </div>
        <div className="sampleworks_footer_like" style={{display:'none'}}>
          <img src={Assets.like} />
        </div>
      </div>
    </div>
  );
};

export default SampleWork;
