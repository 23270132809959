import React, { useEffect, useRef,useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSetState, useQuery, getValue, isMobileApp, getBaseURL, isAndroid, isDisableBookingButton, expressDeliveryRate, estimatedTime } from 'utils/functions.utils';
import {
  Models,
  Assets,
  PrimaryButton,
  Header,
  TextArea,
  FileUpload,
  Functions,
  CheckBox,
  SingleSliderComponent,
  MapPicker,
  Colors,
  TooltipComponent,
  CourierCardComponent,
} from 'utils/imports.utils';
import {
  storeTotalAmount,
  storeTotalPrice,
  testDispatch,
  storeCouponData,
  storeItemPrice,
  storeExpressDelivery,
  storeOfferReduceAmount,
  storeUsedCoupon,
  storeBodySizeData,
  storeBodyHeightData,
  storeNotes,
  storeNumberOfPleates,
  storePrevBookingData,
  storeApplyForAll
} from 'utils/redux.utils';
import './booking.screen.scss';
import { Route, useNavigate } from 'react-router-dom';
import {
  paymentType,
  sareeRate,
  deliveryCharge,
  expressDelivery,
  bodyCheckboxData,
  bodyHeight,
  pleaterCharge,
  hipNotesContent,
  numOfPleatContent,
  DELIVERY_VENDOR,
  courierCardData,
  COURIER_DELIVERY_TYPES,
  relaxDeliveryCharge,
  FIRSTPLEAT,
} from '../../utils/constant.utils';
import { removeAmountKey } from 'utils/functions.utils';
import Notiflix from 'notiflix';
import _ from 'lodash';
import Confirmation from '../../common_components/ui/confirmation/confirmation.component';
import SelectCoupon from '../coupon/select_coupon.screen';
import { nanoid } from '../../utils/functions.utils';
import {
  createOreder,
  createPayment,
  paymentStatus,
} from 'models/payment.model';
import { storeBookingData } from '../../utils/redux.utils';
import OutsideClickHandler from 'react-outside-click-handler';
import { PuffLoader } from 'react-spinners';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import toast from 'react-hot-toast';
import {AccordionWrapper, AccordionItem} from 'custom-react-accordion'
import 'custom-react-accordion/dist/Accordion.css'
import moment from 'moment';
import {quoteError} from '../../utils/constant.utils'
import LogRocket from 'logrocket';


interface IBooking {
  text?: String;
}

const Booking = (props: IBooking) => {
  // Redux
  const booking = useSelector((state: any) => state.booking);

  // route
  const navigate = useNavigate();

  // State
  const [state, setState] = useSetState({
    saree_data: [],
    limit: 1,
    notes: '',
    file: '',
    coupon_code: '',
    express_delivery: false,
    payment_active: 'ONLINE',
    price: 0,
    totalAmount: 0,
    delivery_charge: deliveryCharge,
    offer: '',
    coupon: [],
    coupon_cancel: true,
    address: '',
    order_confirm: false,
    hide_booking: false,
    coupon_screen_show: false,
    coupon_error: '',
    coupon_verify: null,
    item_price: null,
    setLoading: false,
    user_data: '',
    bodySize_data: '',
    bodyHeight_data: [],
    number_of_pleat: 8,
    filter_saree_data: [],
    loading: false,
    apply_all:false,
    quote_data:'',
    quote_error:'',
    config_data:'',
    show_map:'',
    map_loading:true,
    localStorage:false,
    hipSizeContentShow:'',
    numOfPleatContentShow:'',
    courierQuoteErr:'',
    deliveryMode:'',
    addPincodeShow:false,
  });
  // useRef

  // const fileUploadRef: any = useRef();

  const sareeUploadRef: any = useRef();
  const selectCouponRef:any = useRef()

  //Hooks
  useEffect(() => {
    Notiflix.Notify.init({
      position: 'center-top',
      distance: '50px',
    });
  }, []);

  useEffect(() => {
    addAnotherSarees();
    totalPrice();
  }, [state.limit, state.price, state.totalAmount, state.express_delivery]);

  useEffect(() => {
    if (isMobileApp()) {
      if (isAndroid()) {
        document.addEventListener('message', ((message: any) => {
          let parsedMessage = JSON.parse(message.data)
          if (parsedMessage.status === "Payment suceess") {
            setState({ loading: false });
            window.location.pathname = '/successful';
          } else if (parsedMessage.status === "Payment failed") {
            setState({ loading: false });
            Functions.notiflixFailure("Failed to make payment. Please try again later");
            Functions.notiflixRemove();
          }
        }))
      } else {
        window.addEventListener('message', ((message: any) => {
          let parsedMessage = JSON.parse(message.data)
          if (parsedMessage.status === "Payment suceess") {
            setState({ loading: false });
            window.location.pathname = '/successful';
          } else if (parsedMessage.status === "Payment failed") {
            setState({ loading: false });
            Functions.notiflixFailure("Failed to make payment. Please try again later");
            Functions.notiflixRemove();
          }
        }))
      }
    }
    return () => {
      if (isMobileApp()) {
        if (isAndroid()) {
          document.removeEventListener('message', ((message) => { }))
        } else {
          window.removeEventListener('message', ((message) => { }))
        }
      }
    };
  }, []);



  useEffect(() => {
    getAddress();
    getUser();
    getConfig();
    checkLocalStorage()
    if (booking.data.length > 0) {
      setState({saree_data: booking.data});
      setState({ limit: booking.data.length });
    } else {
      addAnotherSarees();
    }
    setState({
      totalAmount: booking.totalAmount,
      price: booking.totalPrice,
      item_price: booking.item_price,
      coupon_verify: booking.coupon_verify,
      express_delivery: booking.express_delivery,
      offer_reduce_amount: booking.offer_reduce_amount,
      bodyHeight_data:booking.bodyHeight,
      bodySize_data:booking.storeBodySizeData,
      notes:booking.notes,
      number_of_pleat: booking.num_of_pleates,
      apply_all:booking.apply_for_all
    });
    totalPrice();
    setTimeout(()=>{
      setState({map_loading:false})
    },1000)
  }, []);

  useEffect(() => {
    if (state.payment_active === 'ONLINE') {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onerror = () => {
        console.log('Razorpay SDK failed to load. Are you online?');
      };
      document.body.appendChild(script);
    }
  }, [state.payment_active]);

  // Network req

  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      setState({ user_data: res.data });
      if (window.location.href == 'https://app.pleat.co.in/booking') {
        LogRocket.init('pleat-alt/pleat');
        LogRocket.identify(res.data.phone);
        console.log('logrocket initialized');
      } else {
        console.log('logrocket not initialized');
      }
      
      if (res.data.role === 'pleater') {
        navigate('/orders');
      }
    } catch (err: any) {
      // Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const couponVerify = async (value) => {
    Functions.notiflixLoader();
    let item_price: any;
    let total_amount: any;
    let used_coupon: any = [];
    used_coupon.push(value);
    storeUsedCoupon(used_coupon);
    try {
      let filterData = state.saree_data.filter((item: any) => {
        return item.images.length > 0;
      });
      let quantity = filterData.length;
      let body: any = {
        code: value,
        quantity: quantity,
      };
      if (state.saree_data[0].images.length === 0) {
        Functions.notiflixFailure('Upload your saree images');
      } else {
        if (
          state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY &&
          value === FIRSTPLEAT
        ) {
          toast.error("Invalid coupon code")
          return
        } 
        const res: any = await Models.coupon.couponVerify(body);
        toast.success(res.message);
        storeCouponData(res.data);
        setState({ coupon_verify: res.data });
        item_price = state.totalAmount;
        setState({ item_price: state.totalAmount });
        storeItemPrice(item_price);
        total_amount = state.totalAmount * (res.data.offer_value / 100);
        let offer_reduce_amount = total_amount;
        storeOfferReduceAmount(offer_reduce_amount);
        setState({ offer_reduce_amount: total_amount });
        if (total_amount > state.totalAmount) {
          let sumOfValue = total_amount - state.totalAmount;
          setState({ totalAmount: sumOfValue });
          storeTotalAmount(sumOfValue);
        } else {
          let sumOfValue = state.totalAmount - total_amount;
          setState({ totalAmount: sumOfValue });
          storeTotalAmount(sumOfValue);
        }
        selectCouponRef.current.closeModal()
     
      }
    } catch (error: any) {
      toast.error(error);
      setState({ coupon_error: error });
      setTimeout(() => {
        setState({ coupon_error: null });
      }, 2000);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // razory payment
  const DisplayRazorpay = async (currency?) => {
    let body:any;
    Functions.notiflixLoader();
    try {
      setState({ setLoading: true });
      const payAmount: any = Math.round(parseInt(state.price) + expressDeliveryRate(state.deliveryMode, state.saree_data))
      // creating a new order
      const order: any = await createOreder({
        amount: payAmount.toString() * 100,
        currency,
        receipt: nanoid(),
      });
        body = {
        quantity: state.saree_data.length,
        amount: payAmount,
        payment_type: state.payment_active,
        pickup_sarees: state.saree_data,
        express_delivery: state.express_delivery,
        razorpay_order_id:order.data.id
      };
      if (state.coupon_verify !== null) {
        body.coupon = state.coupon_verify._id;
      }

      if (state.express_delivery) {
        let deliveryCharge: any = {
          express_delivery: expressDelivery,
          pleater_charge: pleaterCharge,
        };
        body.delivery_charges = deliveryCharge;
      } else {
        body.delivery_charges = {
          pleater_charge: pleaterCharge * state.saree_data.length,
        };
      }
      if (state.deliveryMode) {
        body['delivery_mode'] = state.deliveryMode;
        if(state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY) {
          body['delivery_vendor'] = DELIVERY_VENDOR.SHIPROCKET;
          body['courier_delivery_charge'] = relaxDeliveryCharge;
        } 
        if (state.deliveryMode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY) {
          body['express_delivery_details'] = {
            express_delivery_charge: expressDeliveryRate(state.deliveryMode, state.saree_data),
            quantity: state.saree_data.length,
            express_delivery_rate: expressDelivery
          }
        }
      }
      const storeBooking:any = await Models.orders.storeOrders(body)

      // callback
      const handleRazorPayCallback = async (response:any) => {
          window.location.pathname = '/successful';
          setState({ setLoading: false });
          Functions.notiflixRemove();
      };
      
      const options = {
        key: 'rzp_live_qCeNxIY4usxatB', // Enter the Key ID generated from the Dashboard rzp_test_771iEtUnnCuP73 rzp_live_qCeNxIY4usxatB
        amount:order.data.amount ,
        currency: 'INR',
        name: 'PLEAT',
        description: 'Pleat Saree booking',
        order_id:order.data.id,
        payment: {
          capture: 'automatic',
          capture_options: {
            automatic_expiry_period: 12,
            manual_expiry_period: 7200,
            refund_speed: 'optimum',
          },
        },
        // image: { Logo },
        // order_id: nanoid(),
        handler: handleRazorPayCallback,
        theme: {
          color: '#9C312B',
        },
      };

      if (isMobileApp()) {
        setState({ loading: true });
        let body: any = {
          quantity: state.saree_data.length,
          amount: Math.round(payAmount),
          payment_type: state.payment_active,
          pickup_sarees: state.saree_data,
          express_delivery: state.express_delivery,
        };
        if (state.coupon_verify !== null) {
          body.coupon = state.coupon_verify._id;
        }
        // if (state.notes.length > 0) {
        //   body.notes.notes = state.notes;
        // }
        if (state.express_delivery) {
          let deliveryCharge: any = {
            express_delivery: expressDelivery,
            pleater_charge: pleaterCharge,
          };
          body.delivery_charges = deliveryCharge;
        } else {
          body.delivery_charges = {
            pleater_charge: pleaterCharge * state.saree_data.length,
          };
        }
        if (state.deliveryMode) {
          body['delivery_mode'] = state.deliveryMode;
          if(state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY) {
            body['delivery_vendor'] = DELIVERY_VENDOR.SHIPROCKET;
            body['courier_delivery_charge'] = relaxDeliveryCharge;
          } 
          if (state.deliveryMode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY) {
            body['express_delivery_details'] = {
              express_delivery_charge: expressDeliveryRate(state.deliveryMode, state.saree_data),
              quantity: state.saree_data.length,
              express_delivery_rate: expressDelivery
            }
          }
        }
        console.log(body, options)
        {  /*// @ts-ignore */}
      window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            message: 'payment',
            paymentData: options,
            paymentBody: body,
            token: localStorage.getItem('token'),
            server: getBaseURL(),
          }),
        );
      } else {
        const windows: any = window;
        const paymentObject = new windows.Razorpay(options);
        paymentObject.open();
      }
    } catch (err: any) {
      setState({ setLoading: false });
      Functions.notiflixFailure(err.data ? err.data.message : err.message)
    }
  };

  // create booking
  const createBooking = async () => {
    Functions.notiflixLoader();
    let courier = {}
    try {
     if (state.apply_all) {
       const data = state.saree_data;
       data.forEach((item: any, index: number) => {
         data[index]['body_size'] = data[0]['body_size'];
         data[index]['body_height'] = data[0]['body_height'];
         data[index]['number_of_pleat'] = data[0]['number_of_pleat'];
         data[index]['notes'] = data[0]['notes'];
       });
       setState({ saree_data: data });
       storeBookingData(data);
     }
      const checkBodyHeigt:any = state.saree_data.every((height:any)=> height.body_height.length>0)
      const checkBodySize:any = state.saree_data.every((size:any)=>size.body_size!==0)
      const checkNumOfPleat:any = state.saree_data.every((num:any)=>num.number_of_pleat!==0)

       if (state.saree_data[state.limit-1].images.length === 0) {
         Functions.notiflixFailure('Upload your saree images');
         setTimeout(() => {
           setState({ hide_booking: false });
         }, 1000);
       } else if (!checkBodySize) {
         Functions.notiflixFailure('Please select your hip size');
         setTimeout(() => {
           setState({ hide_booking: false });
         }, 1000);
       } else if (!checkNumOfPleat) {
         Functions.notiflixFailure('Please select the number of pleats');
         setTimeout(() => {
           setState({ hide_booking: false });
         }, 1000);
       } else if (!checkBodyHeigt) {
         Functions.notiflixFailure('Please select your body height');
         setTimeout(() => {
           setState({ hide_booking: false });
         }, 1000);
       }
       else {
         setState({ hide_booking: false });
         if (state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY) courier['courier'] = true
         const findPleater: any = await Models.orders.findAvailablePleater(courier);
         if (findPleater.status !== 422) {
           if (state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY) await Models.orders.courierQuote({ quantity: state.saree_data?.length })
           const payAmount: any = Math.round(parseInt(state.price) + expressDeliveryRate(state.deliveryMode, state.saree_data))
           if (state.user_data.address.length > 0) {
             if (state.payment_active === 'ONLINE' && payAmount !== 0) {
               await DisplayRazorpay();
             } else {
               const body: any = {
                 quantity: state.saree_data.length,
                 amount: Math.round(payAmount),
                 payment_type: state.payment_active,
                 // delivery_charge: deliveryCharge,
                 pickup_sarees: state.saree_data,
                 express_delivery: state.express_delivery,
                //  notes: {
                //    body_size: state.bodySize_data,
                //    body_height: getValue(state.bodyHeight_data),
                //    number_of_pleat: state.number_of_pleat,
                //  },
               };
               if (state.coupon_verify !== null) {
                 body.coupon = state.coupon_verify._id;
               }
              //  if (state.notes.length > 0) {
              //    body.notes.notes = state.notes;
              //  }
               if (state.express_delivery) {
                 let deliveryCharge: any = {
                   express_delivery: expressDelivery,
                   pleater_charge: pleaterCharge,
                 };
                 body.delivery_charges = deliveryCharge;
               } else {
                 body.delivery_charges = {
                   pleater_charge: pleaterCharge * state.saree_data.length,
                 };
               }
               if (state.deliveryMode) {
                body['delivery_mode'] = state.deliveryMode;
                if(state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY) {
                  body['delivery_vendor'] = DELIVERY_VENDOR.SHIPROCKET;
                  body['courier_delivery_charge'] = relaxDeliveryCharge;
                }
                 if (state.deliveryMode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY) {
                   body['express_delivery_details'] = {
                     express_delivery_charge: expressDeliveryRate(state.deliveryMode, state.saree_data),
                     quantity: state.saree_data.length,
                     express_delivery_rate: expressDelivery
                   }
                 }
              }
               const res: any = await Models.orders.createOrders(body);
               window.location.pathname = '/successful';
             }
           } else {
             Functions.notiflixFailure('Add your address');
             setTimeout(() => {
               setState({ hide_booking: true });
             }, 1000);
           }
         } else {
           Functions.notiflixFailure(findPleater.message);
         }
       }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getQoute = async (address: any) => {
    if (address.length > 0) {
      try {
        await Models.orders.findAvailablePleater()
        const res: any = await Models.orders.getQuote();
        setState({quote_data:res.data})
      } catch (err:any) {
        let removeErrorString:string =  err.replaceAll('Error:','').trim()
        setState({quote_error:removeErrorString})
        console.log('err', err);
      }
    }
  };

  const getConfig = async () => {
    try {
      const res: any = await Models.config.getConfig();
      setState({ config_data: res.data });
    } catch (err: any) {
      console.log('err', err);
    }
  };

  // check localStorage

  const checkLocalStorage = async () => {
    if (localStorage.token) {
      setState({ localStorage: true });
    }
  };

  const getAddress = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.address.getAddress();
      setState({ address: res.data.address });
      getQoute(res.data.address)
      const latlng = {
        lat: res.data.address[0].location.coordinates[0],
        long: res.data.address[0].location.coordinates[1],
      };
      setState({ show_map: latlng });
    } catch (err: any) {
    } finally {
      Functions.notiflixRemove();
    }
  };

  //Logic

  const addAnotherSarees = async () => {
    let saree_data: any = [];
    for (let i = 0; i < state.limit; i++) {
      saree_data.push({
        name: state.saree_data[i]?.name || '',
        images: state.saree_data[i]?.images || [],
        amount: state.saree_data[i]?.amount || '',
        notes:state.saree_data[i]?.notes||'',
        body_size: state.saree_data[i]?.body_size || 26,
        body_height: state.saree_data[i]?.body_height || '',
        number_of_pleat: state.saree_data[i]?.number_of_pleat || 6,
      });
    }
    setState({ saree_data });
  };

  const removeApplyCoupon = async () => {
    Functions.notiflixLoader();
    await setState({ totalAmount: state.item_price });
    let totalAmount = state.item_price;
    storeTotalAmount(totalAmount);
    // storeTotalAmount(state.totalAmount);
    setState({ coupon_verify: null });
    storeUsedCoupon('');
    storeCouponData(null);
    Functions.notiflixRemove();
  };

  let couponFilter: any;

  const viewCoupon = async () => {
    Functions.notiflixLoader();
    // if (state.saree_data[state.limit - 1].images.length === 0) {
    //   Functions.notiflixFailure('Please upload remaining saree images');
    //   Functions.notiflixRemove();
    // } else {
      selectCouponRef.current.openModal();
      try {
        setState({coupon:[]})
        let filterData = state.saree_data.filter((item: any) => {
          return item.images.length > 0;
        });
        setState({ filter_saree_data: filterData });
        const body = {
          quantity: filterData.length,
        };
        const res: any = await Models.coupon.getCoupon(body);
        let sortCoupon = res.data.sort((a: any, b: any) => {
          return a.offer_value - b.offer_value;
        });
        if(state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY ) {
          sortCoupon = _.filter(sortCoupon,(e:any)=> e.code !== FIRSTPLEAT)
        }
        setState({ coupon: sortCoupon });
      } catch (err: any) {
        // Functions.notiflixFailure(err);
        console.log(err);
      } finally {
        Functions.notiflixRemove();
      }
    // }
  };

  const removeAddSarees = (index: number) => {
    let data: any = state.saree_data;
    data.splice(index, 1);
    setState({ saree_data: data });
    setState({ limit: state.limit - 1 });
    remainingData = state.saree_data;
    storeBookingData(remainingData);
    sumOfAmount();
  };

  const paymentTypeHandler = (item: any, index: number) => {
    setState({ payment_active: item.value });
  };

  // variables
  let indexNumber: number;
  let remainingData: any;

  const fileHandle = async (file: any) => {
    // Functions.notiflixLoader('Uploading...');
    let checkFile = file.split('.').pop();
    if (checkFile === 'jpeg' || checkFile === 'png' || checkFile === 'jpg') {
      let data: any = state.saree_data;
      if (data[indexNumber]['images'].length <= 4) {
        data[indexNumber]['name'] = `saree ${indexNumber + 1}`;
        data[indexNumber]['images'].push(file);
        data[indexNumber]['amount'] = sareeRate.firstOne;
        setState({ saree_data: data });
        remainingData = state.saree_data;
        storeBookingData(data);
        if(booking.coupon_verify===null){
        sumOfAmount();
        }
        Functions.notiflixRemove();
      } else {
        Functions.notiflixFailure('Can upload five images only');
        // Functions.notiflixRemove();
      }
    } else {
      Functions.notiflixFailure(`${checkFile} not supported`);
      // Functions.notiflixRemove();
    }
  };

  // add bodySize,height and num of pleat
 
  const addHipSize = (value?: any, index?: any) => {
    if (!state.apply_all) {
      const data = state.saree_data;
      data[index]['body_size'] = value;
      setState({ saree_data: data });
      storeBookingData(data);
      // storePrevBookingData(data)
    } else if (state.apply_all) {
      const data = state.saree_data;
      data[0]['body_size'] = value;
      setState({ saree_data: data });
      data.forEach((item: any, i: number) => {
        data[i]['body_size'] = data[0]['body_size'];
      });
      storeBookingData(data);
    }
  };

  const addNumberOfPleat = (value: any, index: number) => {
    if (!state.apply_all) {
      const data = state.saree_data;
      data[index]['number_of_pleat'] = value;
      setState({ saree_data: data });
      storeBookingData(data);
      // storePrevBookingData(data)
    } else if (state.apply_all) {
      const data = state.saree_data;
      data[0]['number_of_pleat'] = value;
      data.forEach((item: any, i: number) => {
        data[i]['number_of_pleat'] = data[0]['number_of_pleat'];
        setState({ saree_data: data });
        storeBookingData(data);
      });
    }
  };

 

  const addNotes = (value: any, index: number) => {
    if (!state.apply_all) {
      const data = state.saree_data;
      data[index]['notes'] = value;
      setState({ saree_data: data });
      storeBookingData(data);
      // storePrevBookingData(data);
    } else if (state.apply_all) {
      const data = state.saree_data;
      data[0]['notes'] = value;
      setState({saree_data:data})
      data.forEach((item: any, i: number) => {
        data[i]['notes'] = data[0]['notes'];
      });
      storeBookingData(data);
    }
  };

  const removeItem = async (removeIndex, removeItemIndex: number) => {
    if(booking.coupon_verify===null){
    Functions.notiflixLoader('Removing...');
    let removeImage: any = state.saree_data;
    removeImage[removeIndex]['images'].splice(removeItemIndex, 1);
    setState({ saree_data: removeImage });
    
    Functions.notiflixRemove();
    if (
      state.saree_data[removeIndex].images.length === 0 &&
      removeIndex !== 0
    ) {
      setState({ limit: state.limit - 1 });
    }
    remainingData = await state.saree_data.filter((element: any) => {
      return element.images.length > 0;
    });
    setState({ saree_data: remainingData });
    storeBookingData(remainingData);
    if (_.isEmpty(remainingData)) {
      addAnotherSarees();
      setState({ totalAmount: 0 });
      storeTotalAmount(0);
    } else {
      sumOfAmount();
    }
    }
  };

  const sumOfAmount = async () => {
    let amount: any = [];
      remainingData?.map((item: any) => {
        amount.push(parseInt(item?.amount));
      });
      const initialValue = 0;
      let sum = amount?.reduce((prev, current) => prev + current, initialValue);
      storeTotalAmount(sum);
      setState({ totalAmount: parseInt(sum) });
      totalPrice();
  };

  const totalPrice = async () => {
    let express_delivery = state.express_delivery ? expressDelivery : 0;
    storeExpressDelivery(state.express_delivery);
    // let price = state.totalAmount + deliveryCharge + express_delivery;
    let price = state.totalAmount;
    price = parseInt(price)
    setState({ price });
    storeTotalPrice(price);
  };

  const checked = (value) => {
    let checked = state.bodySize_data;
    if (checked.includes(value)) {
      let indexNumber = checked.indexOf(value);
      checked.splice(indexNumber, 1);
      setState({ checked_data: checked });
    } else {
      checked.splice(0, 3);
      checked.push(value);
      setState({ checked_data: checked });
    }
  };

  const checkedBodyHeight = (value: any, index: number) => {
    const data = state.saree_data;
    if (!state.apply_all) {
      if (data[index]['body_height'] === value) {
        data[index]['body_height'] = '';
        setState({ saree_data: data });
        storeBookingData(data);
        // storePrevBookingData(data);
      } else {
        data[index]['body_height'] = '';
        data[index]['body_height'] = value;
        setState({ saree_data: data });
        storeBookingData(data);
        // storePrevBookingData(data);
      }
    } else if (state.apply_all) {
      const data = state.saree_data;
      data[0]['body_height'] = value;
      setState({saree_data:data})
      data.forEach((item: any, i: any) => {
        data[i]['body_height'] = data[0]['body_height'];
      });
      storeBookingData(data);
    }
  };

 const applyAllSaress = () => {
   storeApplyForAll(state.apply_all);
   if (state.apply_all) {
     const data = state.saree_data;
     data.forEach((item: any, index: number) => {
       data[index]['body_size'] = data[0]['body_size'];
       data[index]['body_height'] = data[0]['body_height'];
       data[index]['number_of_pleat'] = data[0]['number_of_pleat'];
       data[index]['notes'] = data[0]['notes'];
     });
     setState({ saree_data: data });
     storeBookingData(data);
   } else {
     if (booking.data.length > 0) {
       setState({ saree_data: booking.data });
       setState({ limit: booking.data.length });
     } else {
       addAnotherSarees();
     }
   }
 };

 const pickupTime = () => {
  let now = moment().format('HH:mm')
   if (now >= '21:00') {
     return 'Next day 9:00 AM';
   } else if (now <= '07:00') {
     return '9:00 AM';
   } else {
     return `${state.quote_data?.eta_pickup} min`;
   }
 };

// confirm booking
const confirmBooking = () => {
  if (!localStorage.token) navigate('/login');
  else setState({ hide_booking: true });
};

// confirm address 
const addNewAddress = () => {
  if (!localStorage.token) navigate('/login');
  else navigate('/address?edit=true');
};

  const disableBookingButton = () => {
    const isDisable = isDisableBookingButton(state.quote_error, state.user_data, state.deliveryMode)
    if(isDisable) return true
    else return false
  }

  useEffect(() => {
    if (!_.isEmpty(state.quote_error)) disableBookingButton();
  }, [state.deliveryMode]);
  

 useEffect(()=>{
   applyAllSaress()
 },[state.apply_all])

  const handleChangeHorizontal = () => {
    let label = { 6: '6', 12: '12' };
    return label;
  };
  const handleChangeHorizontalBodyData = () => {
    let label = { 26: '26', 48: '48' };
    return label;
  };

  useEffect(() => {
    if (
      state.coupon_verify !== null &&
      state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY &&
      booking.coupon_verify?.code === FIRSTPLEAT
    ) {
      setState({
        coupon_verify: null,
        totalAmount: booking.item_price,
        price: booking.item_price,
      });
      storeCouponData(null);
      storeItemPrice(booking.item_price);
      storeTotalAmount(booking.item_price);
      storeTotalPrice(booking.item_price);
      totalPrice();
    }
  }, [state.deliveryMode]);

  return (
    <div className="booking_container">
      {state.loading && (
        <div className="loader">
          <PuffLoader />
        </div>
      )}
          <SelectCoupon
            ref={selectCouponRef}
            error={state.coupon_error}
            sareeDataLength={state.filter_saree_data}
            value={state.coupon_code}
            couponData={state.coupon}
            onChange={(code: any) => {
              setState({ coupon_code: code });
            }}
            couponVerify={(value: any) => couponVerify(value)}
            onClick={() => {
             selectCouponRef.current.closeModal()
            }}
          />
   
        <div className="booking_screen_container">
          <div className="booking_header">
            <Header onClick={() => navigate(-1)} title={'Add Order'} />
          </div>
          <div className="booking_scroll_container">
            <div className="booking_addsaree_container">
              <div className="booking_addsaree_header">
                <div className="booking_subheading">Add your saree</div>
              </div>
          
              <div className="booking_addsaree_wrapper">
                <div className="booking_addsaree_component_container">
                  {state.saree_data.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="addsaree_container">
                          <div className="addsaree_wrapper">
                                
                                  <div className="addsaree_bottom_wrapper">
                                    <div className="addsaree_saree_count_container">
                                      Saree {index + 1}
                                    </div>
                                    {index !== 0 &&
                                    booking.coupon_verify === null ? (
                                      <div
                                        onClick={() => removeAddSarees(index)}
                                        className="addsaree_remove_text">
                                        Remove
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="booking_screen_saree_image_heading_flex_wrapper">
                                    <div className="booking_screen_saree_image_heading">
                                      Saree image
                                    </div>
                                    {state.saree_data[index].images.length >
                                    0 ? (
                                      <div className="addsaree_saree_price_container">
                                        <div className="addsaree_price_currency">
                                          Rs.
                                        </div>
                                        <div
                                          style={{ marginTop: '-2px' }}
                                          className="addsaree_price_value">
                                          {sareeRate.firstOne}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                
{/*                                 
                                  <div className="addsaree_top_wrapper">
                                    <FileUpload
                                      multiple
                                      ref={sareeUploadRef}
                                      onChange={(file: any) => {
                                        fileHandle(file);
                                      }}>
                                      <div className="addsaree_camera_container">
                                        <div
                                          key={index}
                                          onClick={() => {
                                            sareeUploadRef.current.openUpload(),
                                              (indexNumber = index);
                                          }}
                                          className="addsaree_camera_container">
                                          <img src={Assets.camera} />
                                        </div>
                                        {state.saree_data[index].images
                                          .length === 0 ? (
                                          <div className="booking_screen_add_saree_notify">
                                            {index === 0 ? 'Please upload your 1st  saree images':`Please upload your saree ${index+1} images*`}
                                          </div>
                                        ) : null}
                                      </div>
                                    </FileUpload>
                                    {state.saree_data[index].images.map(
                                      (img: any, indexnumber: number) => {
                                        return (
                                          <>
                                            <div className="upload_saree_image_wrapper">
                                              <div
                                                className="upload_image_position"
                                                onClick={() =>
                                                  removeItem(index, indexnumber)
                                                }>
                                                <img
                                                  src={img}
                                                  className="upload_saree_image"
                                                />
                                                {booking.coupon_verify ===
                                                  null && (
                                                  <div className="cancel_button">
                                                    <img
                                                      src={Assets.close}
                                                      alt="delete button"
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      },
                                    )}
                                    <div className="addsaree_list_container"></div>
                                  </div> */}

                            {/* change upload field */}
                            {state.saree_data[index]?.images?.length > 0 ? (
                              <div className='saree_upload_show_image_wrapper'>
                                <div className='saree_upload_show_image'>
                                  <img style={{ borderRadius: '15px' }} src={state.saree_data[index]?.images?.[0]} width={'100%'} height={164} />
                                {booking.coupon_verify ===
                                  null && (
                                    <div onClick={() =>
                                      removeItem(index, 0)
                                    } className='delete_saree_upload_button_wrapper'>
                                      <div className='delete_saree_button_image'>
                                        <img src={Assets.close} width={24} height={24} />
                                      </div>
                                    </div>

                                  )}
                                </div>
                              </div>
                            ) : (
                              <FileUpload
                                multiple
                                ref={sareeUploadRef}
                                onChange={(file: any) => {
                                  fileHandle(file);
                                }}>
                                  <div onClick={() => {
                                    sareeUploadRef.current.openUpload(),
                                      (indexNumber = index);
                                  }} className='saree_upload_wrapper'>
                                    <div className='saree_upload_flex_wrapper'>
                                      <div className='saree_upload_image'>
                                        <img src={Assets.saree_upload} width={24} height={21.333} />
                                      </div>
                                      <div className='saree_upload_content'>Click to upload </div>
                                    </div>
                                  </div>
                              </FileUpload>
                            )}

                                  {/* hip size start */}
                                  <div className="booking_screen_hip_size_wrapper">
                                    <div className="booking_screen_hip_size_heading_flex_wrapper">
                                      <div className="hip_size_heading">
                                        What is your hip size?*
                                      </div>
                                      {state.apply_all ? (
                                        <>
                                          {state.saree_data[0].body_size >=
                                            26 && (
                                            <div className="booking_screen_question_title_range">
                                              {state.saree_data[0].body_size}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {state.saree_data[index].body_size >=
                                            26 && (
                                            <div className="booking_screen_question_title_range">
                                              {
                                                state.saree_data[index]
                                                  .body_size
                                              }
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="booking_screen_hip_size_range_slider_wrapper">
                                      <div className="slider-horizontal">
                                        <div className="body_size_slider">
                                          <Slider
                                            min={26}
                                            max={48}
                                            step={1}
                                            value={
                                              state.apply_all
                                                ? state.saree_data[0].body_size
                                                : state.saree_data[index]
                                                    .body_size
                                            }
                                            labels={handleChangeHorizontalBodyData()}
                                            onChange={(value) => {
                                              addHipSize(value, index);
                                              // setState({ bodySize_data: value });
                                              storeBodySizeData(value);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                              <div style={{ margin: '10px 0' }}>
                                <div style={{ position: 'relative' }} className="hip_notes_wrapper">
                                  <div onMouseLeave={()=>setState({hipSizeContentShow:''})} onMouseEnter={()=>setState({hipSizeContentShow:index})}  id={`anchor-hipsize-id-${index}`}  className='eye_wrapper'>
                                  <img width={20} height={20} src={state.hipSizeContentShow === index ? Assets.info_active : Assets.info_disable} />
                                  </div>
                                  <TooltipComponent
                                    place='top-start'
                                    backgroundColor="#FFFFFF"
                                    color="#9C312B"
                                    anchorId={`anchor-hipsize-id-${index}`}
                                    content={hipNotesContent[0]}
                                  />
                                </div>
                              </div>
                            </div>
                                  {/* hip size end */}

                                  {/* num of pleat start */}
                                  <div className="booking_screen_pleat_wrapper">
                                    <div className="booking_screen_pleat_range_slider_flex_wrapper">
                                      <div className="pleat_heading">
                                        Number of pleats?*
                                      </div>
                                      {state.apply_all ? (
                                        <>
                                          {state.saree_data[0].number_of_pleat >
                                            0 && (
                                            <div
                                              className={
                                                state.saree_data[0]
                                                  .number_of_pleat <= 9 ||
                                                state.saree_data[0]
                                                  .number_of_pleat === 11
                                                  ? 'booking_screen_num_of_pleat_range'
                                                  : 'booking_screen_num_of_pleat_range1'
                                              }>
                                              {
                                                state.saree_data[0]
                                                  .number_of_pleat
                                              }
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {state.saree_data[index]
                                            .number_of_pleat > 0 && (
                                            <div
                                              className={
                                                state.saree_data[index]
                                                  .number_of_pleat <= 9 ||
                                                state.saree_data[index]
                                                  .number_of_pleat === 11
                                                  ? 'booking_screen_num_of_pleat_range'
                                                  : 'booking_screen_num_of_pleat_range1'
                                              }>
                                              {
                                                state.saree_data[index]
                                                  .number_of_pleat
                                              }
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="booking_screen_pleat_range_slider_wrapper">
                                      <div className="slider-horizontal">
                                        <div className="booking_screen_slider_container">
                                          <Slider
                                            min={6}
                                            max={12}
                                            step={1}
                                            value={
                                              state.apply_all
                                                ? state.saree_data[0]
                                                    .number_of_pleat
                                                : state.saree_data[index]
                                                    .number_of_pleat
                                            }
                                            labels={handleChangeHorizontal()}
                                            onChange={(value) => {
                                              storeNumberOfPleates(value);
                                              addNumberOfPleat(value, index);
                                              // setState({ number_of_pleat: value });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                              <div style={{ margin: '10px 0' }}>
                                <div style={{ position: 'relative' }} className="hip_notes_wrapper">
                                  <div onMouseLeave={() => setState({ numOfPleatContentShow: '' })} onMouseEnter={() => setState({ numOfPleatContentShow: index })} id={`anchor-numofpleat-id-${index}`} className='eye_wrapper'>
                                    <img width={20} height={20} src={state.numOfPleatContentShow === index ? Assets.info_active : Assets.info_disable} />
                                  </div>
                                  <TooltipComponent
                                    place='bottom-start'
                                    backgroundColor="#FFFFFF"
                                    color="#9C312B"
                                    anchorId={`anchor-numofpleat-id-${index}`}
                                    content={numOfPleatContent[0]}
                                  />
                                </div>
                              </div>
                                  </div>
                                  {/* num of pleat end */}

                                  {/* body height start */}
                                  <div className="booking_screen_body_height_wrapper">
                                    <div className="booking_screen_body_height_heading">
                                      What is your body height?*
                                    </div>
                                    {bodyHeight?.map((item: any) => {
                                      return (
                                        <>
                                          <div className="booking_screen_checkbox_flex_wrapper">
                                            <div
                                              onClick={() =>
                                                checkedBodyHeight(
                                                  item.value,
                                                  index,
                                                )
                                              }
                                              className="booking_screen_input_checkbox">
                                              <CheckBox
                                                checked={
                                                  state.apply_all
                                                    ? state.saree_data[0]
                                                        .body_height ===
                                                      item.value
                                                      ? true
                                                      : false
                                                    : state.saree_data[index]
                                                        .body_height ===
                                                      item.value
                                                    ? true
                                                    : false
                                                }
                                                // checked={
                                                //  state.saree_data[index].body_height?.indexOf(
                                                //     item.value,
                                                //   ) !== -1
                                                //     ? true
                                                //     : false
                                                // }
                                                active={Assets.checkbox_active}
                                                disabled={
                                                  Assets.checkbox_disable
                                                }
                                              />
                                            </div>
                                            <div
                                              onClick={() =>
                                                checkedBodyHeight(
                                                  item.value,
                                                  index,
                                                )
                                              }
                                              className="booking_screen_section_content_height">
                                              {item.label}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  {/* body height end */}

                                  {/* notes start */}
                                  <div className="booking_screen_notes_wrapper">
                                    <div className="booking_screen_notes_heading">
                                      Add notes
                                    </div>
                                    <div className="booking_screen_notes">
                                      <TextArea
                                        className="add_notes_input"
                                        value={state.saree_data[index].notes}
                                        onChange={(e) => {
                                          addNotes(e.target.value, index);
                                          // setState({ notes: e.target.value });
                                          storeNotes(e.target.value);
                                        }}
                                        placeholder="Add notes about your order..."
                                      />
                                    </div>
                                  </div>
                                  {/* notes end */}
                                
                          </div>
                        </div>
                        {index === 0 &&
                        state.saree_data[0]['body_size'] >= 26 &&
                        state.saree_data[0]['body_height'].length > 0 &&
                        state.saree_data[0]['number_of_pleat'] >= 6 ? (
                          <div className="booking_screen_apply_all_checkbox_wrapper">
                            <div
                              onClick={() =>
                                setState({ apply_all: !state.apply_all })
                              }
                              className="booking_screen_checkbox_apply_all_flex_wrapper">
                              <div>
                                <CheckBox
                                  checked={state.apply_all}
                                  active={Assets.checkbox_active}
                                  disabled={Assets.checkbox_disable}
                                />
                              </div>
                              <div className="booking_screen_checkbox_content">
                                Apply for all sarees
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                  <div className="booking_addsaree_button">
                    {state.saree_data[0]?.images.length > 0 &&
                      booking.coupon_verify === null && (
                        <PrimaryButton
                          onClick={() => {
                            setState(
                              { limit: state.limit + 1, numOfPleatContentShow: '', hipSizeContentShow: '' },
                              // (indexNumber = index),
                            );
                          }}
                          text="+ Add another saree"
                          className={'addsaree_button'}
                        />
                      )}
                  </div>
                  {booking.coupon_verify !== null && (
                    <div className="booking_screen_warning_message_remove_coupon">
                      *To edit sarees remove applied coupon
                    </div>
                  )}
                </div>
              </div>
          </div>
          {isMobileApp() ?
            <div className='help'>Need help on booking? Please contact us on
              <a className='whatsapp' href={`https://wa.me/+919043040220?text=Hi, I Need help on my Pleat booking`}>
                <img
                  width={'30px'}
                  height={'30px'}
                  src={Assets.whatsapp_icon}
                  alt="ask_help_whatsapp"
                /> Whatsapp</a>
            </div> :
            <div className='help'>Need help on booking? Please contact us on
              <a className='whatsapp' target={'_blank'} href={`https://web.whatsapp.com/send?text=Hi, I Need help on my Pleat booking&phone=+919043040220`}>
                <img
                  width={'30px'}
                  height={'30px'}
                  src={Assets.whatsapp_icon}
                  alt="ask_help_whatsapp"
                /> Whatsapp</a>
            </div>}

            <div className="booking_address_container">
              <div style={{padding:'20px'}}>
              <div className="booking_address_flex_wrapper">
                <div className="booking_subheading">Address</div>
                {state.address?.length > 0 && (
                  <div onClick={()=>navigate('/address')} className="booking_address_change">
                    Change
                    </div>
                )}
              </div>
              <div className="booking_add_address_button">
                {state.address?.length > 0 ? (
                  <>
                    <div className="booking_address_content">
                      <div className="booking_address_content_heading">
                        {`${state.address[0]?.house_number},  ${state.address[0]?.apartment},  ${state.address[0]?.phone_number}`}
                      </div>
                      <div className="booking_address_content_text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <PrimaryButton
                      onClick={() => addNewAddress()}
                      text="+ Add new address"
                      className="add_address_button button_blue"
                    />
                  </>
                )}
              </div>
            {/* map container */}
          {state.address?.length > 0 && (
            <div className="booking_screen_map_container">
              {state.map_loading ? (
                    <div className='map_loading_wrapper'>                
                    <PuffLoader
                      color={Colors.primaryLightColor}
                      loading={true}
                      size={60}
                    />
                    </div>
              ):(
              <div className="booking_screen_map_wrapper">
                <MapPicker latlong={(value:any)=>console.log('latlng',value)}
                 latlongData={state.show_map}
                 editLatlong={true}/>
              </div>
              )}
           </div>
        )}
          {/* map container end  */}
            </div>
        </div>
          
            {/* coupon code */}
            {state.localStorage && (
            <div className="coupon_code_container">
              <div className="coupon_code_flex_wrapper">
                <div className="coupon_code_apply_block">
                  <div className="coupon_code_discount">
                    <div className="coupon_code_discount_icon">
                      <img
                        height={30}
                        width={30}
                        src={Assets.discount_percentage}
                        alt="discount_percentage"
                      />
                    </div>
                    <div className="coupon_code_discount_text">
                      Apply coupon code
                    </div>
                  </div>
                </div>
                {state.coupon_verify === null && (
                    <div className="coupon_code_apply_block">
                      <PrimaryButton
                        onClick={() => viewCoupon()}
                        className={'coupon_code_apply_button'}
                        text="View coupon"
                      />
                    </div>
                  )}
              </div>
              <div className="coupon_code_horizontal_line"></div>
              {state.coupon_verify !== null && (
                <div className="coupon_code_remove_wrapper">
                  <div className="coupon_code_content">
                    {state.coupon_verify.code} code applied
                  </div>
                  <div
                    onClick={() => removeApplyCoupon()}
                    className="coupon_code_remove_button">
                    Remove
                  </div>
                </div>
              )}
            </div>
            )}
            <div style={{ padding: '20px' }}>
              <div className='courier_delivery_types_heading'>Delivery Types</div>
              <CourierCardComponent
               quoteError={state.quote_error}
               defaultValue={
                _.isEmpty(state.quote_error) || 
                (!_.isEmpty(state.quote_error) && !quoteError.includes(state.quote_error))
                  ? COURIER_DELIVERY_TYPES.STANDARD_DELIVERY
                  : COURIER_DELIVERY_TYPES.RELAX_DELIVERY
             }
               data={
                 _.isEmpty(state.quote_error) || 
                 (!_.isEmpty(state.quote_error) && !quoteError.includes(state.quote_error))
                   ? courierCardData.slice(0, 1)
                   : courierCardData.slice(1)
              }
              callback={(value: any) => setState({ deliveryMode: value })}
              />
            </div>
            {
              <>
              {/* dunzo quote */}
              {!_.isEmpty(state.quote_data) && (
                <div className="dunzo_quote_container">
                  <div className="dunzo_quote_heading">Delivery details</div>
                  {state.quote_data.eta_pickup && (
                    <div className="dunzo_quote_flex_wrapper">
                      <div className="dunzo_quote_details_heading">Estimated pickup time</div>
                      <div className='dunzo_quote_details_data'>{pickupTime()}</div>
                    </div>
                  )}
                  <div className="dunzo_quote_flex_wrapper">
                    <div className="dunzo_quote_details_heading">Estimated delivery time</div>
                    <div className="dunzo_quote_details_data">
                      {state.deliveryMode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY ? (
                        <>
                          {estimatedTime().split(" ")[1]} {estimatedTime().split(" ")[2]} on{" "}
                          {estimatedTime().split(" ")[0]}
                        </>
                      ) : (
                        <>{state.config_data?.delivery_time}</>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* error message show */}
              {/* {!_.isEmpty(state.quote_error) && quoteError.includes(state.quote_error ) && (
                 <div className="dunzo_quote_container">
                 <div className="dunzo_quote_heading">Delivery details</div>
                <div className="dunzo_quote_error_message">{'We aren’t serviceable in this location yet.'}</div>
                 </div>
              )} */}
              {/* error message show end */}
              {/* dunzo quote end */}
                <div className="booking_billing_container">
                  <div className="booking_subheading">Bill Details</div>
                  <div className="booking_billing_wrapper">
                    <div className="booking_billing_type_value">
                      <div className="booking_billing_type">Item Total</div>
                      <div className="booking_billing_value">
                        {state.coupon_verify !== null
                          ? state.item_price
                          : state.totalAmount}
                      </div>
                    </div>
                  {
                    (_.isEmpty(state.quote_error) ||
                      (!_.isEmpty(state.quote_error) &&
                        !quoteError.includes(state.quote_error))) &&
                    state.deliveryMode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY && (
                      <div className="booking_billing_type_value">
                        <div className="booking_billing_type">Express Delivery Charge</div>
                        <div className="booking_billing_value">
                          {`${state.saree_data.length
                            } * ${expressDelivery} = ${expressDeliveryRate(
                              state.deliveryMode,
                              state.saree_data
                            )}`}
                        </div>
                      </div>
                    )
                  }
                    {state.deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY && (
                         <div className="booking_billing_type_value">
                         <div className="booking_billing_type">Courier Delivery Charge</div>
                         <div className="booking_billing_value">
                          {relaxDeliveryCharge}
                         </div>
                       </div>
                     )}
                    {/* <div className="booking_billing_value">
                        {state.coupon_verify
                          ? `-${state.offer_reduce_amount} `
                          : 0}
                      </div> */}
                    {state.coupon_verify !== null ? (
                      <>
                        <div className="booking_billing_type_value">
                          <div className="booking_billing_type">
                           { `Offer ` }
                            {state.coupon_verify
                              ? Math.round(state.coupon_verify?.offer_value)
                              : 0}
                            %
                          </div>
                          <div className="booking_billing_value">
                            {state.coupon_verify
                                ? `-${Math.round(state.offer_reduce_amount)} `
                              : 0}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="booking_billing_footer">
                    <div className="booking_billing_topay">To Pay</div>
                    {state.saree_data && _.isEmpty(state.saree_data[0]?.images) ? (
                      <>
                      {sareeRate.firstOne + expressDeliveryRate(state.deliveryMode,state.saree_data)}
                      </>
                    ) : (
                      <div className="booking_billing_amount">
                        {Math.round(parseInt(state.price) + expressDeliveryRate(state.deliveryMode, state.saree_data))}
                      </div>
                    )}
                  </div>
                </div>
              <div className="booking_footer">
                {
                  !_.isEmpty(state.quote_error) &&
                  quoteError.includes(state.quote_error) &&
                  !state.user_data.address[0].pincode &&
                  state.addPincodeShow && (
                    <div className="add_pincode_content_wrapper">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={Assets.pincode_error} width={24} height={24} />
                      </div>
                      <div className="add_pincode_content">
                        You haven’t added your Pincode yet{" "}
                        <span onClick={() => navigate("/address")}>Add Pincode</span>
                      </div>
                    </div>
                  )
                }

                <div className='booking_screen_booking_button'>
                  <PrimaryButton
                  className={`${disableBookingButton() ? 'booking_button_disabled':''}`}
                    style={{
                      borderRadius: "14px",
                      marginTop: `${state.addPincodeShow? "16px" : "30px"}`,
                    }}
                    onClick={() => disableBookingButton() ? setState({addPincodeShow:true}) :confirmBooking() }
                    text={"Book"}
                  />
                </div>
              </div>
              </>
            }
          </div>
          {state.hide_booking ? (
            <>
              {/* <OutsideClickHandler
                onOutsideClick={() => {
                  setState({ hide_booking: false });
                }}> */}
              <Confirmation
                outSideClick={(value: any) => {
                  setState({ hide_booking: value });
                }}
                onClick={() => createBooking()}
                back={() => setState({ hide_booking: false })}
                buttonText={'Confirm'}
                title={'Confirm Booking'}
                booking_confirmation={true}
                text={'Please confirm to get your saree perfectly pre-pleated!'}
              />
              {/* </OutsideClickHandler> */}
            </>
          ) : null}
        </div>
    
    </div>
  );
};

export default Booking;
