import instance from 'utils/axios.utils';

const users = {
  getAllUsers: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'auth/get_all_users';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  adminEditUsers: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'auth/admin_edit_user';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteUser: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/delete_user`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getUser: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `auth/get_user_by_id`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getManyUser:(body:any)=>{
    let promise = new Promise((resolve, reject) => {
      let url = `auth/get_many_users`;
      instance()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise
  }
};

export default users;
