import instance from 'utils/axios.utils';

const dashboard = {
  getManyDashboard: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'dashboard/get_many_dashboard';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default dashboard;
