import AdminModel from 'admin_panel/imports/models.admin_import';
import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import ListComponent from '../../admin_common_components/ui/list_component/list_component';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
import { Dropdown, Functions, Searchbar } from 'utils/imports.utils';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import './payout.admin_screen.scss';
import _ from 'lodash';
import moment from 'moment';

interface IPayout {}

const Payout = (props: IPayout) => {
  // state
  const [state, setState] = useSetState({
    payout_data: [],
    search: '',
    search_input: '',
    search_data:[],
  });

  // get manyPayouts
  let data: any = [];
  const getManyPayout = async (disable: boolean) => {
    if (disable) {
      Functions.notiflixLoader();
    }
    try {
      let body: any = {};
      if (state.search.length > 0) {
        body['search'] = state.search;
      }

      const res: any = await AdminModel.payout.getManyPayout(body);
      if (!disable && state.search_input.length > 0) {
        res.data.map(
          (item: any) => (
            (item.pleater_name = item.pleater.name),
            (item.pleater_id = item.pleater._id)
          ),
        );
        searchFilter(res.data);
      }
      else {
      res.data.map(
        (item: any) => (
          (item.pleater_name = item.pleater.name),
          (item.pleater_id = item.pleater._id)
        ),
      );

      let sortedData = await res?.data.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setState({search_data:sortedData})
      setState({ payout_data: sortedData });
    }
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // edit payout_data
  const editPayout = async (value: any) => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        status: 'TRANSFERED',
        payout_id: value._id,
      };
      const res: any = await AdminModel.payout.editPayout(body);
      getManyPayout(true);
      setState({ payout_modal: false });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };



  // Logic
  const searchFilter = async (data?:any) => {
    const payoutData = data || state.search_data
    const filterData = payoutData.filter((item: any) => {
    return  item.pleater_name.toLowerCase().includes(state.search_input?.toLowerCase())||
    item._id?.toLowerCase().includes(state.search_input?.toLowerCase()) ||
    item.amount?.toString().includes(state.search_input?.toLowerCase()) ||
    moment(item.created_at).format('DD-MM-YYYY').includes(state.search_input?.toLowerCase()) 
    });
    setState({ payout_data: filterData });
  };

  useEffect(() => {
    searchFilter();
  }, [state.search_input]);

  // hooks
  useEffect(() => {
    getManyPayout(true);
  }, [state.search]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.token) {
      navigate('/login');
    }
  }, []);

  //data
  const titledata: object = [
    {
      label: '#',
      value: 's.no',
      width: '5%',
    },
    {
      label: 'Name',
      value: 'pleater_name',
      width: '18%',
    },
    {
      label: 'Payout ID',
      value: '_id',
      width: '20%',
    },
    {
      label: 'Request Date',
      value: 'created_at',
      width: '18%',
    },

    {
      label: 'Requested',
      value: 'amount',
      width: '12%',
    },
    {
      label: 'Status',
      value: 'status',
      width: '15%',
    },
  ];

  const optiondata: object = [
    {
      value:'',
      label: 'All',
    },
    {
      value: 'TRANSFERED',
      label: 'Paid',
    },
    {
      value: 'PROCESSING',
      label: 'Processing',
    },
  ];
  return (
    <div className="payout_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="payout_wrapper">
        <AdminHeader text={'Payout'} />
        <div className="payout_table_container">
          <div className="table_header">
            <div className="search_wrapper">
              <Searchbar
                value={state.search_input}
                onChange={(value: any) => setState({ search_input: value })}
                className={'search_panel'}
                placeholder={'Search'}
                style={{ borderRadius: '16px' }}
              />
            </div>
            <div className="dropdown_header">
              <Dropdown
                onChange={(search: any) =>
                setState({ search: search.value })
                }
                data={optiondata}
                width={"230px"} borderRadius={"15px"}
                placeholder={"Status..."}
              />
            </div>
          </div>
          <ListComponent
            refresh={(value: boolean) => {
              if (value) {
                getManyPayout(false);
              }
            }}
            titleArray={titledata}
            data={state.payout_data}
            type={'payout_table'}
          />
        </div>
      </div>
    </div>
  );
};

export default Payout;
