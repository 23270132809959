import { useEffect, useRef } from 'react';
import './live_location.admin_screen.scss';
import {
  capitalizeFirstLetter,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import { Assets, Dropdown, Functions } from 'utils/imports.utils';
import Sidebar from 'admin_panel/admin_common_components/ui/sidebar/sidebar_component.ui';
import AdminModel from 'admin_panel/imports/models.admin_import';
import _ from 'lodash';
import Autocomplete from 'react-google-autocomplete';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from '@react-google-maps/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { GOOGLE_MAPS_API_KEY } from 'utils/constant.utils';

export default function LiveLocation() {
  // ref
  const Map: any = useRef(/** @type google.maps.Map */ null);
  const desinationCustomer: any = useRef(null);

  // params
  let paramsId: any = useQuery().get('id');

  // dropdown check
  let isDropDown: any = useQuery().get('key') || false;
  
  // navigate
  const navigate = useNavigate();


// areaData
  const areaData: any = [
    {
      label: 'Chennai',
      value: 'chennai',
      lat: 13.067439,
      lng: 80.237617,
    },
    {
      label: 'Bangalore',
      value: 'bangalore',
      lat: 12.972442,
      lng: 77.580643,
    },
    {
      label: 'Coimbatore',
      value: 'coimbatore',
      lat: 11.017363,
      lng: 76.958885,
    },
  ];

  // state
  const [state, setState] = useSetState({
    loading: true,
    center: {
      lat: 12.0968048,
      lng: 78.193043,
    },
    area: '',
    customerLocation: {},
    map_zoom: false,
    distance: null,
    pleaterData: [],
    pleaterDetail: { pleater: { name: '' } },
    pleaterLocation: {},
    pleaterAllLocation: [],
    directionResponse: null,
    duration: null,
    quoteAndDistance:[],
    quoteLoading:false,
    quoteLoadingEnabled:false,
    infoBoxData:'',
    infoWindowOpen:false,
    map: /** @type google.maps.Map */ null,
    distanceShow:false,
    defaultValue:[],
    nearByLocationEnabled:true
  });

  // get all pleater
  const getAllPleaterLocation = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        role: 'pleater',
      };
      if (state.area) {
        body.area = state.area;
      }
      const res: any = await AdminModel.users.getAllUsers(body);
      let pleaterData = res.data?.map((item: any) => {
        return {
          value: item.name,
          label: item.name,
          id: item._id,
        };
      });
      setState({
        pleaterAllLocation: res.data, pleaterData: pleaterData,
        pleaterLocation: {},
      });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // getPleater
  const getPleaterLocation = async (body) => {
    Functions.notiflixLoader();
    try {
      const query: any = {
        user_id: body,
      };
      const res: any = await AdminModel.users.getUser(query);
      setState({
        pleaterLocation: res.data.address[0].location,map_zoom: true,
        pleaterDetail: res.data,pleaterAllLocation: [],
        area: res.data.area, center: {
          lat: res.data.address[0].location.coordinates[0],
          lng: res.data.address[0].location.coordinates[1]},
      });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // getBooking
  const getBooking = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        booking_id: paramsId,
      };
      const res: any = await AdminModel.bookings.getBookingID(body);
      setState({
        pleaterLocation: res.data.pleater_address.location,
        customerLocation: {
          lat: res.data.user_address.location.coordinates[0],
          lng: res.data.user_address.location.coordinates[1],
        },
        pleaterDetail: res.data,area: res.data.pleater.area,
        customerAddress: res.data.user_address.map_address,
      });
      await getQuoteAndDistance(res.data?.user?._id,res.data?.pleater?._id)
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getQuoteAndDistance = async (userId: string, pleaterId: string) => {
    setState({quoteLoading:true, quoteLoadingEnabled:true})
    try {
      const body = {
        user_id: userId,
        pleater_id: pleaterId,
      };
      const res: any = await AdminModel.bookings.getQuoteAndDistance(body);
      setState({quoteAndDistance:res.data.quote_details, quoteLoading:false})

    } catch (err: any) {
      setState({quoteLoading:true})
       console.log('err',err)
    } 
  };
  // getNearblePleaterLocation
  const getNearblePleaterLocation = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await AdminModel.bookings.getNearblePleaterLocation(state.customerLocation);
      setState({
        pleaterLocation: res.data.address[0].location,
        defaultValue: [{ value: res.data.name, label: res.data.name }],
        pleaterDetail: { pleater: res.data }, area: res.data.area
      });
      caculateRoute(res.data.address[0].location)
      navigate(`/live_location?key=${true}`);
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };


// getTitle a marker
  const getTitle = (markers) => {
    if(!_.isEmpty(markers)) {
      return `${markers.name ? markers.name : ''}${markers?.pleater?.name ? markers.pleater.name : ''
      } ${markers?.pleater?.phone ? markers.pleater.phone : ''} ${markers.phone ? markers.phone : ''}  ${
        markers.name || markers?.pleater?.name? 'Lastupdated:' + `${moment(markers.created_at).format('DD-MM-YYYY hh:mma')}`: ''
      } ${state.distance ? 'Distance:' + state.distance : ''}`;
    }
  };

  // caculateRoute a distance 
  const caculateRoute = async (data?:any) => {
    const directionService: any = new google.maps.DirectionsService();
    let directionWay:any
      if(!_.isEmpty(data) ) {
        directionWay = await directionService.route({
          origin: new google.maps.LatLng(
            data.coordinates[0],
            data.coordinates[1],
          ),
          destination: new google.maps.LatLng(
            state.customerLocation.lat,
            state.customerLocation.lng,
          ),
          travelMode: google.maps.TravelMode.DRIVING,
        });
      }
      else {
      directionWay = await directionService.route({
        origin: new google.maps.LatLng(
          state.pleaterLocation.coordinates[0],
          state.pleaterLocation.coordinates[1],
        ),
        destination: new google.maps.LatLng(
          state.customerLocation.lat,
          state.customerLocation.lng,
        ),
        travelMode: google.maps.TravelMode.DRIVING,
      });
    }
      setState({
        directionResponse: directionWay, map_zoom: true,
        distance: directionWay.routes[0].legs[0].distance.text,
      });
  };

  const getPrefix = () => {
    const prefix = moment().toDate().getTime();
    setState({ prefix });
  };

  const locationDetailsShow = (data: any) => {
    getPrefix()
    setState({ infoBoxData: data, infoWindowOpen: true });
  };
  const handleClose = (data: any) => {
    setState({ InfoBoxData: "", infoWindowOpen: false });
  };

  // hooks
  useEffect(() => {
    if (paramsId) {
      getBooking();
    }
    if (!localStorage.token) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(state.pleaterLocation)) {
      getAllPleaterLocation();
    }
  }, [state.area]);

  useEffect(() => {
    if (!_.isEmpty(state.customerLocation) && !_.isEmpty(state.pleaterLocation) && !state.nearByLocationEnabled) {
      caculateRoute();
    } else if (!_.isEmpty(state.customerLocation)) {
      getNearblePleaterLocation();
    } else if (!paramsId) {
      navigate('/live_location');
    }
  }, [state.customerLocation]);


  useEffect(() => {
    if (!_.isEmpty(state.customerLocation) && !_.isEmpty(state.pleaterLocation) && !state.nearByLocationEnabled) {
      caculateRoute();
    }
  }, [state.pleaterLocation])

  return (
    <div className="location_container">
      <div className="sidebarheader">
        <Sidebar />
      </div>
      <div className="location_screen">
        <div className="location_wrapper">
          <div className="customer_location">
            <Autocomplete
              ref={desinationCustomer}
              value={paramsId && state.customerAddress}
              apiKey={GOOGLE_MAPS_API_KEY}
              style={{
                border: `1px solid transparent`,
                width: `100%`,
                height: `40px`,
                padding: `0 6px`,
                borderRadius: `4px`,
                fontWeight: 600,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                fontFamily: 'Inter',
                zIndex: 2,
                marginLeft: 5,
              }}
              placeholder={'Search a location...'}
              onPlaceSelected={(place: any) => {
                let center = {
                  lat: place?.geometry?.location?.lat(),
                  lng: place?.geometry?.location?.lng(),
                };
                setState({
                  customerLocation: center,
                  map_zoom: true,
                  center: center,
                  distanceShow:true,
                });
              }}
              options={{
                componentRestrictions: { country: 'in' },
                fields: ['address_components', 'geometry', 'icon', 'name'],
                strictBounds: false,
                types: ['establishment'],
              }}
            />
          </div>
          <div className="pleater_location">
            {!isDropDown && state.pleaterData && (
              <Dropdown
                width={'100%'}
                borderRadius={'5px'}
                background={'#ffffff'}
                value={state.pleaterDetail?.pleater?.name}
                border={'#E4E4E4 1px solid'}
                placeholder={'Select Pleater...'}
                data={state.pleaterData}
                height={'auto'}
                onChange={(value: any) => {
                  if (!paramsId) {
                    getPleaterLocation(value.id),
                      setState({
                        pleaterDetail: { pleater: { name: value.label } },
                        nearByLocationEnabled:false
                      });
                  }
                }}
              />
            )}
            {isDropDown && state.pleaterDetail.pleater.name !== '' && (
              <Dropdown
                width={'100%'}
                borderRadius={'5px'}
                background={'#ffffff'}
                border={'#E4E4E4 1px solid'}
                value={state.defaultValue}
                placeholder={'Select Area...'}
                height={'auto'}
              />
            )}
          </div>
          <div className="area_location">
            <Dropdown
              value={
                !_.isEmpty(state.area) && {
                  label: capitalizeFirstLetter(`${state.area}`),
                }
              }
              data={areaData}
              onChange={(search: any) =>
                setState({
                  area: search.value,
                  map_zoom: true,
                  center: { lat: search.lat, lng: search.lng },
                })
              }
              width={'100%'}
              placeholder={'Select Area...'}
              height={'auto'}
              borderRadius={'5px'}
              background={'#ffffff'}
              border={'#E4E4E4 1px solid'}
            />
          </div>
          {state.quoteAndDistance && state.quoteLoadingEnabled && !state.distanceShow && (
            <div className="location_distance">
              {!state.quoteLoading ? (
                <>
                  {!_.isEmpty(state.quoteAndDistance) ? (
                    <>
                      {state.quoteAndDistance.map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}>
                            <div className="dunzo_distance_heading">
                              {`${Functions.capitalizeFirstLetter(item.vendor)} -`}
                            </div>
                            <div className="dunzo_distance_content">
                              {`${item.estimated_price} Rs ${item.distance ? ',' :""}`}
                            </div>
                            <div className="dunzo_distance_content">
                              {item.distance ? `${item.distance} Km` : null}
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <div className='dunzo_distance_heading' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      No Data
                    </div>
                  )}
                </>
              ) : (
                <div className='dunzo_distance_heading' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Loading...
                </div>
              )}
            </div>
          )}

          {state.distanceShow && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div className='dunzo_distance_heading'>Distance :</div>
              <div className="dunzo_distance_content">{state.distance}</div>
            </div>
          )}
          
          <div className={state.distanceAndPrice ? "clear_location" : "clear_location_left"}>
            {(!_.isEmpty(state.customerLocation) ||
              !_.isEmpty(state.pleaterLocation) ||
              state.area) && (
              <div
                onClick={() => {
                  setState({
                    pleaterLocation: {},
                    distance: '',
                    customerLocation: {},
                    area: '',
                    pleaterDetail: { pleater: { name: '' } },
                    directionResponse: null,
                    map_zoom: false,
                    center: {
                      lat: 12.0968048,
                      lng: 78.193043,
                    },
                    quoteAndDistance:''
                  }),
                    (desinationCustomer.current.value = '');
                  navigate('/live_location');
                }}
                className="clear_title">
                Clear
              </div>
            )}
          </div>
        </div>
        <div className="map_wrapper">
          <GoogleMap
            ref={Map}
            center={state.center}
            zoom={state.map_zoom ? 9 : 8}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}>
            {state.directionResponse &&
            !_.isEmpty(state.customerLocation) &&
            !_.isEmpty(state.pleaterLocation) ? (
              <>
                <DirectionsRenderer directions={state.directionResponse} />
                <Marker
                  title={getTitle(state.pleaterDetail)}
                  position={{
                    lat: state.customerLocation.lat,
                    lng: state.customerLocation.lng,
                  }}
                  zIndex={1000000}
                />
              </>
            ) : (
              state.pleaterAllLocation?.length > 0 &&
              state.pleaterAllLocation.map((markers: any, index: number) => {
                if (markers.address[0]) {
                  const { coordinates } = markers.address[0].location;
                  return (
                    <Marker
                      title={getTitle(markers)}
                      onClick={() => {
                        setState({
                          center: {
                            lat: coordinates[0],
                            lng: coordinates[1],
                          },
                          map_zoom: true,
                        });
                        locationDetailsShow(markers);
                      }}
                      position={{ lat: coordinates[0], lng: coordinates[1] }}
                      key={index}>
                      {state.infoBoxData &&
                        state.infoBoxData?._id === markers?._id &&
                        state.infoWindowOpen && (
                          <InfoWindow
                            key={`${markers._id}-${state.prefix}`}
                            onCloseClick={() => handleClose(markers)}
                            position={state.center}>
                            <div className="info_window_location_details">
                              <div className="infobox_flex_wrapper">
                                <div className="infobox_driver_name">
                                  Name :
                                </div>
                                <div className="infobox_driver_name">
                                  { state.infoBoxData?.name ? Functions.capitalizeFirstLetter(
                                    state.infoBoxData?.name?.toLowerCase(),
                                  ):'---'}
                                </div>
                              </div>
                              <div
                                style={{ marginTop: '4px' }}
                                className="infobox_flex_wrapper">
                                <div className="infobox_driver_name">
                                  Phone :
                                </div>
                                <div className="infobox_driver_name">
                                  {state.infoBoxData?.phone}
                                </div>
                              </div>
                              <div style={{ marginTop: '4px' }} className="">
                                <div className="infobox_driver_name">
                                   Address :
                                <span style={{marginLeft:'3px'}} className="infobox_driver_name">{state.infoBoxData?.address[0]?.map_address}</span>
                                </div>
                              </div>
                            </div>
                          </InfoWindow>
                        )}
                    </Marker>
                  );
                }
              })
            )}

            {!_.isEmpty(state.pleaterLocation) && (
              <Marker
                title={getTitle(state.pleaterDetail)}
                position={{
                  lat: state.pleaterLocation.coordinates[0],
                  lng: state.pleaterLocation.coordinates[1],
                }}
                zIndex={1000000}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
}
