import React from 'react';
import './tooltip.component.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

interface ITooltip {
  content: string;
  anchorId: string;
  color: string;
  backgroundColor: string;
  place: any;
}
const TooltipComponent = (props: ITooltip) => {
  return (
    <div className="tooltip_container">
        <ReactTooltip
          style={{
            width: '100%',
            color: props.color,
            backgroundColor: props.backgroundColor,
            zIndex: 99999,
            fontSize: '13px',
            fontFamily: 'sans-serif',
            lineHeight: '1.5',
            fontWeight: '400',
            padding: '10px',
            borderRadius:'8px',

          }}
          className="tooltip"
          anchorId={props.anchorId}
          place={props.place}
          content={props.content}
          events={['hover']}
        
        />
    </div>
  );
};

export default TooltipComponent;
