import React, { useEffect } from 'react';
import './courier_card.component.scss';
import Assets from 'imports/assets.import';
import { ICourierCardData } from 'interfaces/common.interface';
import { estimatedTime, useSetState } from 'utils/functions.utils';
import { COURIER_DELIVERY_TYPES } from 'utils/constant.utils';

interface ICourierCard {
  callback: Function;
  data: ICourierCardData[];
  checked?: boolean;
  quoteError: string;
  defaultValue: string;
}
const CourierCardComponent = (props: ICourierCard) => {
  // state
  const [state, setState] = useSetState({
    checkbox: [],
  });
  const checkboxSelect = (elem: any) => {
    let data = state.checkbox;
    data = [];
    data.push(elem.serviceHeading);
    setState({ checkbox: data });
    props.callback(...data);
  };

  useEffect(() => {
    props.callback(props.defaultValue);
    setState({ checkbox: props.defaultValue });
  }, [props.defaultValue]);

  return (
    <>
      {props.data &&
        props.data.map((item: ICourierCardData, i: number) => {
          return (
            <div
              onClick={() => checkboxSelect(item)}
              key={i}
              className={`courier_card_container ${
                i !== 0 && 'courier_card_container_space'
              }`}>
              <div className="courier_card_wrapper">
                <div className={`courier_card_name_wrapper`}>
                  <div className="courier_card_icon">
                    <img width={20} height={20} src={item.icon} />
                  </div>
                  <div className="courier_card_name">{item.serviceHeading}</div>
                </div>
                <div className="courier_checkbox_wrapper">
                  <img
                    width={20}
                    height={20}
                    src={
                      state.checkbox.includes(item.serviceHeading)
                        ? Assets.courier_active_checkbox
                        : Assets.courier_inactive_checkbox
                    }
                  />
                </div>
              </div>
              <div className="courier_card_delivery_timing_content">
                {item.deliveryTimingContent}
              </div>
              {item?.businessHours && (
                <div
                  style={{ marginTop: '12px' }}
                  className="courier_card_delivery_fee_content">
                  {item.businessHours}
                </div>
              )}
              {item.serviceHeading === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY && (
                <div
                  className="courier_card_delivery_fee_content">
                  {`The order will be delivered before ${estimatedTime().split(' ')[1]} ${estimatedTime().split(' ')[2]} on ${estimatedTime().split(' ')[0]}`}
                </div>
              )}
              <div className="courier_card_delivery_fee_content">
                {item.deliveryFeeContent}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default CourierCardComponent;
