import instance from "utils/axios.utils";

const auth = {
  login: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/login";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  otpVerify: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/otp_verify";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },
  editUser: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/edit_user";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },


  editAvailability: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/edit_availability";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  getUser: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/get_user";
      instance().post(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  regenerateToken: () => {
    let promise = new Promise((resolve, reject) => {
      let url = "auth/regenerate_token";
      instance().post(url).then(res => {
        resolve(res.data)
      }).catch(error => {
        if (error.response) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
    })
    return promise
  },

  uploadFile: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/get_signed_url';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};


export default auth;