import instance from 'utils/axios.utils';

const review = {
  addReview: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'rating/create_rating';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  getReview: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'rating/get_rating';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default review;
