import {
  BOOKING_DATA,
  COUPON_VERIFY,
  EXPRESS_DELIVERY,
  ITEM_PRICE,
  TOTAL_AMOUNT,
  TOTAL_PRICE,
  OFFER_REDUCE_AMOUNT,
  USED_COUPON,
  STORE_BODY_SIZE_DATA,
  STORE_BODY_HEIGHT,
  STORE_NOTES,
  NUM_OF_PLEATES,
  STORE_PREVIOUS_BOOKING_DATA,
  APPLY_FOR_ALL
} from 'utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  data: [],
  totalAmount: 0,
  totalPrice: 0,
  coupon_verify: null,
  item_price: 0,
  express_delivery: false,
  offer_reduce_amount: 0,
  used_coupon:'',
  storeBodySizeData:0,
  bodyHeight:[],
  notes:'',
  num_of_pleates:0,
  store_prev_data:[],
  apply_for_all:false

};

const bookingReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case BOOKING_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case TOTAL_AMOUNT:
      return {
        ...state,
        totalAmount: action.payload,
      };
    case TOTAL_PRICE:
      return {
        ...state,
        totalPrice: action.payload,
      };
    case COUPON_VERIFY:
      return {
        ...state,
        coupon_verify: action.payload,
      };
    case ITEM_PRICE:
      return {
        ...state,
        item_price: action.payload,
      };
    case EXPRESS_DELIVERY:
      return {
        ...state,
        express_delivery: action.payload,
      };
    case OFFER_REDUCE_AMOUNT:
      return {
        ...state,
        offer_reduce_amount: action.payload,
      };
    case USED_COUPON:
      return {
        ...state,
        used_coupon: action.payload,
      };
    case STORE_BODY_SIZE_DATA:
      return {
        ...state,
        storeBodySizeData: action.payload,
      };
    case STORE_BODY_HEIGHT:
      return {
        ...state,
        bodyHeight: action.payload,
      };
    case STORE_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case NUM_OF_PLEATES:
      return {
        ...state,
        num_of_pleates: action.payload,
      };
    case STORE_PREVIOUS_BOOKING_DATA:
      return {
        ...state,
        store_prev_data: action.payload,
      };
      case APPLY_FOR_ALL:
        return {
          ...state,
          apply_for_all: action.payload,
        };

    default:
      return state;
  }
};

export default bookingReducer;
