import instance from 'utils/axios.utils';

const todaydeal = {
  createTodaydeal: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'todaydeal/create_todaydeal';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getTodaydeal: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'todaydeal/get_many_todaydeal';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default todaydeal;
