import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets, PrimaryButton } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './successful.screen.scss';
import { textData } from 'utils/constant.utils';
import {useNavigate} from 'react-router-dom';

interface ISuccessful {
  text?: String;
}

const Successful = (props: ISuccessful) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  // route
  const navigate = useNavigate()

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="success_container">
      <div className="success_wrapper">
        <div className="success_image">
          <img src={Assets.delivery_man} className="delivery_man_image" />
        </div>
        <div className="success_title">Order placed Successful!</div>
        {textData.slice(0,3).map((item: any, index: number) => {
          return (
            <>
              {index !== 3 ? (
                <>
                  <div className="success_message">{item}</div>
                </>
              ) : null}
            </>
          );
        })}
      </div>
      <div onClick={() => navigate('/home')} className="success_footer">
        <PrimaryButton text="Home" />
      </div>
    </div>
  );
};

export default Successful;
