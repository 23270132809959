import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState, useQuery, removeId } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Header,
  PrimaryButton,
  Assets,
  BottomSheetComponent,
  UploadComponent,
  FileUpload,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './deliver_sarees.screen.scss';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';
import { isTemplateExpression } from 'typescript';
import toast from 'react-hot-toast';
import '../../common_components/hoc/mobile_screen.scss';


const AddSareePopup = () => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  //   query
  const query = useQuery();
  const order_id: any = query.get('order_id');

  //   useRef
  const sareeUploadRef: any = useRef();

  //   route
  const navigate = useNavigate();

  // State
  const [state, setState] = useSetState({
    booking_data: '',
    limit: 1,
    delivery_saree_length: [],
    deliver_saree: [],
    check: false,
    images: [
      Assets.sareeImage,
      Assets.sareeImage,
      Assets.sareeImage,
      Assets.sareeImage,
      Assets.sareeImage,
      Assets.sareeImage,
    ],
  });

  //Hooks

  useEffect(() => {
    getBooking();
    addDeliverSaress();
  }, [state.limit]);

  // Network req

  const addDeliverSaress = async () => {
    let data: any = [];
    for (let i = 0; i < state.limit; i++) {
      data.push({
        name: state.deliver_saree[i]?.name || '',
        images: state.deliver_saree[i]?.images || [],
      });
    }
    setState({ deliver_saree: data });
  };

  const getBooking = async () => {
    Functions.notiflixLoader();
    try {
      const body = {
        booking_id: order_id,
      };
      const res: any = await Models.orders.getOrderById(body);
      if (res.data.delivery_sarees?.length > 0) {
        setState({ deliver_saree: res.data.delivery_sarees });
      } 
      if (res.data.pickup_sarees?.length > 0) {
        setState({ limit: res.data.pickup_sarees.length });
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const updateDeliverySaree = async () => {
    Functions.notiflixLoader();
    try {
      const checkData = state.deliver_saree.filter((item: any) => {
        return item.images.length !== 0;
      });
      if (checkData.length === state.limit) {
        const body = {
          delivery_sarees: removeId(state.deliver_saree),
          booking_id: order_id,
          re_schedule: false,
        };
        const res: any = await Models.orders.editOrder(body);
        toast.success(
          `Uploaded successfully.Our ${
            res.data.delivery_vendor
              ? Functions.capitalizeFirstLetter(
                  res.data.delivery_vendor.toLowerCase()
                )
              : 'Dunzo'
          } partner will reach you shortly`,
        );
        navigate('/orders');
      } else {
        Functions.notiflixFailure('Please upload remaining saree images');
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  //Logic

  let indexNumber: number;
  const fileHandle = async (file: any) => {
    Notiflix.Loading.pulse('Uploading...');
    const checkFile = file.split('.').pop();
    if (checkFile === 'jpeg' || checkFile === 'png' || checkFile === 'jpg') {
      let data: any = state.deliver_saree;
      data[indexNumber]['name'] = `saree ${indexNumber + 1}`;
      data[indexNumber]['images'].push(file);
      setState({ deliver_saree: data });
      Functions.notiflixRemove();
    } else {
      Functions.notiflixRemove();
      Functions.notiflixFailure(`${checkFile} not supported`);
    }
  };

  const removeImage = async (removeItem: number, index: number) => {
    Notiflix.Loading.pulse('Removing...');
    let data: any = state.deliver_saree;
    data[index]['images'].splice(removeItem, 1);
    setState({ deliver_saree: data });
    Functions.notiflixRemove();
  };


  const sareeData = [{ sareeCount: '01' }, { sareeCount: '02' }];

  return (
    <div className="mobile_screen">
    <div className="addsaree_popup_container">
      <BottomSheetComponent open={open} className="popup_bottomsheet">
        <div className="addsaree_popup_bottomsheet">
          <div className="addsaree_popup_header">
            <Header onClick={() => navigate('/orders')} title="Add Saree" />
          </div>
          {state.deliver_saree.map((saree: any, index: number) => (
            <div className="addsaree_popup_wrapper">
              <div className="addsaree_popup_count">Saree {index + 1}</div>
              <div className="addsaree_popup_images_button">
                {/* <div className="addsaree_popup_button">
                <PrimaryButton
                  icon={Assets.camera_blue}
                  text="Add Saree"
                  className="add_saree_button"
                />
              </div> */}
                <div
                  className={`${
                    state.deliver_saree[index].images.length === 0
                      ? 'addsaree_popup_images_container_center'
                      : 'addsaree_popup_images_container'
                  }`}>
                  {state.deliver_saree[index].images.length === 0 ? (
                    <>
                      <FileUpload
                        multiple
                        ref={sareeUploadRef}
                        onChange={(file: any) => {
                          fileHandle(file);
                        }}>
                        <div
                          key={index}
                          onClick={() => {
                            sareeUploadRef.current.openUpload();
                            indexNumber = index;
                          }}>
                          <img src={Assets.add_saree} alt="add_saree" />
                        </div>
                      </FileUpload>
                    </>
                  ) : (
                    <>
                      <FileUpload
                        multiple
                        ref={sareeUploadRef}
                        onChange={(file: any) => {
                          fileHandle(file);
                        }}>
                        <div
                          onClick={() => {
                            sareeUploadRef.current.openUpload();
                            indexNumber = index;
                          }}>
                          <img src={Assets.camera} className="camera_icon" />
                        </div>
                      </FileUpload>
                      <div className="addsaree_popup_camera_gallery">
                        {state.deliver_saree[index].images.map(
                          (image: any, saree_index: number) => (
                            <div
                              key={saree_index}
                              className="addsaree_popup_images_wrapper">
                              <img
                                src={image}
                                className="saree_images"
                                alt="saree image"
                              />
                              <img
                                onClick={() => {
                                  removeImage(saree_index, index);
                                }}
                                src={Assets.close}
                                className="close_icon"
                              />
                            </div>
                          ),
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="addsaree_popup_footer">
            <div className="addsaree_popup_cancel_button">
              <PrimaryButton
                onClick={() => navigate('/orders')}
                text="Cancel"
                className="cancel_button"
              />
            </div>
            <div className="addsaree_popup_deliver_button">
              <PrimaryButton
                onClick={() => updateDeliverySaree()}
                text="Deliver"
                className="deliver_button"
              />
            </div>
          </div>
        </div>
      </BottomSheetComponent>
    </div>
    </div>
  );
};

export default AddSareePopup;
