import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  PrimaryButton,
  BottomSheetComponent,
  TextArea,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './confirmation.component.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { textData } from 'utils/constant.utils';

interface IConfirmation {
  title: String;
  text?: String;
  buttonText?: String;
  type?: String;
  onClick?: any;
  back?: any;
  button?: any;
  outSideClick?: any;
  booking_confirmation?:boolean
}

const Confirmation = (props: IConfirmation) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};



  return (
    <div>
      <div className="confirmation_container">
        <BottomSheetComponent open={open} className="confirmation_bottom_sheet">
          <OutsideClickHandler
            onOutsideClick={() => {
              props.outSideClick(false);
            }}>
            <div className="confirmation_wrapper">
              <div className="confirmation_header">{props.title}</div>

              {props.booking_confirmation ? (
                <>
                  {textData.map((text: any, index: number) => {
                    return (
                      <>
                        <div className="booking_confirmation_body_wrapper">
                          <div
                            className="booking_confirmation_body_number"
                            style={{
                              marginLeft: `${index === 0 ? '2px' : ''}`,
                            }}>
                            {index + 1}.
                          </div>
                          <div className={'booking_confirmation_body'}>
                            {text}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <div className="confirmation_body">{props.text}</div>
              )}

              {props.type == 'textarea' && (
                <div className="confirmation_text_container">
                  <TextArea
                    className="confirmation_textarea"
                    placeholder="Write a reason for rejection..."
                  />
                </div>
              )}
              <div className="confirmation_footer_container">
                {props.button ? (
                  <>
                    <div className="confirmation_button">
                      <PrimaryButton
                        className={'confirmation_button_success'}
                        onClick={props.onClick}
                        text="Back"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="confirmation_cancel_button">
                      <PrimaryButton
                        onClick={props.back}
                        text="Cancel"
                        className="cancel_button"
                      />
                    </div>
                    <div className="confirmation_confirm_button">
                      <PrimaryButton
                        onClick={props.onClick}
                        text={props.buttonText}
                        className="confirm_button"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        </BottomSheetComponent>
      </div>
    </div>
  );
};

export default Confirmation;
