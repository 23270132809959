import { useSetState } from 'utils/functions.utils';
import './async_dropdown.component.ui.scss';
import AsyncSelect from 'react-select/async';

interface IDropdownProps {
  text?: String;
  data?: any;
  onChange?: any;
  width?: any;
  borderRadius?: any;
  background?: any;
  border?: any;
  placeholder?: any;
  color?: any;
  value?: any;
  height?: any;
  defaultValue?: any;
  placeholderColor?: any;
  fontSize?: any;
  fontWeigth?: any;
  fontFamily?: any;
  loadOptions?: any;
}
const AsyncDropdown = (props: IDropdownProps) => {
  const [state, setState] = useSetState({
    text: '',
  });
  const customStyles: any = {
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: props.background,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600,
        color: props.placeholderColor || '#c8c8cd',
      };
    },
    control: (styles) => ({
      ...styles,
      background: props.background || '#f6f6f6',
      borderRadius: props.borderRadius || 'none',
      width: props.width,
      outline: '0px',
      padding: '7px',
      border: props.border || 'none',
      color: props.color,
      '&:hover': {
        backgroundColor: '#f6f6f6',
        color: '#000',
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: '#f6f6f6',
      width: props.width,
      height: props.height,
      // overflow: scroll,

      '&:hover': {
        backgroundColor: '#f6f6f6',
        color: '#000',
      },
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#f6f6f6',
      width: props.width,
      height: props.height,
      '&:hover': {
        backgroundColor: '#f6f6f6',
        color: '#000',
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: props.color,
      fontSize: props.fontSize,
      fontFamily: props.fontFamily,
      fontWeigth: props.fontWeigth,
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   borderBottom: '1px dotted pink',
    //   color: state.isSelected ? 'red' : 'blue',
    //   padding: 20,
    // })
    dropdownIndicator: (base) => ({
      ...base,
      color: props.color || '#a29e9e', // Custom colour
    }),
  };
  return (
    <div>
      {props.defaultValue && !props.value ? (
        <AsyncSelect
          maxMenuHeight={200}
          styles={customStyles}
          options={props.data}
          onChange={props.onChange}
          placeholder={props.placeholder}
          defaultValue={{
            value: props.defaultValue,
            label: props.defaultValue,
          }}
        />
      ) : (
        <AsyncSelect
          isSearchable={true}
          isMulti
          maxMenuHeight={200}
          styles={customStyles}
          defaultOptions
          loadOptions={props.loadOptions}
          options={props.data}
          onChange={props.onChange}
          placeholder={props.placeholder}
          value={props.value}
        />
      )}
    </div>
  );
};

export default AsyncDropdown;
