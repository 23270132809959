import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import {
  Assets,
  Dropdown,
  Functions,
  PrimaryButton,
  TextArea,
} from 'utils/imports.utils';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import './notification.admin_screen.scss';
import InputAdmin from 'admin_panel/admin_common_components/ui/input_admin/input_admin.ui';
import { useQuery, useSetState } from 'utils/functions.utils';
import AdminModel from 'admin_panel/imports/models.admin_import';
import { Fragment, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ListComponent from '../../admin_common_components/ui/list_component/list_component';
import AdminInviteModal from 'admin_panel/admin_common_components/ui/admin_invite_modal/admin_invite_modal';
import _ from 'lodash'
import moment from 'moment-timezone';

const NotificationAdmin = (props: any, ref) => {
  // state
  const [state, setState] = useSetState({
    title: '',
    message: '',
    search_input: [],
    selectedOption: null,
    activeTab:"Send Notification",
    data:[],
    sendTo:'',
    daysArray:[],
    time:moment().format('HH:mm')
  });

  // ref
  const notificationRef:any = useRef()

  // querfy
  const query = useQuery();
  let params_id: any = query.get('id') || '';

  const navigate = useNavigate();

  //hooks
  useEffect(() => {
    if (!localStorage.token) {
      navigate('/login');
    }
    else geManyPushNotifications()
  }, [state.activeTab]);

  // push notification
  const pushNotification = async () => {
    Functions.notiflixLoader();
    try {
      if (state.title.length === 0) {
        Functions.notiflixFailure('Enter the Title');
      } else if (state.message.length === 0) {
        Functions.notiflixFailure('Enter the Message');
      } else if (state.search_input.length === 0 && params_id === '') {
        Functions.notiflixFailure('Enter the Send to  ');
      } else if (state.title && state.message && state.search_input) {
        let body: any = { title: state.title, content: state.message };
        if (params_id.length > 0) {
          body.user = params_id;
        } else if (state.search_input.length > 0) {
          body.status = state.search_input;
        }

        const res: any = await AdminModel.admin_notification.pushNotification(
          body,
        );

        setState({
          title: '',
          message: '',
          search_input: { label: '', value: '' },
        });
      }
    } catch (error: any) {
      Functions.Failure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const notification_data = [
    {
      label: 'All User',
      value: 'ALL USER',
    },
    {
      label: 'Active User',
      value: 'ACTIVE',
    },
    {
      label: 'Inactive User',
      value: 'INACTIVE',
    },
    {
      label: 'Pleater',
      value: 'PLEATER',
    },
  ];

  const tabList = ['Send Notification', 'Schedule Notification']

  const sendNotification: any = [
    {
      label: 'Title',
      value: 'title',
      width: '25%',
    },
    {
      label: 'Content',
      value: 'content',
      width: '25%',
    },
    {
      label: 'Send to',
      value: 'status',
      width: '15%',
    },
    {
      label: 'Date',
      value: 'created_at',
      width: '15%',
    },
  ];

  const scheduleNotification = [
    {
      label: 'Title',
      value: 'title',
      width: '23%',
    },
    {
      label: 'Content',
      value: 'content',
      width: '23%',
    },
    {
      label: 'Date',
      value: 'created_at',
      width: '12%',
    },
    {
      label: 'Day',
      value: 'day',
      width: '12%',
    },
    {
      label: 'Time',
      value: 'time',
      width: '12%',
    },
  
  ]

  // days
  const days = [
    {
      label: "All days",
      value: '*',
    },
    {
      label: "Monday",
      value: '1'
    },
    {
      label: 'Tuesday',
      value: '2'
    }, {
      label: 'Wednesday',
      value: '3'
    },
    {
      label: 'Thursday',
      value: '4'
    },
    {
      label: 'Friday',
      value: '5'
    },
    {
      label: 'Saturday',
      value: '6'
    },
    {
      label: 'Sunday',
      value: '0'
    }
  ]

  const geManyPushNotifications = async () => {
    try {
      const body = {}
      if(state.activeTab === 'Schedule Notification') body['type'] = 'Schedule Notification'
      const res: any = await AdminModel.admin_notification.getManyPushNotifications(body)
      setState({ data: res.data })
    }
    catch (err: any) {
      Functions.notiflixFailure(err)
      console.log('err', err)
    }
  }

  const onSend = async () => {
    try {
      if (!params_id && _.isEmpty(state.sendTo)) {
        Functions.notiflixFailure('Select users');
      }
      else if (_.isEmpty(state.title)) {
        Functions.notiflixFailure('Enter the Title');
      }
      else if (_.isEmpty(state.message)) {
        Functions.notiflixFailure('Enter the message');
      }
      else if (state.activeTab === 'Schedule Notification' && _.isEmpty(state.daysArray)) {
        Functions.notiflixFailure('Select days')
      }
      else {
        const body = {
          title: state.title,
          content: state.message
        }
        if (!params_id) body['status'] = state.sendTo
        if (params_id) body['user'] = params_id

        if (state.activeTab === 'Schedule Notification') {
          let day = state.daysArray.includes('*')
          if (day) {
            day = ["*"]
          }
          else {
            day = _.sortBy(state.daysArray, (e: string) => {
              const num = parseFloat(e);
              return isNaN(num) ? Infinity : num;
            })
          }

          body['day'] = day
          body['time'] = state.time

        }
        await AdminModel.admin_notification.sendPushNotification(body)
        if (params_id) navigate('/users')
        else {
          clearStates ()
          geManyPushNotifications()
        }
      }
    }
    catch (err: any) {
      Functions.notiflixFailure(err)
      console.log('err', err)
    }
  }

  const onDelete = async (id: string) => {
    try {
      const body = {
        notification_id: id
      }
      await AdminModel.admin_notification.deleteSchedulerPushNotification(body)
      geManyPushNotifications()
    }
    catch (err: any) {
      Functions.notiflixFailure(err)
      console.log('err', err)
    }
  }

  const clearStates  = () => {
    setState({
      title:'',
      status:'',
      message:'',
      daysArray:[]
    })
    notificationRef?.current.closeModal()
  }

  const notificationModal = () => {
    notificationRef.current?.openModal()
  }

  const switchNotificationTab = (item: string) => {
    setState({ activeTab: item })
  }

  const addDays = (item: string) => {
    let data = state.daysArray;
    if (item === '*') {
      if (data.includes('*')) {
        data = [];
        setState({ daysArray: data });
      } else {
        data = []
        days.forEach((day: any, i: number) => {
          data.push(day.value);
        });
        setState({ daysArray: data });
      }
    } else {
      const findIndex = _.findIndex(data, (e: string) => e === item);
      if (findIndex !== -1) {
        data.splice(findIndex, 1);
        setState({ daysArray: data });
      } else {
        data.push(item);
        setState({ daysArray: data });
      }
    }
  };

  const renderType = () => {
    let type: string;
    if (state.activeTab === 'Send Notification') type = 'sendNotification'
    else type = 'scheduleNotification'
    return type
  }

  return (
    <div className="notification_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="notification_wrapper">
        <AdminHeader text={'Notification'} />
        <div className='notification_list_wrapper'>
          <div className='notification_tab_list_wrapper'>
            {tabList.map((item: string, i: number) => {
              return (
                <Fragment key={i}>
                  <div className={`notification_tab_list_content ${state.activeTab === item ? 'notification_tab_list_active' : 'notification_tab_list_in_active'}`}>
                    <div onClick={() => switchNotificationTab(item)} style={{ cursor: 'pointer', width: 'fit-content' }}>
                      {item}
                    </div>
                  </div>
                </Fragment>
              )
            })}
          </div>
          {/* tab line */}
          <div className='notification_tab_list_line'>
            {tabList.map((item: any, i) => {
              return (
                <Fragment key={i}>
                  <div className={`${state.activeTab === item ? 'notification_tab_list_line_active' : 'notification_tab_list_line_in_active'}`}>
                  </div>
                </Fragment>
              )
            })}
          </div>
          <div onClick={() => notificationModal()}
            className={`${state.activeTab === 'Send Notification' ?
              "notification_modal" : "notification_modal schedule_notification_modal"}`}>
            <div className='notification_modal_button_wrapper'>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={state.activeTab === 'Send Notification' ? Assets.send_notification : Assets.plus_icon} width={20} height={20} />
              </div>
              <div className='notification_send_button_content'>
                {state.activeTab === 'Send Notification' ? "Send Notification" : 'Notification'}
              </div>
            </div>
          </div>
          {/* table */}
          <div className='notification_table_wrapper'>
          <ListComponent
            onDelete={(data:any)=>onDelete(data)}
            showAction={state.activeTab === "Send Notification" ? false : true}
            titleArray={state.activeTab === 'Send Notification' ? sendNotification : scheduleNotification}
            type={'admin_notification_table'}
            data={state.data}
          />
          </div>
        </div>
      </div>
      <AdminInviteModal
        onSend={() => onSend()}
        onCancel={() => clearStates()}
        sendTo={state.sendTo.value}
        sendToOnChange={(value) => setState({ sendTo: value })}
        notification_data={notification_data}
        title={state.title}
        titleOnChange={(title: string) => setState({ title })}
        message={state.message}
        messageOnChange={(message: string) => setState({ message })}
        type={renderType()}
        days={days}
        daysArray={state.daysArray}
        ref={notificationRef}
        paramsId = {params_id}
        daysOnChange={(day: string) => addDays(day)}
        timeValue = {state.time}
        timeOnChange={(time:string)=> setState({time})}
         />
    </div>
  );
};

export default NotificationAdmin;
