import React, { useEffect } from 'react';
import './splash.screen.scss';
import { useNavigate } from 'react-router-dom';
import { Assets, Functions, Models } from '../../../utils/imports.utils';
import { isMobileApp } from 'utils/functions.utils';
function SplashScreen() {
  // route
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.token) {
      setTimeout(() => {
        getUser();
      }, 1000);
    } else {
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }
  }, []);
  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      localStorage.setItem('role', res.data?.role);
      if (res.data.role === 'pleater') {
        navigate('/orders');
      } else if (res.data.role === 'admin') {
        if (isMobileApp()) {
          navigate('/home');
        } else {
          window.location.pathname = '/dashboard';
        }
      } else {
        navigate('/home');
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    }
    Functions.notiflixRemove();
  };

  

  return (
    <div className="splash_screen">
      <div className="splash_screen_logo">
        <img src={Assets.logo} alt="pleat-logo" />
      </div>
    </div>
  );
}

export default SplashScreen;
