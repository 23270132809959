import instance from 'utils/axios.utils';

const coupon = {
  getManyCoupon: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'coupon/get_all_coupons';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getCoupon: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'coupon/get_coupon_by_id';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  createCoupon:(data:any)=> {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'coupon/create_coupon';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  editCoupon:(data:any)=> {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'coupon/edit_coupon';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  deleteCoupon:(data:any)=> {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'coupon/delete_coupon';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise
  }
};

export default coupon;
