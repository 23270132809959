import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  BottomSheetComponent,
  Header,
  PrimaryButton,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './payment_transfer.component.scss';
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

interface IPaymentTransfer {
  text?: String;
  onChange?: any;
  value?: any;
  onClick?: any;
  availableBalance?: any;
  close?: any;
  outSideClick?: any;
}

const PaymentTransfer = (props: IPaymentTransfer) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  // route

  const navigate = useNavigate();

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className="payment_container">
      <BottomSheetComponent open={open} className="payment_bottomsheet">
        <OutsideClickHandler
          onOutsideClick={() => {
            props.outSideClick(false);
          }}>
          <div className="payment_wrapper">
            <div className="payment_body_container">
              <div className="payment_header">
                <Header onClick={props.close} title="Payment" />
              </div>
              <div className="payment_amount_container">
                <div className="payment_value">
                  <input
                    type="number"
                    className="payment_value_input_text"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}></input>
                </div>
                <div className="payment_amount_wrapper">
                  <div className="payment_available_balance_text">
                    Available Balance
                  </div>
                  <div className="payment_available_balance_value">
                    {props.availableBalance}
                  </div>
                </div>
              </div>
              {props.value <= props.availableBalance &&
              props.availableBalance !== 0 ? (
                <div style={{display:'none'}} className="payment_instruction_text">
                  Transfer amount should be minimum of Rs. 1,000 and maximum of
                  Rs. 50,000
                </div>
              ) : (
                <div className="payment_instruction_not_sufficent_text">
                  Not sufficient amount to your account
                </div>
              )}
            </div>
            <div className="payment_footer_container">
              {props.availableBalance >= props.value &&
              props.availableBalance !== 0 ? (
                <PrimaryButton onClick={props.onClick} text="Transfer" />
              ) : null}
            </div>
          </div>
        </OutsideClickHandler>
      </BottomSheetComponent>
    </div>
  );
};

export default PaymentTransfer;
