import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets, PrimaryButton } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './cancelled.screen.scss';
import '../../common_components/hoc/mobile_screen.scss';


interface ICancelled {
  text?: String;
}

const Cancelled = (props: ICancelled) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({});

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error: any) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div className='mobile_screen'>
    <div className="cancel_container mobile_screen">
      <div className="cancel_wrapper">
        <div className="cancel_image">
          <img src={Assets.delivery_man} className="delivery_man_image" />
        </div>
        <div className="cancel_title">Booking Cancelled!</div>
        <div className="cancel_message">
          Your booking ID46576 has been cancelled successfully.
        </div>
      </div>
      <div className="cancel_footer">
        <PrimaryButton text="Home" />
      </div>
    </div>
    </div>
  );
};

export default Cancelled;
