import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import OutsideClickHandler from 'react-outside-click-handler';


const BottomSheetComponent = (props) => {
  return (
    <div> 
        <BottomSheet  {...props}>{props.children}</BottomSheet>
    </div>
  );
};

export default BottomSheetComponent;
