import AdminModel from 'admin_panel/imports/models.admin_import';
import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import ListComponent from '../../admin_common_components/ui/list_component/list_component';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSetState } from 'utils/functions.utils';
import { Dropdown, Functions, Searchbar } from 'utils/imports.utils';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import './bookings.admin_screen.scss';
import moment from 'moment';
import _ from 'lodash';

const Bookings = (props: any) => {
  const [state, setState] = useSetState({
    booking_data: [],
    search_status: '',
    search_input: '',
    search_data: [],
    skip:0,
    limit:100,
    search:''
  });

  // query
  const query = useQuery();
  const params_id = query.get('id') || '';

  // getManybooking
  const getManybookings = async () => {
    Functions.notiflixLoader();
    try {
      const body:any = {
        limit:state.limit,
        skip:state.skip
      }
      if (state.search.length > 0) {
        const search = parseInt(state.search);
        if (search) {
          body['search'] = search;
        } else {
          body['search_name'] = state.search;
        }
      }
      if(state.search_status) {
        delete body.search
        delete body.search_name
        body['search_status'] = state.search_status.value
      }
      const res: any = await AdminModel.bookings.getManyBookingWithLimit(body);
      setState({booking_data:res.data.docs})
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  
  useEffect(() => {
    getManybookings()
  }, [state.search,state.search_status]);


  const titledata: any = [
    {
      label: '#',
      value: 's.no',
      width: '5%',
    },
    {
      label: 'Order ID',
      value: 'id',
      width: '12%',
    },
    {
      label: 'Date',
      value: 'created_at',
      width: '15%',
    },
    {
      label: 'Customer',
      value: 'name',
      width: '16%',
      key:'user',
      isNested:true
    },
    {
      label: 'Pleater',
      value: 'name',
      width: '16%',
      key:'pleater',
      isNested:true
    },
    {
      label: 'Status',
      value: 'status',
      width: '20%',
    },
  ];
  const paymentMethod: any = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'ORDER PLACED',
      label: 'Order Placed',
    },
    {
      value: 'WAITING FOR PICKUP',
      label: 'Waiting For Pickup',
    },
    {
      value: 'PICKED UP',
      label: 'Picked Up',
    },
    {
      value: 'REACHED',
      label: 'Reached',
    },
    {
      value: 'WAITING FOR DISPATCH',
      label: 'Waiting For Dispatch',
    },
    {
      value: 'DISPATCH',
      label: 'Dispatch',
    },
    {
      value: 'DELIVERED',
      label: 'Delivered',
    },
    {
      value: 'PICKUP SCHEDULED',
      label: 'Pickup Scheduled',
    },
    {
      value: 'DROP SCHEDULED',
      label: 'Drop Scheduled',
    },
    {
      value: 'REFUND INITIATED',
      label: 'Refund Initiated',
    },
    {
      value: 'REFUNDED',
      label: 'Refund',
    },
    {
      value: 'ORDER CANCELLED',
      label: 'Order Cancelled',
    },
    {
      value: 'QUEUED',
      label: 'Queued',
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.token) {
      navigate('/login');
    }
  }, []);

  return (
    <div className="bookings_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="bookings_wrapper">
        <AdminHeader text={'Bookings'} />
        <div className="bookings_table_container">
          <div className="table_header">
            <div className="search_wrapper">
              <Searchbar
                className={'search_panel'}
                value={state.search}
                onChange={(value: any) => setState({ search: value ,search_status:''})}
                placeholder={'Search'}
                style={{ borderRadius: '16px' }}
              />
            </div>
            <div className="dropdown_header">
              <Dropdown
                data={paymentMethod}
                value={state.search_status}
                onChange={(search: any) =>
                  setState({ search_status:search})
                }
                width={'220px'}
                borderRadius={'15px'}
                placeholder={'Status...'}
              />
            </div>
          </div>
          <ListComponent
            titleArray={titledata}
            type={'booking_table'}
            data={state.booking_data}
          />
        </div>
      </div>
    </div>
  );
};

export default Bookings;
