import testPic from 'assets/images/dp.jpeg';
import close from 'assets/icons/close.svg';
import back from 'assets/icons/back.svg';
import profile_placeholder from 'assets/icons/user_placeholder.svg';
import logo from 'assets/images/logo.svg';
import toggle from 'assets/icons/toggle.svg';
import settings from 'assets/icons/settings.svg';
import search_active from 'assets/icons/search_active.svg';
import settings_inactive from 'assets/icons/settings_inactive.svg';
import search_inactive from 'assets/icons/search-inactive.svg';
import requisition_active from 'assets/icons/requisition_active.svg';
import requisition from 'assets/icons/requisition.svg';
import house_active from 'assets/icons/house_active.svg';
import house from 'assets/icons/house.svg';
import buildings_active from 'assets/icons/buildings_active.svg';
import buildings from 'assets/icons/buildings.svg';
import department from 'assets/icons/dept.svg';
import department_active from 'assets/icons/dept_active.svg';
import map from 'assets/icons/map.svg';
import map_active from 'assets/icons/map_active.svg';
import email from 'assets/icons/EnvelopeSimple.svg';
import email_active from 'assets/icons/EnvelopeSimple_active.svg';
import manageusers from 'assets/icons/UserPlus.svg';
import manageusers_active from 'assets/icons/UserPlus_active.svg';
import password from 'assets/icons/Password.svg';
import password_active from 'assets/icons/Password_active.svg';
import profile from 'assets/icons/Profile.svg';
import profile_active from 'assets/icons/Profile_active.svg';
import job from 'assets/icons/job.svg';
import offer from 'assets/icons/offer.svg';
import Linkedin from 'assets/icons/linked_in.svg';
import facebook_black from 'assets/icons/facebook.svg';
import search from 'assets/icons/Search.svg';
import logout from 'assets/icons/logout.svg';
import bell from 'assets/icons/bellicon.svg';
import plus_with_box from 'assets/icons/plus_with_box.svg';
import loader from 'assets/videos/loader.gif';
import share from 'assets/icons/share.svg';
import black_close from 'assets/icons/black_close.svg';
import trash_white from 'assets/icons/trash_white.svg';
import uncheck from 'assets/icons/uncheck.svg';
import edit from 'assets/icons/edit.svg';
import checked from 'assets/icons/checked.svg';
import trash from 'assets/icons/trash.svg';
import login_img from 'assets/images/login_img.svg';
import arrowIndicate from 'assets/icons/arrowIndicate.svg';
import bookOrder from 'assets/images/bookOrder.svg';
import login from 'assets/images/login.png';
import nav from 'assets/icons/stroke.svg';
import saree from 'assets/images/saree.svg';
import addReview from 'assets/icons/subtract.svg';
import help from 'assets/icons/help.svg';
import terms from 'assets/icons/terms.svg';
import signout from 'assets/icons/signout.svg';
import sareeImage from 'assets/images/saree_image.svg';
import error from 'assets/icons/error.svg';
import home_inactive from 'assets/icons/home_inactive.svg';
import orders_inactive from 'assets/icons/orders_inactive.svg';
import notification_inactive from 'assets/icons/notification_inactive.svg';
import list_inactive from 'assets/icons/list_inactive.svg';
import like from 'assets/icons/like.svg';
import deals_saree from 'assets/images/deals_saree.jpg';
import sample_work from 'assets/images/sample_work.png';
import camera from 'assets/icons/camera.svg';
import checkbox_active from 'assets/icons/checkbox_active.svg';
import truck from 'assets/icons/truck.svg';
import gpay from 'assets/icons/gpay.svg';
import pay_on_delivery from 'assets/icons/pay_on_delivery.svg';
import radiobox_inactive from 'assets/icons/radio_box_inactive.svg';
import radiobox_active from 'assets/icons/radio_box_active.svg';
import offer_card from 'assets/images/offer_card.png';
import lightning_fill from 'assets/icons/lightning_fill.svg';
import status_active from 'assets/icons/status_active.svg';
import dashed_line from 'assets/icons/dashed_line.svg';
import radio_inactive from 'assets/icons/radiobox_active.svg';
import radio_active from 'assets/icons/radiobox_inactive.svg';
import more from 'assets/icons/more.svg';
import delivery_man from 'assets/images/delivery_man.jpg';
import success_tick from 'assets/images/success.jpg';
import camera_blue from 'assets/icons/Camera_blue.svg';
import earnings from 'assets/icons/earnings.svg';
import transferred from 'assets/icons/transferred.svg';
import payment_card from 'assets/images/payment_card.jpg';
import bullet_active from 'assets/icons/bullet_active.svg';
import bullet_inactive from 'assets/icons/bullet_inactive.svg';
import book_now from 'assets/icons/book_now.svg';
import reviews from 'assets/images/reviews.jpg';
import discount_percentage from 'assets/icons/discount_percentage.svg';
import disable_status from 'assets/icons/disable_status.svg';
import booking_icon from 'assets/icons/booking_icon.svg';
import timeicon from 'assets/icons/Time.svg';
import add_saree from 'assets/icons/add_saree.svg';
import edit_icon from 'assets/icons/edit_icon.svg';
import payment from 'assets/icons/payment.svg';
import address_pin from 'assets/icons/address_pin.svg';
import checkbox_disable from 'assets/icons/checkbox_inactive.svg';
import bullet_success from 'assets/icons/bullet_success.svg';
import home_active from 'assets/icons/home_active.svg';
import order_active from 'assets/icons/order_active.svg';
import notification_active from 'assets/icons/notification_active.svg';
import account_active from 'assets/icons/account_active.svg';
import delete_request from 'assets/icons/delete_request.svg';
import order_cancel from 'assets/icons/order_cancel.svg';
import lean_uncheck from 'assets/icons/lean.svg'
import lean_checked from 'assets/icons/lean_check.svg'
import avg_uncheck from 'assets/icons/avg.svg'
import avg_checked from 'assets/icons/avg_check.svg'
import above_avg_uncheck from 'assets/icons/above_avg.svg'
import above_avg_checked from 'assets/icons/above_avg_check.svg'
import payments from 'assets/icons/payments.svg'
import payment_active from 'assets/icons/payment_active.svg'
import my_location from 'assets/icons/my_location.png'
import privacy from 'assets/icons/privacy.svg'
import order from 'assets/images/order.png'
import location from 'assets/icons/location.svg'
import logo1 from 'assets/icons/logo1.svg'
import arrow from 'assets/icons/arrow.png'
import accordion from 'assets/icons/accordion.svg'
import whatsapp from 'assets/icons/whatsapp.svg'
import whatsapp_icon from 'assets/icons/whatsapp-icon.png'
import header from 'assets/icons/header-logo.svg'
import dashboard_active from 'assets/icons/dashboard_active.svg';
import dashboard_inactive from 'assets/icons/dashboard_inactive.svg';
import users_active from 'assets/icons/users_active.svg';
import users_inactive from 'assets/icons/users_inactive.svg';
import bookings_inactive from 'assets/icons/booking_inactive.svg';
import bookings_active from 'assets/icons/booking_active.svg';
import payout_inactive from 'assets/icons/payout_inactive.svg';
import payout_active from 'assets/icons/payout_active.svg';
import logout_active from 'assets/icons/logout_active.svg';
import logout_inactive from 'assets/icons/logout_inactive.svg';
import setting_active from 'assets/icons/setting_active.svg';
import setting_inactive from 'assets/icons/setting_inactive.svg';
import adminlogo from 'assets/icons/pleat_admin.svg';
import admin_barchart from 'assets/icons/admin_barchart.svg';
import message_box from 'assets/icons/message_box.svg';
import comment from 'assets/icons/comment.svg';
import user_picture from 'assets/icons/user_picture.svg';
import notification from 'assets/icons/notification.svg';
import vision_eye from 'assets/icons/vision_eye.svg';
import trash_icon from 'assets/icons/trash_icon.svg';
import call from 'assets/icons/call.svg';
import feedback from 'assets/icons/feedback.svg';
import terms_condition from 'assets/icons/terms_condition.svg';
import trash_new from 'assets/icons/trash_new.svg';
import reschedule from 'assets/icons/reschedule.svg';
import admin_map from 'assets/icons/admin_map.svg';
import mobile from 'assets/icons/mobile.svg';
import admin_email from 'assets/icons/admin_email.svg';
import admin_radio_active from 'assets/icons/admin_radio_active.svg';
import admin_radio_inactive from 'assets/icons/admin_radio_inactive.svg';
import notification_admin_active from 'assets/icons/notification_admin_active.svg';
import notification_user from 'assets/icons/notification_user.svg';
import booking_list from 'assets/icons/booking_list.svg';
import live_location_view from 'assets/icons/live_location_view.svg';
import live_location_active from 'assets/icons/live_location_active.svg';
import live_location_inactive from 'assets/icons/live_location_inactive.svg';
import refer_earn from 'assets/icons/refer_earn.svg';
import account_delete from 'assets/icons/account_delete.svg';
import edit_new_icon from 'assets/icons/edit_new_icon.svg';
import calendar_icon from 'assets/icons/calendar_icon.svg';
import calendar_close from 'assets/icons/calendar_close.svg';
import clock from 'assets/icons/clock.svg'
import location_white from 'assets/icons/location_white.svg';
import dunzo_icon from 'assets/icons/dunzo_icon.svg';
import reason_icon from 'assets/icons/reason_icon.svg';
import pickup_icon from 'assets/icons/pickup_icon.svg';
import porter_icon from 'assets/icons/porter_icon.svg';
import info_active from 'assets/icons/Info_active.svg';
import info_disable from 'assets/icons/Info_disbale.svg'
import express_courier from 'assets/icons/express_courier.svg'
import courier_inactive_checkbox from 'assets/icons/courier_inactive_checkbox.svg'
import courier_active_checkbox from 'assets/icons/courier_active_checkbox.svg'
import standard_delivery from 'assets/icons/standard_delivery.svg'
import relax_delivery from 'assets/icons/relax_delivery.svg';
import green_tick from 'assets/icons/green_tick.svg'
import clock_black from 'assets/icons/clock_black.svg'
import pincode_error from 'assets/icons/pincode_error.svg'
import bars from 'assets/icons/bars.svg'
import xmark from 'assets/icons/xmark.svg'
import send_notification from 'assets/icons/send_notification.svg'
import notification_admin_checkbox_disable from 'assets/icons/notification_admin_checkbox_disable.svg'
import notification_admin_checkbox_active from 'assets/icons/notification_admin_checkbox_active.svg'
import plus_icon from 'assets/icons/admin_plus_icon.svg'
import admin_edit_icon from 'assets/icons/admin_edit_icon.svg'
import coupon_modal_disable_checkbox from 'assets/icons/coupon_modal_disable_checkbox.svg'
import coupon_modal_active_checkbox from 'assets/icons/coupon_modal_active_checkbox.svg'
import coupon_sidebar_icon from 'assets/icons/coupon_sidebar_icon.svg'
import coupon_active_sidebar_icon from 'assets/icons/coupon_active_sidebar_icon.svg'
import saree_upload from 'assets/icons/saree_upload.svg'
import saree_remove_icon from 'assets/icons/saree-remove_icon.svg'






const Assets = {
  testPic,
  close,
  back,
  profile_placeholder,
  logo,
  toggle,
  settings,
  search_active,
  settings_inactive,
  search_inactive,
  requisition_active,
  requisition,
  house_active,
  house,
  buildings_active,
  buildings,
  department,
  department_active,
  map,
  map_active,
  email,
  email_active,
  manageusers,
  manageusers_active,
  password,
  password_active,
  profile,
  profile_active,
  job,
  offer,
  Linkedin,
  facebook_black,
  search,
  logout,
  bell,
  plus_with_box,
  loader,
  share,
  black_close,
  trash_white,
  uncheck,
  edit,
  checked,
  trash,
  login_img,
  arrowIndicate,
  bookOrder,
  login,
  nav,
  saree,
  addReview,
  help,
  terms,
  signout,
  sareeImage,
  error,
  home_inactive,
  orders_inactive,
  notification_inactive,
  list_inactive,
  like,
  deals_saree,
  sample_work,
  camera,
  checkbox_active,
  truck,
  gpay,
  pay_on_delivery,
  radiobox_active,
  radiobox_inactive,
  offer_card,
  lightning_fill,
  status_active,
  dashed_line,
  radio_active,
  radio_inactive,
  more,
  delivery_man,
  success_tick,
  camera_blue,
  earnings,
  transferred,
  payment_card,
  bullet_active,
  bullet_inactive,
  book_now,
  reviews,
  discount_percentage,
  disable_status,
  booking_icon,
  timeicon,
  add_saree,
  edit_icon,
  payment,
  address_pin,
  checkbox_disable,
  bullet_success,
  home_active,
  order_active,
  notification_active,
  notification_admin_active,
  account_active,
  delete_request,
  order_cancel,
  lean_uncheck,
  lean_checked,
  avg_uncheck,
  avg_checked,
  above_avg_uncheck,
  above_avg_checked,
  my_location,
  payments,
  payment_active,
  privacy,
  order,
  location,
  logo1,
  arrow,
  accordion,
  whatsapp,
  header,
  whatsapp_icon,
  dashboard_active,
  dashboard_inactive,
  payout_active,
  payout_inactive,
  users_active,
  users_inactive,
  bookings_active,
  bookings_inactive,
  setting_inactive,
  setting_active,
  logout_active,
  logout_inactive,
  adminlogo,
  admin_barchart,
  notification,
  comment,
  user_picture,
  message_box,
  vision_eye,
  trash_icon,
  call,
  feedback,
  terms_condition,
  trash_new,
  reschedule,
  mobile,
  admin_map,
  admin_email,
  admin_radio_inactive,
  admin_radio_active,
  notification_user,
  booking_list,
  live_location_active,
  live_location_inactive,
  live_location_view,
  refer_earn,
  account_delete,
  edit_new_icon,
  calendar_icon,
  calendar_close,
  clock,
  location_white,
  dunzo_icon,
  porter_icon,
  reason_icon,
  pickup_icon,
  info_active,
  info_disable,
  express_courier,
  courier_active_checkbox,
  courier_inactive_checkbox,
  standard_delivery,
  relax_delivery,
  green_tick,
  clock_black,
  pincode_error,
  bars,
  xmark,
  send_notification,
  notification_admin_checkbox_active,
  notification_admin_checkbox_disable,
  plus_icon,
  admin_edit_icon,
  coupon_modal_active_checkbox,
  coupon_modal_disable_checkbox,
  coupon_sidebar_icon,
  coupon_active_sidebar_icon,
  saree_upload,
  saree_remove_icon


};

export default Assets;
