import instance, { v2Route } from 'utils/axios.utils';

const bookings = {
  getManybookings: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = 'booking/get_many_booking_populate';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getBookingID: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/get_booking`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  rescheduleExisting: (body?,id?:string) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/reschedule/${id}`;
      v2Route()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  adminAssignPleater: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/admin_assign_pleater`;
      v2Route()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getNearblePleaterLocation: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/get_near_pleater_location`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editBooking: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/edit_booking`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getManyBookingWithLimit: (body:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/get_many_booking_limit`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  cancelBooking:(id:any)=>{
    let promise = new Promise((resolve, reject) => {
      let url = `booking/cancel_task/${id}`;
      v2Route()
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getQuoteAndDistance :(body:any)=>{
    let promise = new Promise((resolve, reject) => {
      let url = `booking/get_quote_and_distance`;
      v2Route()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  }
};

export default bookings;
