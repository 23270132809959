import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  Header,
  Footer,
  Dropdown,
  PrimaryButton,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './payment.screen.scss';
import Notiflix from 'notiflix';
import { expressDelivery, deliveryCharge } from '../../utils/constant.utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TransferRequestfrom from '../transfer_request/transfer_request.screen';


interface IPayment {
  text?: String;
}

const Payment = (props: IPayment) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({
    your_money: 0,
    payout_data: [],
    transfer_amount: '',
    total_amount:0,
    earning_this_month: 0,
    hide_transfer_request: true,
    active_tab: false,
    processing_amount:0
  });

  // route

  const navigate = useNavigate();

  //Hooks
  useEffect(() => {
    if (!localStorage.token) {
      window.location.pathname = '/';
    } else {
      // getBookingPayment();
      getUser();
      getPayoutHistory();
    }
  }, []);

  // Network req

  const getPayoutHistory = async () => {
    let data: any = [];
    Functions.notiflixLoader();
    try {
      const res: any = await Models.payout.getManyPayout();
      setState({
        payout_data: res.data,
        your_money: res.payoutDetails.your_money,
        earning_this_month:res.payoutDetails.earning_this_month,
        processing_amount:res.payoutDetails.processing_amount,
        transfer_amount:res.payoutDetails.transfered_amount,
        total_amount:res.payoutDetails.total_amount
      });

    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      if (res.data.role !== 'pleater') {
        navigate('/home');
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  

  const transferRequestClose = () => {
    setState({ hide_transfer_request: true });
  };

  const totalBooking = async () => {
    setState({ active_tab: true });
    setState({ sumBooking: true });
    Functions.notiflixLoader();
    try {
      const res: any = await Models.orders.getManyOrders();
      let data = res.data.filter((item: any) => item.status === 'DELIVERED');

      setState({ payout_data: data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };
  const totalPayout = async () => {
    setState({ active_tab: false });
    setState({ sumBooking: false });
    Functions.notiflixLoader();
    try {
      const res: any = await Models.payout.getManyPayout();
      setState({
        payout_data: res.data,
        your_money: res.payoutDetails.your_money,
        earning_this_month:res.payoutDetails.earning_this_month,
        processing_amount:res.payoutDetails.processing_amount,
        transfer_amount:res.payoutDetails.transfered_amount,
        total_amount:res.payoutDetails.total_amount
      });
     
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  return (
    <div className="payment_container">
      <div className="payment_header_wrapper">
        <div className="payment_header_container">
          <Header onClick={() => navigate(-1)} title="Payment" />
        </div>
      </div>
      <div className="payment_wrapper_scroll">
        <div className="payment_balance_amount_container">
          <div className="payment_subheading">Balance Amount</div>
          <div className="payment_balance_amount_wrapper">
            <div className="payment_balance_card">
              <img
                src={Assets.payment_card}
                alt="payment_card"
                className="payment_card"
              />
            </div>
            <div className="payment_balance_card_container">
              <div className="payment_balance_card_wrapper">
                <div className="payment_balance_your_money">Your Money</div>
                <div className="payment_balance_money_value">
                  {state.your_money}
                  {/* {state.earning_money === -50 ? 0 : state.earning_money} */}
                </div>
                <div className="payment_balance_earning">
                  <div className="payment_balance_earning_text">
                    Earning This Month
                  </div>
                  <div className="payment_balance_earning_value">
                    {state.earning_this_month}
                  </div>
                </div>
              </div>
              <div className="payment_balance_card_button">
                <PrimaryButton
                  onClick={() => setState({ hide_transfer_request: false })}
                  text="Transfer"
                  className="transfer_button"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="payment_overall_transaction_container">
          <div className="payment_overall_transaction_header">
            <div className="payment_subheading">Overall Transaction</div>
          </div>
          <div className="payment_overall_processing_amount_container">
             <div className='payment_overall_processing_status'> 
              <div><img src={Assets.bullet_active} alt="bullet_active"/></div>
              <div>
                Total processing amount
              </div>
             </div>
             <div className="payment_overall_processing_amount_list">
                {state.processing_amount }
             </div>
            </div>
          <div className="payment_earning_card_container">
            <div
              onClick={() => totalPayout()}
              className={
                state.active_tab
                  ? 'payment_earning_wrapper2_inactive'
                  : 'payment_earning_wrapper2'
              }>
              <div className="payment_earning_icon">
                <img src={Assets.transferred} className="earnings_icon" />
              </div>
              <div className="payment_total_earning_text">
                Total Transferred
              </div>
              <div className="payment_total_earning_value">
                {state.transfer_amount}
              </div>
            </div>
            <div
              onClick={() => totalBooking()}
              className={
                state.active_tab
                  ? 'payment_earning_wrapper1'
                  : 'payment_earning_wrapper1_inactive'
              }>
              <div className="payment_earning_icon">
                <img src={Assets.earnings} className="earnings_icon" />
              </div>
              <div className="payment_total_earning_text">Total Earnings</div>
              <div className="payment_total_earning_value">
                {state.total_amount}
              </div>
            </div>
          </div>
        </div>
        <div className="payment_transaction_container">
          {state.payout_data.map((transaction: any, index: number) => (
            <div key={index} className="payment_transaction_wrapper">
              <div className="payment_transaction_bullet_icon">
                <img
                  src={`${
                    transaction.status === 'TRANSFERED' ||
                    transaction.status === 'DELIVERED'
                      ? Assets.bullet_success
                      : Assets.bullet_active
                  }`}
                  className="bullet_icon"
                />
              </div>
              <div className="payment_transaction_status">
                <div
                  className={`${
                    transaction.status === 'TRANSFERED' ||
                    transaction.status === 'DELIVERED'
                      ? 'payment_transaction_status_success'
                      : 'payment_transaction_status_text'
                  }`}>
                  {transaction.status}
                </div>
                <div className="payment_transaction_status_time">
                  {moment(transaction.created_at).format('h:mma, MMM D, YYYY')}
                </div>
              </div>
              <div className="payment_transaction_amount">
                Rs.{' '}
                {state.sumBooking
                  ? transaction?.delivery_charges?.pleater_charge
                  : transaction.amount}
                {/* {state.sumBooking
                  ? transaction.express_delivery
                    ? transaction.amount - expressDelivery - deliveryCharge
                    : transaction.amount - deliveryCharge
                  : transaction.amount} */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* TransferRequestfrom */}
      {state.hide_transfer_request ? null : (
        <div>
          <TransferRequestfrom
            outSideClick={(value: any) =>
              setState({ hide_transfer_request: !value })
            }
            onChange={(value) => setState({ hide_transfer_request: !value })}
            close={() => transferRequestClose()}
            availableBalance={state.your_money}
          />
        </div>
      )}

      <div className="payment_footer_container">
        <Footer />
      </div>
    </div>
  );
};

export default Payment;
