import instance from 'utils/axios.utils';

const coupon = {
  getCoupon: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'coupon/get_many_coupon';
      instance()
        .post(url,data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  couponVerify: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'coupon/coupon_verify';
      instance()
        .post(url,data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },


  

  uploadFile: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/get_signed_url';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default coupon;
