import React from 'react';
import { useSetState } from 'utils/functions.utils';
import './notification.component.scss';
import { Assets, } from '../../utils/imports.utils';

interface NotifyComponent {
    status?: string;
    orderId?: string;
    reason?: string;
    review?: string;
    time?: string;
    image?:string
  
}

const NotificationComponent = (props: NotifyComponent) => {
    const { status, orderId, reason, review, time } = props
    return (
        <div>
        <div className='notification_wrap'>
            <div className='notification_container'>
                <div className='saree_image_container'>
                    <div className=''>
                        <img className='saree_image'  src={props.image} />
                    </div>
                </div>
                <div className='status_wrap'>
                    <div className='status'>{status}</div>
                    <div className='order_id'>{orderId}</div>
                    <div className='view_text'>{reason}</div>
                    <div className='review_wrap'>
                        {review ?
                            <img width={9} src={Assets.addReview} className='edit_image' /> : null}
                        <div className='view_text'>{review}</div>
                    </div>
                </div>
                <div className='time_wrap'>
                    <div className='time'>{time}</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default NotificationComponent;



