import instance from "utils/axios.utils";

const Feedback = {
    createFeedback: (body:any) => {
        let promise = new Promise((resolve, reject)=>{
          let url = "feedback/create_feedback";
          instance().post(url, body).then(res=>{
            resolve(res.data)
          }).catch(error=>{
            if(error.response){
              reject(error.response.data.message)
            }else{
              reject(error)
            }
          })
        })
        return promise
      },
}

export default Feedback