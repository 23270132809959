import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  PrimaryButton,
  BottomSheetComponent,
  Header,
  StarRating,
  TextArea,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './reviews.screen.scss';
import {useNavigate} from 'react-router-dom';
import '../../common_components/hoc/mobile_screen.scss';


interface IReviews {
  text?: String;
  onClick?:any,
  onChange?:any;
  value?:any
  rating?:any
  onPress?:any
  rating_value?:any
  reviewSuccessShow?:boolean;
  id?:string;
}

const Reviews = (props: IReviews) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({ addReview: true });

  // route

  const navigate = useNavigate()

  //Hooks
  useEffect(() => {}, []);

  // Network req

  //Logic
  const testLogic = () => {};

  return (
    <div className='mobile_screen'>
    <div className="reviews_container mobile_screen">
      {props.reviewSuccessShow ? (
        <BottomSheetComponent open={open}>
          <div className="reviews_bootomsheet_container">
            <div className="reviews_bottomsheet_header">
              <Header onClick={props.onClick} title="Review" />
            </div>
            <div className="reviews_bottomsheet_rating_container">
              <div className="reviews_rating_text">How many stars for us?</div>
              <StarRating initailValue={0} iconsCount={5} ratingValue={props.rating_value} onClick={(rating:any)=>props.rating(rating)} />
            </div>
            <div className="reviews_bottomsheet_textarea">
              <TextArea
                onChange={(e)=>props.onChange(e.target.value)}
                value={props.value}
                placeholderText="Write Somthing(Optional)..."
                // className={state.focus ? "textarea" : "onFocused" }
                // className="textarea"
                // onFocus={() => setState({ focus: true })}
              />
            </div>
            <div className="reviews_bottomsheet_button">
              <PrimaryButton
                text="Add Review"
                className="add_reviews_button"
                onClick={props.onPress}
              />
            </div>
          </div>
        </BottomSheetComponent>
      ) : (
        <div className="reviews_successful">
          <div className="reviews_wrapper">
            <div className="reviews_image">
              <img src={Assets.reviews} className="delivery_man_image" />
            </div>
            <div className="reviews_title">Review Added</div>
            <div className="reviews_message">
              Your review for order {props.id} has been updated successfully!
            </div>
          </div>
          <div className="reviews_footer">
            <PrimaryButton onClick={()=>navigate('/home')} text="Home" />
          </div>
        </div>
      )}
    </div>
  </div>

  );
};

export default Reviews;
