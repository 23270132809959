import {END_DATE, START_DATE } from '../../utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  startDate:null,
  endDate: null,
};

const dateRedcuer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    default:
      return state;
  }
};

export default dateRedcuer;
