import React from 'react';
import { useSetState } from 'utils/functions.utils';
import './outline_input.ui.scss';

interface IInputProps {
  className?: string;
  onChange: any;
  icon?: any;
  icon_class?: string;
  type: string;
  onPress?: any;
  value: string;
  name?: string;
  iconOnPress?: any;
  size?: any;
  error?: any;
  min?: any;
  disabled?: boolean;
  label: string;
}
function OutlineInput(props: IInputProps) {
  const [state, setState] = useSetState({
    focus: false,
    name: '',
  });

  return (
    <div>
      {props.error &&
        props.error.map(
          (error: any) =>
            props.name === error?.path && (
              <div className="error_message">
                {props.name === error?.path && error.message}
              </div>
            ),
        )}
      <div className="container">
        <div className="material-textfield">
          <div
            className={
              props.error.path === props.name
                ? `error outline_input_flex_wrapper_error`
                : state.focus
                ? `outline_input_flex_wrapper focus_input`
                : 'outline_input_flex_wrapper'
            }>
            <input
              style={props.icon ? { width: '93%' } : { width: '100%' }}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
              placeholder={props.label}
              type={props.type}
              onFocus={() => setState({ focus: true })}
              onBlur={() => {
                setState({ focus: false });
              }}
            />
            <label>{props.label}</label>
            <div
              className="icon_wrapper"
              style={props.icon && { width: '10%' }}
              onClick={() => {
                props.iconOnPress();
              }}>
              {props.error.length > 0 ? (
                <>
                  {props.error &&
                    props.error.map(
                      (error: any) =>
                        props.name === error?.path && (
                          <img
                            src={props.icon}
                            alt="image"
                            className={`input_icon ${props.icon_class || ''}`}
                          />
                        ),
                    )}
                  {/* {props.icon && (
                    <img
                      src={props.icon}
                      alt="image"
                      className={`input_icon ${props.icon_class || ''}`}
                    />
                  )} */}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OutlineInput;
