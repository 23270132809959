import { useEffect, useRef } from 'react';
import { Assets, Functions, PrimaryButton } from 'utils/imports.utils';
import './list_component.scss';
import ModelAdmin, { AdminModel } from 'admin_panel/imports/models.admin_import';
import { capitalizeFirstLetter, useSetState } from 'utils/functions.utils';
import moment from 'moment';
import AdminInviteModal from '../admin_invite_modal/admin_invite_modal';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

interface IListComponentProps {
  titleArray?: any;
  data?: any;
  type?: string;
  route?: any;
  refresh?: any;
  onEdit?: any;
  showAction?: boolean
  onDelete?:any
}

const ListComponent = (props: IListComponentProps) => {
  const [state, setState] = useSetState({
    data: '',
    couponDetails:'',
    couponId:'',
    pushNotificationDetails: ''
  });
  // ref
  const ModalRef: any = useRef();
  const editModalRef:any = useRef()

  let value: any;

  // api
  const editPayout = async (value?: any) => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        status: 'TRANSFERED',
        payout_id: state.data._id,
      };
      const res: any = await ModelAdmin.payout.editPayout(body);
      ModalRef.current.closeModal();
      props.refresh(true);
      // getManyPayout(true);
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getPushNotification = async (notification_id: string) => {
    try {
      const body = {
        notification_id
      }
      const res: any = await AdminModel.admin_notification.getPushNotification(body)
      setState({ pushNotificationDetails: res.data })
      ModalRef?.current.openModal()
    }
    catch (err: any) {
      Functions.notiflixFailure(err)
    }
  }

  const getCouponDetails = async (id: string) => {
    try {
      const res: any = await AdminModel.coupon.getCoupon({ coupon_id: id })
      setState({ couponDetails: res.data ,couponId:id})
    }
    catch (err: any) {
      Functions.notiflixFailure(err)
    }
  }

  const timeFormatted = (time) => {
    const parsedTime = moment(time, "HH:mm");
    const formattedTime = parsedTime.format("h:mm A");
    return formattedTime
  }

  const deleteCoupon = async (coupon_id:string)=> {
    try{
      await AdminModel.coupon.deleteCoupon({coupon_id})
      props.refresh(true)
    }
    catch(err:any){
      Functions.notiflixFailure(err)
    }
  }

  const editCoupon = async (data: any) => {
    try {
      if (_.isEmpty(data.code)) {
        Functions.notiflixFailure('Enter coupon code');
      } else if (data.max <= 0) {
        Functions.notiflixFailure('Enter coupon maximum limit');
      } else if (data.offer_value <= 0) {
        Functions.notiflixFailure('Enter discount percentage');
      } else if (data.order_limit <= 0) {
        Functions.notiflixFailure('Enter total order limit');
      } else if (data.saree_quantity <= 0) {
        Functions.notiflixFailure('Enter eligible saree count');
      } else if (data.saree_limit <= 0) {
        Functions.notiflixFailure('Enter maximum number of sarees');
      } else if (_.isEmpty(data.text)) {
        Functions.notiflixFailure('Enter coupon description');
      } else if (_.isEmpty(data.title)) {
        Functions.notiflixFailure('Enter coupon title');
      } else if (data.repeat && data.user_max <= 0) {
        Functions.notiflixFailure('Enter usage limit per user');
      } else if (data.valid_from === null) {
        Functions.notiflixFailure('Select coupon valid from date');
      } else if (data.valid_to === null) {
        Functions.notiflixFailure('Select coupon valid to date');
      } else if (parseInt(data.order_limit) < parseInt(data.saree_limit)) {
        Functions.notiflixFailure('Maximum number of sarees over than total order limit');
      } else if (parseInt(data.saree_limit) < parseInt(data.saree_quantity)) {
        Functions.notiflixFailure('Eligible saree count over than maximum number of sarees');
      } else {
        data['valid_from'] = moment(data.valid_from).startOf('day').toISOString();
        data['valid_to'] = moment(data.valid_to).endOf('day').toISOString();
        data['coupon_id'] = state.couponId
        if(!data.repeat) delete data['user_max']
        await AdminModel.coupon.editCoupon(data);
        editModalRef?.current.closeModal();
        props.refresh(true)
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    }
  }


  const searchUser = async (user: string) => {
    let data: any = [];
    try {
      const res: any = await AdminModel.users.getManyUser({ search: user });
      if (res.data.docs.length > 0) {
        res.data.docs.forEach((item: any) => {
          data.push({
            label: `${item.phone.slice(3).trim()} ${item.name ? '-' : ''} ${
              item.name || ''
            }`,
            value: item._id,
          });
        });
        return data;
      } else return [];
      // else setState({userData:[]})
    } catch (err: any) {
      Functions.notiflixFailure(err);
    }
  };

  const promiseOptions = (inputValue: string) => 
  new Promise((resolve) => {
    resolve(searchUser(inputValue));
  });
  
  // delete user

  // const deleteUser = async (value?: any) => {
  //   Functions.notiflixLoader();
  //   try {
  //     const body: any = {
  //       _id: value._id,
  //     };
  //     const res: any = await ModelAdmin.users.deleteUser(body);
  //     ModalRef.current.closeModal();
  //     props.refresh(true);
  //     // getAllUser(true);
  //   } catch (err: any) {
  //     Functions.notiflixFailure(err);
  //   } finally {
  //     Functions.notiflixRemove();
  //   }
  // };

  const navigate = useNavigate();
  return (
    <div className="list_component_container">
      <div className="list_component_header">
        {props.titleArray.map((item: any, index: number) => {
          return (
            <div
              style={{ width: item.width, ...item?.style }}
              className={`${
                index === 0 && 'list_component_block'
              }  list_component_name_wrapper`}
              key={index}>
              <div className="list_component_header_name ">{item.label}</div>
            </div>
          );
        })}
        <div
          className="list_component_name_wrapper"
          style={
            props.type === 'payout_table'
              ? { width: '12%' }
              : props.type === 'users_table'
              ? { width: '24%' }
              : props.type === 'admin_notification_table'
              ? { width: '15%' } 
              : { width: '16%' }
          }>
          <div className="list_component_header_name ">Action</div>
        </div>
      </div>
      {props.data.length === 0 ? (
        <div className="no_data_found">No Data Found</div>
      ) : props.type === 'payout_table' ? (
        <div className="list_component_card">
          {props.data.map((item: any, index: number) => {
            return (
              <div className="list_component_row" key={index}>
                {props.titleArray.map((titleobj: any, titleIndex: number) => {
                  value = item[props?.titleArray[titleIndex]?.value];
                  return (
                    <div
                      style={{
                        width: props?.titleArray[titleIndex]?.width,
                      }}
                      className={`${
                        titleIndex === 0 && 'list_component_block'
                      }  list_component_name_wrapper`}
                      key={titleIndex}>
                      <div
                        className={
                          'list_component_name' +
                          ' ' +
                          `${
                            value === 'TRANSFERED'
                              ? 'list_component_paid_active'
                              : value === 'PROCESSING'
                              ? 'list_component_onmyway_active'
                              : ''
                          }`
                        }>
                        {titleobj.value === 's.no'
                          ? index + 1
                          : value === 'PROCESSING'
                          ? 'Processing'
                          : value === 'TRANSFERED'
                          ? 'Paid'
                          : titleobj.value === 'created_at'
                          ? moment(value).format('DD-MM-YYYY')
                          : value}
                      </div>
                    </div>
                  );
                })}

                <div className="action_button">
                  {value === 'PROCESSING' ? (
                    <PrimaryButton
                      onClick={() => {
                        setState({ data: item }), ModalRef.current.openModal();
                      }}
                      text={'Pay'}
                      backgroundColor={'#A1342D'}
                      width={'50%'}
                      padding={' 8px 18px '}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <PrimaryButton
                      text={'Paid'}
                      backgroundColor={'#D09A96'}
                      width={'50%'}
                      padding={' 8px 18px '}
                      style={{ cursor: 'default' }}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <div style={{ width: '80%' }}>
            <AdminInviteModal
              modalData={state.data}
              onClick={() => editPayout()}
              type={'payout_model'}
              ref={ModalRef}
            />
          </div>
        </div>
      ) : props.type === 'users_table' ? (
        <div className="list_component_card">
          {props.data.map((item: any, index: number) => {
            // var location_address = item?.location
            //   ?.split(',')
            //   ?.map(String)
            //   ?.reverse();
            // let location: any = location_address?.map(
            //   (item: any, index: any) => {
            //     return index === 3 && item;
            //   },
            // );

            return (
              <div className="list_component_row" key={index}>
                {props.titleArray.map((titleobj: any, titleIndex: number) => {
                  value = item[props?.titleArray[titleIndex]?.value];
                   value = value || '---'

                  return (
                    <div
                      style={{
                        width: props?.titleArray[titleIndex]?.width,
                      }}
                      className={`${
                        titleIndex === 0 && 'list_component_block'
                      }  list_component_name_wrapper`}
                      key={titleIndex}>
                      <div className={'list_component_name'}>
                        {titleobj.value === 's.no'
                          ? index + 1
                          : 
                           value}
                      </div>
                    </div>
                  );
                })}

                <div className="action_icon_button" style={{ width: '24%' }}>
                  <img
                    src={Assets.vision_eye}
                    onClick={() => navigate(`/user_details?id=${item._id}`)}
                    alt="no_icon"
                  />
                  <img
                    src={Assets.notification_user}
                    alt="no_icon"
                    onClick={() =>
                      navigate(`/notification_admin?id=${item._id}`)
                    }
                  />
                  <img
                    src={Assets.booking_list}
                    onClick={() => navigate(`/bookings?id=${item._id}`)}
                    alt="no_icon"
                  />
                </div>
              </div>
            );
          })}

          <div style={{ width: '80%' }}>
            <AdminInviteModal
              // onClick={() => deleteUser(state.data)}
              type={'user_model'}
              ref={ModalRef}
            />
          </div>
        </div>
      ) : props.type === 'booking_table' ? (
        <div className="list_component_card">
          {props.data.map((item: any, index: number) => {
            return (
              <div className="list_component_row" key={index}>
                {props.titleArray.map((titleobj: any, titleIndex: number) => {
                  value = titleobj.isNested ? item[titleobj?.key]?.[titleobj?.value]: item[props?.titleArray[titleIndex]?.value];
                  value = value || '---'
                  return (
                    <div
                      style={{
                        width: props?.titleArray[titleIndex]?.width,
                      }}
                      className={`${
                        titleIndex === 0 && 'list_component_block'
                      }  list_component_name_wrapper`}
                      key={titleIndex}>
                      <div
                        className={
                          'list_component_name' +
                          ' ' +
                          `${
                            value === 'DELIVERED'
                              ? 'list_component_accepted_active '
                              : value === 'ORDER PLACED' ||
                                value === 'WAITING FOR PICKUP' ||
                                value === 'PICKED UP' ||
                                value === 'REACHED' ||
                                value === 'WAITING FOR DISPATCH' ||
                                value === 'DISPATCH' ||
                                value === 'PICKUP SCHEDULED' ||
                                value === 'DROP SCHEDULED' 
                              ? 'list_component_onmyway_active'
                              : value === 'REFUNDED' ||
                                value === 'REFUND INITIATED' ||
                                value === 'ORDER CANCELLED' ||
                                value === 'CANCELLED'
                              ? 'list_component_unpaid_active'
                              : value === 'queued'
                              ? 'list_component_queued_active'
                              : value === 'QUEUED'
                              ? 'list_component_queued_active'
                              : ''
                          }`
                        }>
                        {titleobj.value === 's.no'
                          ? index + 1
                          : titleobj.value === 'status' ||
                            titleobj.value === 'payment_type'
                          ? capitalizeFirstLetter(value.toLowerCase())
                          : titleobj.value === 'created_at'
                          ? moment(value).format('DD-MM-YYYY')
                          : value}
                      </div>
                    </div>
                  );
                })}

                <div className="action_icon_button">
                <img
                    style={item?.pleater?._id ?{cursor:"pointer"}:{cursor:"default"}}
                    src={Assets.live_location_view}
                    alt="no_icon"
                    onClick={() => {item.pleater._id ?navigate(`/live_location?id=${item._id}&key=${true}`):""}}
                  />
                  <img
                    src={Assets.vision_eye}
                    alt="no_icon"
                    onClick={() => navigate(`/booking_details?id=${item._id}`)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : props.type === 'admin_notification_table' ? (
        <div className="list_component_card">
        {props.data.map((item: any, index: number) => {
          return (
            <div className="list_component_row" key={index}>
              {props.titleArray.map((titleobj: any, titleIndex: number) => {
                value = item[props?.titleArray[titleIndex]?.value];
                 value = titleobj.value === 'created_at' ? moment(value).format('D MMM YYYY') : 
                 titleobj.value === 'time' ? timeFormatted(value) :  value || '---'
                return (
                  <div
                    style={{
                      width: props?.titleArray[titleIndex]?.width,
                    }}
                    className={`${
                      titleIndex === 0 && 'list_component_block'
                    }  list_component_name_wrapper`}
                    key={titleIndex}>
                    <div className={'list_component_name'}>
                      {titleobj.value === 's.no'
                        ? index + 1
                        : 
                         value}
                    </div>
                  </div>
                );
              })}
       
              <div className="action_icon_button" style={{ width: '15%' }}>
              {props.showAction ? (
                <>
                  <img
                  src={Assets.vision_eye}
                  alt="no_icon"
                  onClick={() => getPushNotification(item._id)}
                />
                <img
                  src={Assets.trash_icon}
                  alt="no_icon"
                  onClick={() =>props.onDelete(item._id)}
                />
              </>
              ):(
                <>
                 {'---'}
                </>
              )}
              </div>
            </div>
          );
        })}
        <div style={{ width: '80%' }}>
          <AdminInviteModal
            notificationDetails = {state.pushNotificationDetails}
            type={'admin_notification_details'}
            ref={ModalRef}
          />
        </div>
      </div>
      ): props.type === 'coupon_table' ? (
          <div className="list_component_card">
          {props.data.map((item: any, index: number) => {
            return (
              <div className="list_component_row" key={index}>
                {props.titleArray.map((titleobj: any, titleIndex: number) => {
                  value = item[props?.titleArray[titleIndex]?.value];
                  value = titleobj.value === 'valid_from' ?
                    moment(item.valid_from).format('DD/MM/YYYY') :
                    titleobj.value === 'valid_to' ?
                      moment(item.valid_to).format('DD/MM/YYYY') :
                    value || '---'
                  return (
                    <div
                      style={{
                        width: props?.titleArray[titleIndex]?.width,
                      }}
                      className={`${
                        titleIndex === 0 && 'list_component_block'
                      }  list_component_name_wrapper`}
                      key={titleIndex}>
                      <div className={'list_component_name'}>
                        {titleobj.value === 's.no'
                          ? index + 1
                          : 
                           value}
                      </div>
                    </div>
                  );
                })}

                <div className="action_icon_button" style={{ width: '16%' }}>
                  <img
                    src={Assets.vision_eye}
                    onClick={() => {
                      getCouponDetails(item._id),
                        ModalRef?.current.openModal()
                    }
                    }
                    alt="no_icon"
                  />
                  <img
                    src={Assets.admin_edit_icon}
                    alt="no_icon"
                    onClick={() => {
                      getCouponDetails(item._id),
                        editModalRef?.current.openModal()
                    }
                    }
                  />
                  <img
                    src={Assets.trash_icon}
                    onClick={() => deleteCoupon(item._id)}
                    alt="no_icon"
                  />
                </div>
              </div>
            );
          })}

          <div style={{ width: '80%' }}>
            <AdminInviteModal
              type={'coupon_details'}
              couponDetails={state.couponDetails}
              ref={ModalRef}
            />
            <AdminInviteModal
              editCoupon={true}
              loadOptions={promiseOptions}
              type={'coupon_modal'}
              couponDetails={state.couponDetails}
              ref={editModalRef}
              createCoupon = {(data:any) => editCoupon(data)}
            />
          </div>
        </div>
        ):(null)}
    </div>
  );
};

export default ListComponent;
