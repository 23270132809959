import { ACTIVE_TAB, TAB_ACTIVE_DATA } from '../../utils/types.utils';
import { storeAction } from 'interfaces/common.interface';

const initialState = {
  tab_data: [],
  active_tab: 'Current',
};

const orderRedcuer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case TAB_ACTIVE_DATA:
      return {
        ...state,
        tab_data: action.payload,
      };
    case ACTIVE_TAB:
      return {
        ...state,
        active_tab: action.payload,
      };
    default:
      return state;
  }
};

export default orderRedcuer;
