import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import ListComponent from '../../admin_common_components/ui/list_component/list_component';
import { Dropdown, Functions, Searchbar } from 'utils/imports.utils';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import './users.admin_screen.scss';
import AdminModel from 'admin_panel/imports/models.admin_import';
import { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';

const Users = (props: any) => {
  // state
  const [state, setState] = useSetState({
    users_data: [],
    search_input: '',
    search_data: [],
  });
  // get-All-Users
  let data: any = [];
  const getAllUsers: any = async (disable: boolean) => {
    if (disable) {
      Functions.notiflixLoader();
    }
    try {
      const body = {
        skip:0,
        limit:50,
      }
      if(state.search_input.length>0) body['search'] = state.search_input
      const res: any = await AdminModel.users.getManyUser(body);
      setState({ users_data: res.data.docs});
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  useEffect(() => {
    getAllUsers(true);
  }, []);

  useEffect(() => {
    getAllUsers()
  }, [state.search_input]);

  const titledata = [
    {
      label: '#',
      value: 's.no',
      width: '8%',
    },
    {
      label: 'Name',
      value: 'name',
      width: '33%',
    },
    {
      label: 'Moblie No.',
      value: 'phone',
      width: '35%',
    },

    // {
    //   label: 'Orders',
    //   value: 'order_count',
    //   width: '25%',
    // },
    // {
    //   label: 'Location',
    //   value: 'location',
    //   width: '20%',
    // },
  ];

  return (
    <div className="users_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="users_wrapper">
        <AdminHeader text={'Users'} />
        <div className="user_table_container">
          <div className="table_header">
            <div className="search_wrapper">
              <Searchbar
                value={state.search_input}
                onChange={(value: any) => setState({ search_input: value })}
                className={'search_panel'}
                placeholder={'Search'}
                style={{ borderRadius: '16px' }}
              />
            </div>
          </div>
          <ListComponent
            refresh={(value: boolean) => {
              if (value) {
                getAllUsers(false);
              }
            }}
            titleArray={titledata}
            type={'users_table'}
            data={state.users_data}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
