import Assets from 'imports/assets.import';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import { useSetState } from 'utils/functions.utils';
import './datepicker.component.scss';
import _ from 'lodash'
import { Functions } from 'utils/imports.utils';

interface IDatePicker {
  label?: string;
  value?: string;
  onChange?: any;
  placeholder?: string;
  name?: string;
  iconOnPress?: any;
  onFocusPress?:any;
  validateDate?:boolean;
  startDate?:any

}
function SingleDatePicker(props: IDatePicker) {
  const handleChange = (dates: any) => {
    const time = moment(dates.format(), 'DD/MM/YYYY').toISOString()
    if (_.gte(time, props.startDate)) {
      props.onChange(time)
    }
    else {
      props.onChange(null)
      Functions.notiflixFailure("The Valid To date cannot be earlier than the Valid From date")
    }
  }
  return (
    <div className="date_picker_wrapper">
      <DatePicker
      className="custom-calendar"
        value={props.value}
        format={'DD/MM/YYYY'}
        containerStyle={{ width: '100%' }}
        multiple={false}
        range={false}
        onChange={(dates: any) => {
          props.validateDate ? handleChange(dates) : props.onChange(moment(dates.format(), 'DD/MM/YYYY').toISOString());
        }}
        render={
          <InputDatePicker
            placeholder={props.placeholder}
            icon={ props.value === null?Assets.calendar_icon:Assets.calendar_close}
            name={props.name}
            label={props.label}
            value={props.value}
            iconOnPress={
              props.value !== null?props.iconOnPress:""
            }
            onFocusPress={props.onFocusPress}
          />
        }
      />
    </div>
  );
}

interface IInputDatePicker {
  className?: string;
  icon?: any;
  icon_class?: string;
  type?: string;
  onPress?: any;
  value?: string;
  name?: string;
  iconOnPress?: any;
  size?: any;
  error?: any;
  min?: any;
  disabled?: boolean;
  label?: string;
  endText?: string;
  placeholder?: any;
  hide?: any;
  openCalendar?: any;
  handleValueChange?: any;
  onFocusPress?:any;
}
function InputDatePicker(props: IInputDatePicker) {
  // State
  const [state, setState] = useSetState({ focus: false });

  return (
    <div className='calendar_container'>
      <div className="login_input_field_label">{props.label}</div>
      <div
        className={
          state.focus ? 'focus input_container set_bg' : 'input_container'
        }>
        <input
          type={props.type}
          className={
            state.focus
              ? `set_bg input ${props.className || ''}`
              : `input ${props.className || ''}`
          }
          style={props.icon ? { width: '93%' } : { width: '100%' }}
          onChange={props.handleValueChange}
          value={props.value}
          name={props.name}
          onFocus={() => {
            props.openCalendar(), setState({ focus: true }) ,
            props.value ?"":props.onFocusPress()
          }}
          onBlur={() => {
            setState({ focus: false });
          }}
          min={props.min}
          disabled={props?.disabled}
          placeholder={props.placeholder}
        />
        {props.hide !== '' && (
          <div
            className="icon_wrappers"
            style={props.icon && { width: '7%' }}
            onClick={() => {
              props.iconOnPress()
            }}>
            {props.icon && (
              <img
             style={props.value?{width:"18px",height:"18px"}:{width:"24px",height:"24px"}}
                src={props.icon}
                alt="image"
                className={props.value?"close_icon":"open_icon"}
              />
            )}
            {props.endText && (
              <div className="end_text_wrapper">{props.endText}</div>
            )}
          </div>
        )}
      </div>
      {props.error &&
        props.error.map(
          (error: any) =>
            props.name === error?.path && (
              <div className="input_field_error">
                {props.name === error?.path && error.message}
              </div>
            ),
        )}
    </div>
  );
}
export default SingleDatePicker;
