import React, { useEffect, useRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetState, geocode, isIos } from 'utils/functions.utils';
import { Functions, Models } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import GoogleMapPicker from 'react-google-map-picker';
import './map.component.scss';
import Notiflix from 'notiflix';
import { GOOGLE_MAPS_API_KEY } from 'utils/constant.utils';

interface IMapPicker {
  text?: String;
  address?: any;
  latlong?: any;
  editLatlong?: boolean;
  latlongData?: any;
  currentLocation?: any;
}

const MapPicker = (props: IMapPicker) => {
  // variables
  let latitude: any, longitude: any;

  //  useEffect
  useEffect(() => {
    editLocation();
  }, [props.editLatlong,props.latlongData]);

  useEffect(() => {
    getGeoLocation();
  }, [props.currentLocation]);



  // Network req
  const getGeoLocation = async () => {
    Functions.notiflixLoader();
    try {
      setTimeout(() => setLoading(true), 500);
      // getAddressFromLatLong(defaultLocation.lat, defaultLocation.lng);
      {/*// @ts-ignore */ }
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ message: 'location' }),
      );
      handlePermission();
      var options = { timeout: 60000 };
      window.navigator.geolocation.getCurrentPosition(
        showLocation,
        errorHandler,
        options,
      );
    } catch (err) {
      console.log('Sorry, browser does not support geolocation!');
    } finally {
      Functions.notiflixRemove();
    }
  };

  const handlePermission = async () => {
    try {
      let result = await navigator.permissions.query({ name: 'geolocation' });

      if (result.state === 'granted') {
        return true;
      } else if (result.state === 'prompt') {
        return true;
      } else if (result.state === 'denied') {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editLocation = () => {
    if (props.editLatlong === true) {
      console.log("showLocation", props.latlongData)
      getAddressFromLatLong(props.latlongData.lat, props.latlongData.long);
      const obj: any = {
        lat: props.latlongData.lat,
        long: props.latlongData.long,
      };
      props.latlong(obj);
      setDefaultLocation(obj);
      handleResetLocation();
    }
  }

  const showLocation = async (position: any) => {
    if(props.editLatlong===false){
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      getAddressFromLatLong(latitude, longitude);
      let obj: any = {
        lat: latitude,
        long: longitude,
      };
      props.latlong(obj);
      setDefaultLocation(obj);
      handleResetLocation();
    }
  };

  const errorHandler = (err: any) => {
    if (err.code == 1) {
      Functions.notiflixFailure('Location not available')
      console.log('Error: Access is denied!');
    } else if (err.code == 2) {
      console.log('Error: Position is unavailable!');
    }
  };

  const getAddressFromLatLong = async (lat, lng) => {
    const response: any = await geocode(lat, lng);
    setDefaultLocation({ lat, lng });
    if(props.address){
    props.address(response);
    }
  };

  //Logic
  let DefaultLocation: any;
  if (props.editLatlong) {
    DefaultLocation = {
      lat: props.latlongData.lat,
      lng: props.latlongData.long,
    };
  } else {
    DefaultLocation = { lat: 13.074347, lng: 80.198201 };
  }

  const DefaultZoom = 13;
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [loading, setLoading] = useState(false);

  const handleChangeLocation = async (lat, long) => {
    setLocation({ lat: lat, lng: long });
    let obj = {
      lat: lat,
      long: long,
    };
    props.latlong(obj);
    await onChangeLocation(lat, long);
  };

  const onChangeLocation = async (lat, lng) => {
    const response: any = await geocode(lat, lng);
    props.address(response);
  };

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }



  return (
    <>
      {loading && (
        <GoogleMapPicker
          defaultLocation={defaultLocation}
          zoom={zoom}
          style={{ height: '100%' }}
          onChangeLocation={handleChangeLocation}
          onChangeZoom={handleChangeZoom}
          apiKey={GOOGLE_MAPS_API_KEY}
        />
      )}
    </>
  );
};

export default MapPicker;
