import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Header, Input } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './select_coupon.screen.scss';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';

interface ISelectCoupon {
  text?: String;
  onClick?: any;
  couponVerify?: any;
  couponData?: any[];
  onChange?: any;
  value?: any;
  error?: string;
  applyCode?: any;
  sareeDataLength?: any;
}

const SelectCoupon = forwardRef((props: ISelectCoupon,ref) => {
  // Redux
  const booking = useSelector((state: any) => state.booking);
  const userData =useSelector((state: any) => state.user);

  // ref
  const modalRef:any = useRef()

  // route
  const navigate = useNavigate();

  // State
  const [state, setState] = useSetState({
    user_Id: '',
    isModalOpen:false,
  });

  //Hooks
  useEffect(() => {}, []);

  // modal control

  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
   
    },
    closeModal() {
      setState({ isModalOpen: false });
     
    },
  }));


  const quantityCheck = (coupon: any) => {
    if (!coupon.saree_quantity) {
      return true;
    }
    if (coupon.saree_quantity) {
      if (coupon.saree_quantity <= props.sareeDataLength.length) {
        return true;
      } else return false;
    }
  };

  const sareeLimitCheck = (coupon: any) => {
    if (!coupon.saree_limit) {
      return true;
    }
    if (coupon.saree_limit) {
      if (coupon.saree_limit >= props.sareeDataLength.length) {
        return true;
      } else return false;
    }
  };

  const status = (coupon: any) => {
    const quantity = quantityCheck(coupon);
    const sareeLimit = sareeLimitCheck(coupon);
    if (quantity && sareeLimit) {
      return true;
    } else return false;
  };

 

  // useEffect
  
  //Logic
  const testLogic = () => {};

  return (
    <div>
      <Modal
        open={state.isModalOpen}
        ref={modalRef}
        onClose={() => {
          setState({ isModalOpen: false });
        }}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}>
        <div className="select_coupon_container">
          <div style={{ height: '100px' }}>
            <div className="coupon_header">
              <Header onClick={props.onClick} title="Coupons" />
            </div>
            <div className="coupon_input_container">
              <div className="coupon_input_wrapper">
                <input 
                 value={props.value}
                 onChange={(e:any)=> props.onChange(e.target.value) }
                 placeholder='Enter Coupon Code' />
                <div onClick={()=> props.couponVerify(props.value)} className='coupon_input_button_text'>
                 Apply
                </div>
                {/* <Input
                  hide={props.value}
                  iconOnPress={() => props.couponVerify(props.value)}
                  type="text"
                  onChange={(value: any) => {
                    props.onChange(value);
                  }}
                  value={props.value}
                  name="coupon"
                  endText="Apply"
                  placeholder="Enter Coupon Code"
                /> */}
              </div>
              {props.error && (
                <div className="coupon_invalid_text">
                  *The code you entered is invalid
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: `${props.error ? '68px' : '58px'}` }} className="coupon_available_container">
            <div className="coupon_available_heading">Available Coupons</div>
            {props.couponData?.map((coupon: any, index: number) => (
              <div className="coupon_available_wrapper" key={index}>
                <div className="coupon_type">{coupon.title}</div>
                <div className="coupon_value">{coupon.text}</div>
                <div className="coupon_footer">
                  <div className="coupon_code">
                    {status(coupon) ? coupon.code : '******'}
                  </div>
                  {status(coupon) ? (
                    <div
                      onClick={() => props.couponVerify(coupon.code)}
                      className="coupon_apply_button">
                      Apply
                    </div>
                  ) : (
                    <div className="coupon_screen_button_disable">Apply</div>
                  )}
                </div>
                {status(coupon) ? null : (
                  <>
                    <div className="coupon_unlock_content">
                      {coupon.saree_limit ? (
                        <>
                          {coupon.saree_limit < props.sareeDataLength.length ? (
                            <>
                              Maximum {coupon.saree_limit} sarees allowed this
                              coupon
                            </>
                          ) : (
                            <>
                              *Add{' '}
                              {coupon.saree_quantity -
                                props.sareeDataLength.length}{' '}
                              more saree to unlock this coupon
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          *Add{' '}
                          {coupon.saree_quantity - props.sareeDataLength.length}{' '}
                          more saree to unlock this coupon
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default SelectCoupon;
