import * as yup from 'yup';
const phoneRegExp = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
);
export const otpLoginSchema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp, 'Use valid mobile number'),
  referral_code: yup.string().when("referral_code", (val, schema) => {
    if(val?.length === 0) {  
       return yup.string().required('Enter referral code');
    } else { 
       return yup.string().notRequired();
    }
}),
},[['referral_code','referral_code']]);


export const otpVerifySchema = yup.object().shape({
  otp: yup.string().min(4),
});

export const createAddress = yup.object().shape({
  name: yup.string().required('Enter your name'),
  house_number: yup.string().required('Enter house / floor number'),
  apartment: yup.string().required('Enter street / apartment name'),
  phone_number: yup.string().matches(phoneRegExp, 'Enter valid phone number'),
  pincode:yup.number().required('Enter pincode number'),
  email:yup.string().required('Enter your email'),
});
