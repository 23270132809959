import instance from 'utils/axios.utils';

export const addPayment = (data) => {
    const response = new Promise((resolve, reject) => {
        instance().post('/payment/add_payment', data)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            })
    })
    return response
}

export const fetchPayment = () => {
    const response = new Promise((resolve, reject) => {
        instance().post('/payment/fetch_payment')
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            })
    })
    return response
}

export const createOreder = (body) => {
    const response = new Promise((resolve, reject) => {
        instance().post('/razorpay/create_order', body)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            })
    })
    return response
}

export const createPayment = (body) => {
    const response = new Promise((resolve, reject) => {
        instance().post('/razorpay/create_payment', body)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            })
    })
    return response
}

export const paymentStatus = (body) => {
    const response = new Promise((resolve, reject) => {
        instance().post('/razorpay/payment_status', body)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                if (err.response) {
                    reject(err.response)
                } else {
                    reject(err)
                }
            })
    })
    return response
}