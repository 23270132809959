import React, { useEffect, useRef } from 'react';
import './admin_coupon.screen.scss';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import { Functions, Searchbar } from 'utils/imports.utils';
import AdminHeader from 'admin_panel/admin_common_components/ui/admin_header/admin_header';
import { useSetState } from 'utils/functions.utils';
import ListComponent from '../../admin_common_components/ui/list_component/list_component';
import AdminModel from 'admin_panel/imports/models.admin_import';
import AdminInviteModal from 'admin_panel/admin_common_components/ui/admin_invite_modal/admin_invite_modal';
import _ from 'lodash';
import moment from 'moment';

const AdminCouponScreen = () => {
  const [state, setState] = useSetState({
    data: [],
    userData: [],
    couponDetails: '',
    search:''
  });
  // ref
  const modalRef: any = useRef();

  //   title data for table header
  const titleData = [
    {
      label: '#',
      value: 's.no',
      width: '5%',
    },
    {
      label: 'Coupon Name',
      value: 'title',
      width: '22%',
    },
    {
      label: 'Coupon Code',
      value: 'code',
      width: '13%',
    },
    {
      label: 'Valid From',
      value: 'valid_from',
      width: '14%',
    },
    {
      label: 'Valid To',
      value: 'valid_to',
      width: '14%',
    },
    {
      label: 'Discount',
      value: 'offer_value',
      width: '15%',
    },
  ];

  //   api
  const getManyCoupon = async () => {
    try {
      Functions.notiflixLoader();
      const body = {}
      if(state.search.length > 0) body['search'] = state.search
      let res: any = await AdminModel.coupon.getManyCoupon(body);
      res = _.orderBy(res.data, ['created_at'], ['desc']);
      setState({ data: res });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const createCoupon = async (data: any) => {
    try {
      if (_.isEmpty(data.code)) {
        Functions.notiflixFailure('Enter coupon code');
      } else if (data.max <= 0) {
        Functions.notiflixFailure('Enter coupon maximum limit');
      } else if (data.offer_value <= 0) {
        Functions.notiflixFailure('Enter discount percentage ');
      } else if (data.order_limit <= 0) {
        Functions.notiflixFailure('Enter total order limit ');
      } else if (data.saree_quantity <= 0) {
        Functions.notiflixFailure('Enter eligible saree count');
      } else if (data.saree_limit <= 0) {
        Functions.notiflixFailure('Enter maximum number of sarees');
      } else if (_.isEmpty(data.text)) {
        Functions.notiflixFailure('Enter coupon description');
      } else if (_.isEmpty(data.title)) {
        Functions.notiflixFailure('Enter coupon title');
      } else if (data.repeat && data.user_max <= 0) {
        Functions.notiflixFailure('Enter usage limit per user');
      } else if (data.valid_from === null) {
        Functions.notiflixFailure('Select coupon valid from date');
      } else if (data.valid_to === null) {
        Functions.notiflixFailure('Select coupon valid to date');
      } else if (parseInt(data.order_limit) < parseInt(data.saree_limit)) {
        Functions.notiflixFailure('Maximum number of sarees over than total order limit');
      } else if (parseInt(data.saree_limit) < parseInt(data.saree_quantity)) {
        Functions.notiflixFailure('Eligible saree count over than maximum number of sarees');
      } else {
        data['valid_from'] = moment(data.valid_from)
          .startOf('day')
          .toISOString();
        data['valid_to'] = moment(data.valid_to).endOf('day').toISOString();
        if(!data.repeat) delete data['user_max']
        await AdminModel.coupon.createCoupon(data);
        modalRef?.current.closeModal();
        getManyCoupon();
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    }
  };

  const searchUser = async (user: string) => {
    let data: any = [];
    try {
      const res: any = await AdminModel.users.getManyUser({ search: user });
      if (res.data.docs.length > 0) {
        res.data.docs.forEach((item: any) => {
          data.push({
            label: `${item.phone.slice(3).trim()} ${item.name ? '-' : ''} ${
              item.name || ''
            }`,
            value: item._id,
          });
        });
        return data;
      } else return [];
      // else setState({userData:[]})
    } catch (err: any) {
      Functions.notiflixFailure(err);
    }
  };

  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      resolve(searchUser(inputValue));
    });

  //   hooks
  useEffect(() => {
    getManyCoupon();
  }, [state.search]);
  return (
    <div className="admin_coupon_screen_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="admin_coupon_wrapper">
        <AdminHeader text="Coupons" />
        <div className="admin_coupon_table_wrapper">
          <div className="admin_coupon_heading_wrapper">
            <div className="admin_coupon_heading_content">Coupons</div>
            <div
              onClick={() => modalRef?.current.openModal()}
              className="admin_coupon_create_button">
              Create
            </div>
          </div>
          <div className="admin_coupon_table_header">
            <div className="admin_coupon_search_wrapper">
              <Searchbar
                value={state.search}
                onChange={(value: any) => setState({ search: value })}
                className={'search_panel'}
                placeholder={'Search'}
                style={{ borderRadius: '16px' }}
              />
            </div>
          </div>
          <div
            style={{ overflowX: 'hidden', overflowY: 'scroll', height: '90%' }}>
            <ListComponent
              refresh={(isTrue: boolean) => isTrue && getManyCoupon()}
              type={'coupon_table'}
              titleArray={titleData}
              data={state.data}
            />
          </div>
        </div>
      </div>
      <AdminInviteModal
        type={'coupon_modal'}
        userData={state.userData}
        loadOptions={promiseOptions}
        userChange={(user: string) => searchUser(user)}
        ref={modalRef}
        createCoupon={(data: any) => createCoupon(data)}
      />
    </div>
  );
};

export default AdminCouponScreen;
