import instance from 'utils/axios.utils';

const address = {
  createAddress: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'address/create_address';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  getAddress: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'address/get_address';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  defaultAddress: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = `address/default_address?address_id=${data._id}`;
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editAddress: (id, body) => {
    let promise = new Promise((resolve, reject) => {
      let url = `address/edit_address?address_id=${id}`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteAddress: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = `address/delete_address?address_id=${data._id}`;
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  uploadFile: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/get_signed_url';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default address;
