import React, { useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { useSetState, useQuery, isMobileApp, otpLogic, otpListRender, estimatedTime } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Header,
  Footer,
  Assets,
  PrimaryButton,
  StarRating,
  InviteModal,
  OrderDetailsCard
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './order_details.screen.scss';
import moment from 'moment';
import { COURIER_DELIVERY_TYPES, sareeRate } from '../../utils/constant.utils';
import { useNavigate } from 'react-router-dom';
import Reviews from '../../screens/reviews/reviews.screen';
import ModalImage from 'react-modal-image';
import _ from 'lodash';


interface IOrderDetails {
  text?: String;
}

const OrderDetails = (props: IOrderDetails) => {
  // Redux
  const userData = useSelector((state: any) => state.user);

  // State
  const [state, setState] = useSetState({
    cancel: 'Currently we are reviewing your order.',
    order_data: '',
    loading: true,
    review_screen_show: true,
    review: '',
    rating: 0,
    review_success: true,
    user_data: '',
    timeout: '',
    notes:'',
    content:'',
    hoursContent:'',
    hours:''

  });

  // route
  const navigate = useNavigate();

  // ref

  const notesRef:any = useRef();

  // query
  const query = useQuery();
  let id: any = query.get('id');

  //Hooks
  useEffect(() => {
    getOrderDetails();
    getUser();
    getReview();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      setState({ timeout: Math.random() });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

// Refresh useEffect
  useEffect(() => {
     GetManyRefresh()
  }, [state.timeout]);

  // getManyRefresh
  const GetManyRefresh = () => {
    getOrderDetails();
  };

  // Network req
  const getOrderDetails = async () => {
    // Functions.notiflixLoader();
    try {
      const body = {
        booking_id: id,
      };
      const res: any = await Models.orders.getOrderById(body);
      setState({ order_data: res.data });
      dispatchHours(res.data)
      setState({notes:res.data?.notes?.notes})

      // start
      // if (res.data.dunzo_task_id) {
      //   const dunzoId: any = {
      //     dunzo_task_id: res.data.dunzo_task_id,
      //   };
      //   const response: any = await Models.orders.getTrackStatus(dunzoId);
      //   setState({ order_data: response.data });
      //   setState({ loading: false });
      // } else {
      //   setState({ order_data: res.data });
      // }
      
      // end
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };


  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      setState({ user_data: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const addReview = async () => {
    Functions.notiflixLoader();
    const body:any = {
      ratings: state.rating / 10,
      pleater: state.order_data.pleater._id,
      booking: state.order_data._id,
    };
    if (state.review.length > 0) {
      body.review = state.review
    }
     if (state.rating === 0) {
      Functions.notiflixFailure('Give your ratings');
    }
    try {
      const res: any = await Models.review.addReview(body);
      setState({ review_success: false });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const createReshedule = async () => {
    Functions.notiflixLoader('Loading...');
    try {
      const body: any = {
        booking_id: state.order_data._id,
        delivery_saress: state.order_data.delivery_sarees,
        re_schedule: true,
      };
      const res: any = await Models.orders.editOrder(body);
      window.location.reload();
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // order cancelled re_schedule
  const reScheduleOrderCancelled = async () => {
    Functions.notiflixLoader('Loading...');
    try {
      const body: any = {
        booking_id: state.order_data._id,
      };
      const res: any = await Models.orders.editOrder(body);
      window.location.reload();
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getReview = async () => {
    // Functions.notiflixLoader();
    const body = {
      booking_id: id,
    };
    try {
      const res: any = await Models.review.getReview(body);
      setState({ review_data: res.data });
    } catch (err: any) {
      // Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };


  const dispatchHours = (data: any) => {
    const time = estimatedTime(data.reached_time)
    if (time) {
      setState({
        content: `The order will be dispatched on ${time.split(' ')[0]} before`,
        hoursContent: `${time.split(' ')[1]} ${time.split(' ')[2]}`,
      });
    }
    else {
      setState({
        content: 'Dispatch time has ended',
        hoursContent:''
      })
    }
  }
  

  
  return (
    <div>
      <div className="details_container">
        {state.review_screen_show ? (
          <div>
            <div className="details_main_wrapper">
              <div className="details_header_container">
                <Header
                  onClick={() => navigate(-1)}
                  title={`ID ${state.order_data.id}`}
                />
              </div>
              {/* express delivery timing */}
              {
                state.order_data.delivery_mode &&
                state.order_data.delivery_mode ===
                COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY &&
                state.user_data?.role === 'pleater' &&
                state.order_data.reached_time && (
                  <div className="express_delivery_timing_content">
                    {state.content} {''}
                    <span>{state.hoursContent} {state.hours}</span>
                  </div>
                )
              }
              {/* order details slider */}
              <div className="order_details_scroll_wrapper">
                {state.order_data &&
                  state.order_data?.pickup_sarees.map(
                    (item: any, index: number) => {
                      return (
                        <>
                          <OrderDetailsCard
                            id={id}
                            saree_num={index}
                            data={state.order_data}
                            saree_data={item}
                          />
                        </>
                      );
                    },
                  )}
              </div>

              <div className="details_wrapper">
                <div className="details_saree_info_container">
                  <div className="details_subheading">Saree info</div>
                  <div className="details_info_container">
                    <div className="details_info_type_value">
                      <div className="details_info_type">Quantity</div>
                      <div className="details_info_value">
                        {state.order_data.quantity}
                      </div>
                    </div>
                    <div className="details_info_type_value">
                      <div className="details_info_type">Time</div>
                      <div className="details_info_value">
                        {moment(state.order_data.created_at).format(
                          'h:mma, MMM D, YYYY',
                        )}
                      </div>
                    </div>

                    { otpListRender(state.order_data) && (
                    <div className="details_info_type_value">
                      <div className="details_info_type">Otp</div>
                      <div className="details_info_value">
                      {otpLogic(state.order_data,state.user_data)}
                      </div>
                    </div>
                    )}

                    {state.user_data.role !== 'pleater' && (
                      <>
                        <div className="details_info_type_value">
                          <div className="details_info_type">Payment</div>
                          <div className="details_info_value">
                            {state.order_data.payment_type === 'COD'
                              ? 'Pay on delivery'
                              : 'Paid online'}
                          </div>
                        </div>
                        <div
                          className="details_info_type_value"
                          style={{ display: 'none' }}>
                          <div className="details_info_type">Phone</div>
                          <div className="details_info_value">
                            {state.order_data?.user?.phone}
                          </div>
                        </div>
                        <div className="details_info_type_value">
                          <div className="details_info_type">Address</div>
                          <div className="details_info_value">
                            {state.order_data &&
                              state.order_data.user_address.map_address}
                          </div>
                        </div>
                      </>
                    )}
                    {/* {state.order_data?.notes?.body_size && (
                      <div className="details_info_type_value">
                        <div className="details_info_type">Body type</div>
                        <div className="details_info_value">
                          {state.order_data?.notes?.body_size}
                        </div>
                      </div>
                    )} */}
                    {/* {state.order_data?.notes?.notes?.length > 0 && (
                      <div className="details_info_type_value">
                        <p className="details_info_type">Notes</p>
                        <p className="details_info_value_notes">
                          {state.order_data?.notes?.notes}
                        </p>
                      </div>
                    )} */}

                    {/* {state.order_data?.notes?.body_height?.length > 0 && (
                      <div className="details_info_type_value">
                        <div className="details_info_type">Body height</div>
                        <div className="details_info_value">
                          {state.order_data?.notes?.body_height}
                        </div>
                      </div>
                    )} */}
                    {/* {state.order_data?.notes?.number_of_pleat && (
                      <div className="details_info_type_value">
                        <div className="details_info_type">
                          Number of pleats
                        </div>
                        <div className="details_info_value">
                          {state.order_data?.notes?.number_of_pleat}
                        </div>
                      </div>
                    )} */}
                    {/*  */}
                  </div>
                </div>
                <div className="details_order_status_container">
                  <div className="details_subheading">Order Status</div>
                  <div className="details_order_status_wrapper">
                    <div className="details_order_status_element">
                      <div className="details_checkbox_container">
                        <img
                          src={Assets.status_active}
                          className="status_active"
                        />
                        <div className="details_dashed_line" />
                      </div>
                      <div className="details_status_text_container">
                        <div
                          className={`${
                            state.cancel === ''
                              ? 'details_order_status_text'
                              : 'padding_none'
                          }`}>
                          Order Placed
                        </div>

                        {state.cancel !== '' && (
                          <div className="details_description_text_container">
                            <div className="details_description_text">
                              {/* {state.cancel} */}
                            </div>
                            <div className="details_description_button">
                              <PrimaryButton
                                text="Cancel Order"
                                fontSize={12}
                                className="edit_order_button"
                                fontWeight={400}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {state.order_data &&
                    state.order_data.status === 'ORDER CANCELLED' ? (
                      <div className="details_order_status_element">
                        <div className="details_checkbox_container">
                          <img
                            src={Assets.order_cancel}
                            className="status_active_cancel"
                          />
                        </div>
                        <div className="details_order_order_cancelled_wrapper">
                          <div className="details_order_status_text_margin-top">
                            Pickup delayed
                          </div>
                          {
                          userData.user.role === 'admin' ? (
                            <div
                              onClick={() => reScheduleOrderCancelled()}
                              className="details_order_cancel_button">
                              Reschedule
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <>
                      {state.order_data && state.order_data.status === "REFUND INITIATED" ? (
                        <>
                         <div className="details_order_status_element">
                          <div className="details_checkbox_container">
                            <img
                              src={Assets.status_active }
                              className="status_active"
                            />
                          </div>
                          <div className="details_order_status_text">
                           Refund initiated
                          </div>
                        </div>
                        </>
                      ):(
                        <>
                        {state.order_data && state.order_data.status === "REFUNDED" ? (
                          <>
                           <div className="details_order_status_element">
                          <div className="details_checkbox_container">
                          <img
                              src={Assets.status_active }
                              className="status_active"
                            />
                          </div>
                          <div className="details_order_status_text">
                           Refunded
                          </div>
                        </div>
                          </>
                        ):(
                          <>
                        <div className="details_order_status_element">
                          <div className="details_checkbox_container">
                            <img
                              src={
                                state.order_data.status ===
                                  'WAITING FOR PICKUP' ||
                                state.order_data.status === 'PICKED UP' ||
                                state.order_data.status === 'PROCESSING' ||
                                state.order_data.status === 'CANCELLED' ||
                                state.order_data.status ===
                                  'WAITING FOR DISPATCH' ||
                                state.order_data.status === 'REACHED' ||
                                state.order_data.status === 'DISPATCH' ||
                                state.order_data.status === 'DELIVERED' ||
                                state.order_data.status === 'PICKUP SCHEDULED' ||
                                state.order_data.status === 'DROP SCHEDULED' 
                                  ? Assets.status_active
                                  : Assets.disable_status
                              }
                              className="status_active"
                            />

                            <div className="details_dashed_line" />
                          </div>
                          <div className="details_order_status_text">
                            Waiting for pick up
                          </div>
                        </div>
                        <div>
                          <div className="details_order_status_element">
                            <div className="details_checkbox_container">
                              <img
                                src={
                                  state.order_data.status === 'PICKED UP' ||
                                  state.order_data.status === 'PROCESSING' ||
                                  state.order_data.status === 'CANCELLED' ||
                                  state.order_data.status ===
                                    'WAITING FOR DISPATCH' ||
                                  state.order_data.status === 'REACHED' ||
                                  state.order_data.status === 'DISPATCH' ||
                                  state.order_data.status === 'DELIVERED' ||
                                  state.order_data.status === 'DROP SCHEDULED'
                                    ? Assets.status_active
                                    : Assets.disable_status
                                }
                                className="status_active"
                              />

                              <div className="details_dashed_line" />
                            </div>
                            <div className="details_order_status_text">
                              Picked up
                            </div>
                          </div>
                          <div className="details_order_status_element">
                            <div className="details_checkbox_container">
                              <img
                                src={
                                  state.order_data.status === 'PROCESSING' ||
                                  state.order_data.status === 'REACHED' ||
                                  state.order_data.status === 'DISPATCH' ||
                                  state.order_data.status === 'CANCELLED' ||
                                  state.order_data.status ===
                                    'WAITING FOR DISPATCH' || 
                                    state.order_data.status === 'DROP SCHEDULED' ||
                                  state.order_data.status === 'DELIVERED'
                                    ? Assets.status_active
                                    : Assets.disable_status
                                }
                                className="status_active"
                              />

                              <div className="details_dashed_line" />
                            </div>
                            <div className="details_order_status_text">
                              Reached us
                            </div>
                          </div>
                          {state.order_data.status === 'CANCELLED' ? (
                            <>
                              <div className="details_order_status_element">
                                <div className="details_checkbox_container">
                                  <img
                                    src={Assets.order_cancel}
                                    className="status_active_cancel"
                                  />

                                  <div className="details_dashed_line" />
                                </div>
                                <div className="details_order_status_text_flex">
                                  <div className="details_order_cancel_text">
                                    Dispatch delayed
                                  </div>
                                  {
                                    (userData.user.role === 'pleater' ||
                                    userData.user.role === 'admin')  && (
                                    <div
                                      onClick={() => createReshedule()}
                                      className="details_order_cancel_button">
                                      Reschedule
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="details_order_status_element">
                                <div className="details_checkbox_container">
                                  <img
                                    src={
                                      state.order_data.status ===
                                        'PROCESSING' ||
                                      state.order_data.status === 'DISPATCH' ||
                                      state.order_data.status === 'CANCELLED' ||
                                      state.order_data.status ===
                                        'WAITING FOR DISPATCH' ||
                                      state.order_data.status === 'DROP SCHEDULED' ||
                                      state.order_data.status === 'DELIVERED'
                                        ? Assets.status_active
                                        : Assets.disable_status
                                    }
                                    className="status_active"
                                  />

                                  <div className="details_dashed_line" />
                                </div>
                                <div className="details_order_status_text">
                                  Waiting for dispatch
                                </div>
                              </div>
                            </>
                          )}

                          <div className="details_order_status_element">
                            <div className="details_checkbox_container">
                              <img
                                src={
                                  state.order_data.status === 'DISPATCH' ||
                                  state.order_data.status === 'DELIVERED'
                                    ? Assets.status_active
                                    : Assets.disable_status
                                }
                                className="status_active"
                              />

                              <div className="details_dashed_line" />
                            </div>
                            <div className="details_order_status_text">
                              Order Dispatched
                            </div>
                          </div>

                          <div className="details_order_status_element">
                            <div className="details_checkbox_container">
                              <img
                                src={
                                  state.order_data.status === 'DELIVERED'
                                    ? Assets.status_active
                                    : Assets.disable_status
                                }
                                className="status_active"
                              />
                            </div>
                            <div className="details_order_status_text">
                              Delivered
                            </div>
                          </div>
                          {/*  */}
                          {!_.isEmpty(
                            state.order_data.delivery_partner_details,
                          ) &&
                          (state.order_data.status === 'WAITING FOR DISPATCH' ||
                            state.order_data.status === 'PICKED UP') &&
                          (userData.user.role === 'pleater' ||
                            userData.user.role === 'admin') ? (
                            <>
                              <div className="order_details_contact_heading">
                                Delivery Partner (Pick up)
                              </div>
                              <div className="order_details_screen_contact_wrapper">
                                <div className="order_details_screen_contact_flex_wrapper">
                                  <div className="order_details_screen_image_name_flex_wrapper">
                                    <div className="order_details_screen_image_box_shadow">
                                      <img
                                        className="order_details_screen_contact_image"
                                        src={
                                          state.order_data
                                            ?.delivery_partner_details
                                            ?.profile_pic
                                            ? state.order_data
                                                ?.delivery_partner_details
                                                .profile_pic
                                            : Assets.profile
                                        }
                                      />
                                    </div>
                                    <div className="order_details_screen_name_heading">
                                      {
                                        state.order_data
                                          ?.delivery_partner_details?.name
                                      }
                                    </div>
                                  </div>
                                  <div className="order_details_screen_call_wrapper">
                                    <a
                                      href={`tel:${state.order_data?.delivery_partner_details?.phone}`}
                                      className="order_details_screen_call_content">
                                      Call
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {!_.isEmpty(
                                state.order_data.delivery_partner_details,
                              ) &&
                              (state.order_data.status ===
                                'WAITING FOR PICKUP' ||
                                state.order_data.status === 'DISPATCH') &&
                              (userData.user.role === 'admin' ||
                                userData.user.role !== 'pleater') ? (
                                <>
                                  <div className="order_details_contact_heading">
                                    Delivery Partner (Pick up)
                                  </div>
                                  <div className="order_details_screen_contact_wrapper">
                                    <div className="order_details_screen_contact_flex_wrapper">
                                      <div className="order_details_screen_image_name_flex_wrapper">
                                        <div className="order_details_screen_image_box_shadow">
                                          <img
                                            className="order_details_screen_contact_image"
                                            src={
                                              state.order_data
                                                .delivery_partner_details
                                                ?.profile_pic
                                                ? state.order_data
                                                    .delivery_partner_details
                                                    ?.profile_pic
                                                : Assets.profile
                                            }
                                          />
                                        </div>
                                        <div className="order_details_screen_name_heading">
                                          {
                                            state.order_data
                                              ?.delivery_partner_details?.name
                                          }
                                        </div>
                                      </div>
                                      <div className="order_details_screen_call_wrapper">
                                        <a
                                          href={`tel:${state.order_data?.delivery_partner_details?.phone}`}
                                          className="order_details_screen_call_content">
                                          Call
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                        </div>
                          </>
                        )}
                        </>
                      )}
                      </>
                    )}
                  </div>
                </div>

                {/* map */}
                {/* {state.order_data.tracking_url &&
                (state.order_data.status === 'PICKED UP' ||
                  state.order_data.status === 'DISPATCH') ? (
                  <div className="order_details_screen_map_tracking_container">
                    <div className="order_details_screen_map_heading">
                      Live location
                    </div>
                    <div className="order_details_screen_map_tracking_wrapper">
                      <div className="order_details_screen_map">
                        <iframe
                          style={{
                            border: 'none',
                            width: '100%',
                            height: '450px',
                          }}
                          src={state.order_data?.tracking_url}
                          loading="lazy"></iframe>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/*map  */}
                {state.order_data.status === 'DELIVERED' && (
                  <div style={{ margin: '5px' }}>
                    {state.order_data?.delivery_sarees?.length > 0 && (
                      <div className="order_details_delivery_saress">
                        Our Works
                      </div>
                    )}
                    {state.order_data.delivery_sarees &&
                      state.order_data.delivery_sarees.map(
                        (item: any, index: number) => {
                          return (
                            <div
                              className="details_saree_container"
                              style={{ marginTop: 3 }}>
                              <div className="details_saree_header">
                                <div className="details_saree_count">
                                  Saree {index + 1}
                                </div>
                                {/* <div className="details_saree_price">
                                <div className="details_saree_price_currency">
                                  Rs.
                                </div>
                                <div className="details_saree_price_value">
                                  {index === 0
                                    ? sareeRate.firstOne
                                    : sareeRate.secondOne}
                                </div>
                              </div> */}
                              </div>
                              <div className="details_saree_gallery">
                                {item.images.map(
                                  (sarees: any, sareeIndex: number) => (
                                    <>
                                      <div className="details_saree_picture">
                                        <ModalImage
                                          key={sareeIndex}
                                          small={sarees}
                                          large={sarees}
                                          alt="Saree image"
                                          className="saree_picture"
                                        />
                                      </div>
                                    </>
                                  ),
                                )}
                              </div>
                            </div>
                          );
                        },
                      )}
                  </div>
                )}
                {state.user_data.role !== 'pleater' &&
                  state.order_data.status === 'DELIVERED' &&
                  _.isEmpty(state.review_data) &&
                  !_.isEmpty(state.order_data?.delivery_sarees) && (
                    <div className="details_reviews_container">
                      <div className="details_subheading">Write Reviews</div>
                      <div className="details_reviews_wrapper">
                        <div className="details_reviews_text">
                          How is our work. Write a review for us.
                        </div>
                        <div className="details_reviews_button">
                          <PrimaryButton
                            onClick={() =>
                              setState({ review_screen_show: false })
                            }
                            text="Add Review"
                            className="add_reviews_button"
                            fontSize={12}
                            fontWeight={400}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              {state.review_data && (
                <div className="review_screen_container">
                  <div className="review_screen_wrapper">
                    <div className="review_screen_heading">
                      {state.review_data.user.name}
                    </div>
                    <div className="review_screen_star">
                      <StarRating
                        size={18}
                        ratingValue={state.review_data.ratings * 10}
                      />
                    </div>
                    <div className="review_screen_content">
                      {state.review_data.review}
                    </div>
                    {/* {state.review_data &&
                  state.review_data.map((item: any) => {
                    return (
                      <div className="review_screen_wrapper">
                      <StarRating size={18} ratingValue={item.ratings} />
                        <div className="review_screen_name_title">shaik</div>
                        <div className="review_screen_star_rating">
                          {' '}
                        </div>
                        <div className="review_screen_content">
                          {item.review}
                        </div>
                      </div>
                    );
                  })} */}
                  </div>
                </div>
              )}
              {/* Help Whatsapp */}
              <div className="order_details_screen_help_whatsapp_container">
                <div className="order_details_screen_wrapper">
                  <div className="order_details_screen_whatsaapp_button">
                    {isMobileApp()?(
                      <>
                    <a href={`https://wa.me/+919043040220?text=Hi, I Need help on my Pleat order ${state.order_data.id}`}>
                      <img
                        width={'27px'}
                        height={'27px'}
                        src={Assets.whatsapp}
                        alt="ask_help_whatsapp"
                      />
                    </a>
                      </>
                    ):(
                      <>
                       <a
                       target={'_blank'}
                      href={`https://web.whatsapp.com/send?text=Hi, I Need help on my Pleat order ${state.order_data.id}&phone=+919043040220`}>
                      <img
                        width={'27px'}
                        height={'27px'}
                        src={Assets.whatsapp}
                        alt="ask_help_whatsapp"
                      />
                    </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="details_footer_container">
              <Footer />
            </div>
          </div>
        ) : (
          <Reviews
            id={state.order_data.id}
            reviewSuccessShow={state.review_success}
            onPress={() => addReview()}
            rating={(rating) => setState({ rating })}
            value={state.review}
            rating_value={state.rating}
            onChange={(review: any) => setState({ review })}
            onClick={() => setState({ review_screen_show: true })}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
