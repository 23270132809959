import store from 'store/store';
import {
  TEST_ACTION,
  GET_USER,
  BOOKING_DATA,
  TOTAL_AMOUNT,
  TOTAL_PRICE,
  COUPON_VERIFY,
  ITEM_PRICE,
  EXPRESS_DELIVERY,
  ACTIVE_TAB,
  TAB_ACTIVE_DATA,
  OFFER_REDUCE_AMOUNT,
  USED_COUPON,
  STORE_BODY_SIZE_DATA,
  STORE_BODY_HEIGHT,
  STORE_NOTES,
  NUM_OF_PLEATES,
  STORE_PREVIOUS_BOOKING_DATA,
  APPLY_FOR_ALL,
  START_DATE,
  END_DATE
} from './types.utils';

export const testDispatch = (payload: object) =>
  store.dispatch({
    type: TEST_ACTION,
    payload: payload,
  });

export const setUser = (payload: object) => {
  store.dispatch({
    type: GET_USER,
    payload: payload,
  });
};

export const storeBookingData = (payload: any) => {
  store.dispatch({
    type: BOOKING_DATA,
    payload: payload,
  });
};
export const storeTotalAmount = (payload: any) => {
  store.dispatch({
    type: TOTAL_AMOUNT,
    payload: payload,
  });
};

export const storeTotalPrice = (payload: any) => {
  store.dispatch({
    type: TOTAL_PRICE,
    payload: payload,
  });
};
export const storeCouponData = (payload: any) => {
  store.dispatch({
    type: COUPON_VERIFY,
    payload: payload,
  });
};
export const storeItemPrice = (payload: any) => {
  store.dispatch({
    type: ITEM_PRICE,
    payload: payload,
  });
};
export const storeExpressDelivery = (payload: any) => {
  store.dispatch({
    type: EXPRESS_DELIVERY,
    payload: payload,
  });
};

export const storeActiveTabData = (payload: any) => {
  store.dispatch({
    type: TAB_ACTIVE_DATA,
    payload: payload,
  });
};
export const storeActiveTab = (payload: any) => {
  store.dispatch({
    type: ACTIVE_TAB,
    payload: payload,
  });
};

export const storeOfferReduceAmount = (payload: any) => {
  store.dispatch({
    type: OFFER_REDUCE_AMOUNT,
    payload: payload,
  });
};
export const storeUsedCoupon = (payload: any) => {
  store.dispatch({
    type: USED_COUPON,
    payload: payload,
  });
};

export const storeBodySizeData = (payload: any) => {
  store.dispatch({
    type: STORE_BODY_SIZE_DATA,
    payload: payload,
  });
};
export const storeBodyHeightData = (payload: any) => {
  store.dispatch({
    type: STORE_BODY_HEIGHT,
    payload: payload,
  });
};
export const storeNotes = (payload: any) => {
  store.dispatch({
    type: STORE_NOTES,
    payload: payload,
  });
};
export const storeNumberOfPleates = (payload: any) => {
  store.dispatch({
    type: NUM_OF_PLEATES,
    payload: payload,
  });
};
export const storePrevBookingData = (payload: any) => {
  store.dispatch({
    type: STORE_PREVIOUS_BOOKING_DATA,
    payload: payload,
  });
};
export const storeApplyForAll = (payload: any) => {
  store.dispatch({
    type: APPLY_FOR_ALL,
    payload: payload,
  });
};

export const startDate= (payload: any) => {
  store.dispatch({
    type: START_DATE,
    payload: payload,
  });
};
export const endDate = (payload: any) => {
  store.dispatch({
    type: END_DATE,
    payload: payload,
  });
};