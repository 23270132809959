import instance from 'utils/axios.utils';

const admin_notification = {
  pushNotification: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url: any = '/notification/admin_push_notification';
      instance()
        .post(url, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getBookingID: (body?) => {
    let promise = new Promise((resolve, reject) => {
      let url = `booking/get_booking`;
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  getManyPushNotifications: (body:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/get_many_push_notifications';
      instance()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise
  },

  sendPushNotification: (body:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/admin_push_notification_scheduler';
      instance()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise
  },

  deleteSchedulerPushNotification: (body:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/admin_push_notification_scheduler_remove';
      instance()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise
  },

  getPushNotification:(body:any)=> {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification/get_push_notification';
      instance()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise
  }
};

export default admin_notification;
