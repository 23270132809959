import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COURIER_DELIVERY_TYPES,
  DELIVERY_STATUS,
  explanations,
  expressDelivery,
  feminine_coded_words,
  GOOGLE_MAPS_API_KEY,
  masculine_coded_words,
  nonBusinessHours,
  OTP_SHOW_LOGIC,
  possible_codings,
  quoteError,
  relaxDeliveryCharge,
  timeZones,
} from './constant.utils';
import Geocode from 'react-geocode';
import { customAlphabet } from 'nanoid';
import Notiflix from 'notiflix';
import toast, { Toaster } from 'react-hot-toast';
import Compressor from 'compressorjs';
import voucher_codes from 'voucher-code-generator'
import moment from 'moment'

Geocode.setApiKey();
export const getBaseURL = () => {
  // let baseURL = 'http://localhost:8001';
  let baseURL = 'http://192.168.1.17:8001';
  // let baseURL = 'https://pleat-api.augmo.io';
  // let baseURL='https://api.pleat.co.in'
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    baseURL = 'https://api.pleat.co.in';
  } else if (process.env.REACT_APP_NODE_ENV === 'stage') {
    baseURL = 'https://pleat-api.augmo.io';
  }
  return baseURL;
};

export const useSetState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const newSetState = (newState: any) => {
    setState((prevState: any) => ({ ...prevState, ...newState }));
  };
  return [state, newSetState];
};

export const Failure = (text: string) => {
  console.log(text);
};

export const Success = (text: string) => {
  console.log(text);
};

export const modelError = (error: any) => {
  console.log(JSON.stringify(error.response));
  if (error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else if (error.response) {
    return error.response;
  } else {
    return error;
  }
};

// export const toastifyError = (text?: any) => {
//   toast.error(text, {
//     position: 'top-center',
//     // autoClose: 5000,
//     // hideProgressBar: true,
//     // closeOnClick: true,
//     // pauseOnHover: true,
//     // draggable: true,
//   });
// };

// export const toastify = (text?: any) => {
//   toast(text, {
//     position: 'top-center',
//     // autoClose: 5000,
//     // hideProgressBar: true,
//     // closeOnClick: true,
//     // pauseOnHover: true,
//     // draggable: true,
//   });
// };

export const uploadFile = (file, signedRequest, url, cb) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedRequest);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        let data = {
          url: url,
        };
        cb(data);
      } else {
        cb({ error: 'Upload failed' });
      }
    }
  };
  xhr.send(file);
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getTimeZones = () => {
  let timeZone: any = [];
  timeZones.map((item: any) => timeZone.push({ label: item, value: item }));
  return timeZone;
};

export const getDropdownData = (data: any) => {
  let array: any = [];
  data?.map((item: string) => array.push({ label: item, value: item }));
  return array;
};

export const getDataFromDropdown = (data: any) => {
  let array: any = [];
  data?.map((item: any) => array.push(item.value));
  return array;
};
export const getValue = (data: any) => {
  let value: any;
  data?.map((item: any) => {
    return (value = item);
  });
  return value;
};

export const getDropdownValue = (data: string) => {
  if (!_.isEmpty(data)) {
    return { label: data, value: data };
  } else {
    return { label: '', value: '' };
  }
};

export const useAuth = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const filterObjectByKeys = (object = {}, keys) => {
  return _.omit(object, keys);
};

export const getExperience = (type?: string) => {
  let exp: any = [];
  if (type == 'max') {
    for (let i = 1; i <= 30; i++) {
      exp.push(i);
    }
    return exp;
  } else {
    for (let i = 0; i <= 30; i++) {
      exp.push(i);
    }
    return exp;
  }
};

export const filterByKeys = (obj, keys: any = []) => {
  const filtered: any = {};
  keys.forEach((key) => {
    if (obj?.hasOwnProperty(key)) {
      filtered[key] = obj[key];
    }
  });
  return filtered;
};

export const mergeArrayWithoutDuplicates = (array1: any, array2: any) => {
  if (array1.length == 0) {
    return array2;
  } else if (array2.length == 0) {
    return array1;
  } else if (array1.length > 0 && array2.length > 0) {
    array1.map((element1: any, index1: number) => {
      array2.map((element2: any, index2: number) => {
        if (element1._id == element2._id) {
          array1[index1] = array2[index2];
        } else {
        }
      });
    });
    return Array.from(new Set([...array1, ...array2]));
  }
};
export const isGenderBiased = (text: string) => {
  let masculineWords = masculine_coded_words;
  let feminineWords = feminine_coded_words;
  let masculineCount = 0;
  let feminineCount = 0;
  let textWords = text.split(' ');
  let genderbiasedObject = {
    masculine: {},
    feminine: {},
  };

  for (var i = 0; i < textWords.length; i++) {
    if (masculineWords.includes(textWords[i])) {
      masculineCount++;
      if (genderbiasedObject.masculine[textWords[i]] == undefined) {
        genderbiasedObject.masculine[textWords[i]] = 1;
      } else {
        genderbiasedObject.masculine[textWords[i]] =
          genderbiasedObject.masculine[textWords[i]] + 1;
      }
    } else if (feminineWords.includes(textWords[i])) {
      feminineCount++;
      if (genderbiasedObject.feminine[textWords[i]] == undefined) {
        genderbiasedObject.feminine[textWords[i]] = 1;
      } else {
        genderbiasedObject.feminine[textWords[i]] =
          genderbiasedObject.feminine[textWords[i]] + 1;
      }
    }
  }

  let genderStrength = {};
  if (masculineCount > feminineCount) {
    if (masculineCount - feminineCount > 1) {
      genderStrength['strength'] = possible_codings[4];
      genderStrength['reason'] = explanations[possible_codings[4]];
    } else {
      genderStrength['strength'] = possible_codings[3];
      genderStrength['reason'] = explanations[possible_codings[3]];
    }
  } else if (masculineCount < feminineCount) {
    if (feminineCount - masculineCount > 1) {
      genderStrength['strength'] = possible_codings[0];
      genderStrength['reason'] = explanations[possible_codings[0]];
    } else {
      genderStrength['strength'] = possible_codings[1];
      genderStrength['reason'] = explanations[possible_codings[1]];
    }
  } else {
    genderStrength['strength'] = possible_codings[2];
    genderStrength['reason'] = explanations[possible_codings[2]];
  }

  genderbiasedObject.feminine = Object.entries(genderbiasedObject.feminine);
  genderbiasedObject.masculine = Object.entries(genderbiasedObject.masculine);

  let genderData: any = {
    genderStrength: genderStrength,
    genderData: [
      { gender: 'Masculine coded words', data: genderbiasedObject.masculine },
      { gender: 'Feminine coded words', data: genderbiasedObject.feminine },
    ],
  };
  return genderData;
};

export const gets3FileName = (file: any) => {
  let filename: string = file.split('/').pop();
  return filename.split('_').pop();
};

// geocode
export const geocode = async (lat, long) => {
  Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage('en');
  Geocode.setRegion('TN');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();

  let promise = new Promise((resolve, reject) => {
    Geocode.fromLatLng(lat, long)
      .then((response) => {
        const address = response.results[0].formatted_address;
        resolve(address);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

export const removeAmountKey = (data) => {
  let removeAmount: any = [];
  data.map((item: any) => {
    delete item['amount'];
  });
  data.map((elem: any) => {
    removeAmount.push(elem);
  });
  return removeAmount;
};
export const removeId = (data) => {
  let removeId: any = [];
  data.map((item: any) => {
    delete item['_id'];
  });
  data.map((elem: any) => {
    removeId.push(elem);
  });
  return removeId;
};
export const nanoid = () => {
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const nanoId = customAlphabet(alphabet, 10);
  return nanoId();
};

export const isMobileApp = () => {
  if (
    navigator.userAgent ===
    'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/67.0.3396.99 Safari/537.36 android' ||
    navigator.userAgent ===
    'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/67.0.3396.99 Safari/537.36 ios'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAndroid = () => {
  if (
    navigator.userAgent ===
    'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/67.0.3396.99 Safari/537.36 android'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isIos = () => {
  if (
    navigator.userAgent ===
    'Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/67.0.3396.99 Safari/537.36 ios'
  ) {
    return true;
  } else {
    return false;
  }
};

export const notiflixLoader = (data?) => {
  if (data) {
    Notiflix.Loading.standard(data);
  } else {
    Notiflix.Loading.dots('');
  }
};

export const notiflixFailure = (err: any) => {
  toast.error(err, {
    id: '1',
  });
};

export const notiflixSuccess = (success: any) => {
  toast.success(success, {
    id: '1',
  });
};

export const notiflixRemove = () => {
  Notiflix.Loading.remove();
};

Notiflix.Notify.init({
  position: 'center-top',
  distance: '50px',
});

Notiflix.Loading.init({
  className: 'notiflix-loading',
  zindex: 4000,
  backgroundColor: 'rgba(0,0,0,0)',
  svgSize: '60px',
  svgColor: '#9C312B',
});
export const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return '';
  }
};
export const compressImage = function (file) {
  return new Promise(function (resolve, reject) {
    new Compressor(file, {
      quality: 0.8,
      maxWidth: 1024,
      success(result) {
        resolve(result);
      },
      error(result) {
        reject(result)
      }
    })
  })
}

export const coupongenerator = () => {
  let couponCode: any;
  let coupon = voucher_codes.generate({
    length: 7,
    count: 1,
  });
  coupon.map((item: any) => {
    return couponCode = item;
  });
  return couponCode;
};

export const otpListRender = (data: any) => {
  if (
    data.status === OTP_SHOW_LOGIC.WAITING_FOR_PICKUP ||
    data.status === OTP_SHOW_LOGIC.PICKED_UP ||
    data.status === OTP_SHOW_LOGIC.REACHED ||
    data.status === OTP_SHOW_LOGIC.WAITING_FOR_DISPATCH ||
    data.status === OTP_SHOW_LOGIC.DISPATCH ||
    data.status === OTP_SHOW_LOGIC.DELIVERED
  ) {
    return true;
  } else return false;
};

export const otpLogic = (data: any, user: any) => {
  if (
    data.status === OTP_SHOW_LOGIC.WAITING_FOR_PICKUP ||
    data.status === OTP_SHOW_LOGIC.PICKED_UP ||
    data.status === OTP_SHOW_LOGIC.REACHED
  ) {
    if (user?.role === 'pleater') return data?.drop_otp;
    else return data?.pickup_otp;
  } else if (
    data.status === OTP_SHOW_LOGIC.WAITING_FOR_DISPATCH ||
    data.status === OTP_SHOW_LOGIC.DISPATCH ||
    data.status === OTP_SHOW_LOGIC.DELIVERED
  ) {
    if (user?.role === 'pleater') return data?.pickup_otp;
    else return data?.drop_otp;
  }
};

export const isDisableBookingButton = (
  err: any,
  user: any,
  courier: string
) => {
  if (!_.isEmpty(err)) {
    if (quoteError.includes(err)) {
      if (_.has(user?.address[0], "pincode")) {
        if (_.isEmpty(courier)) {
          return true;
        }
      } else return true;
    }
  }
};

export const expressDeliveryRate = (deliveryMode: string, booking: any) => {
  if (!_.isEmpty(deliveryMode)) {
    if (deliveryMode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY) {
      const amount: any = expressDelivery * booking.length;
      return parseInt(amount);
    } else if (deliveryMode === COURIER_DELIVERY_TYPES.RELAX_DELIVERY) {
      const amount: any = _.chain(booking)
        .filter((e: any) => !_.isEmpty(e.amount))
        .sumBy((e: any) => e.amount)
        .value();
      return parseInt(amount + relaxDeliveryCharge);
    } else return 0;
  } else return 0;
};


export const getTime = (date?:string)=> {
  const time = date ? moment(date) : moment()
  return time
}

export const estimatedTime = (reachedTime?: string) => {
  const nonBusinessTiming: string[] = [];
  let timeNow: any = getTime(reachedTime)
  const addHours: any = reachedTime ? timeNow.clone().add(12, 'hours') : moment().add(12, "hours");
  const hoursDifference = addHours.diff(timeNow, 'hours')
  if (_.lte(hoursDifference, 12)) {
    for (let i = 0; i <= hoursDifference - 1; i++) {
      const time:any = timeNow.add(1,'hours').format('HH:mm')
      if (_.gte(time, nonBusinessHours.from) || _.lte(time, nonBusinessHours.to)) {
        nonBusinessTiming.push(time)
      }
    }
    if (!_.isEmpty(nonBusinessTiming)) {
      timeNow = getTime(reachedTime)
      timeNow = timeNow.add(1, 'day')
      timeNow = timeNow.set({ hour: 6 })
      return timeNow.add(nonBusinessTiming.length, 'hour').format('dddd hh:mm A')
    }
    else return addHours.format('dddd hh:mm A')
  }
}


export const windowSize = () => {
  const width = window.innerWidth
  if (_.gte(width, 500)) return true
}












