import React, { Suspense } from 'react';
import './App.scss';
import Test from 'screens/test/test.screen';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Main from 'common_components/hoc/main.hoc';
import store from 'store/store';
import { Provider } from 'react-redux';
// import ChatScreen from 'screens/chat/chat.screen';
import Login from 'screens/login/login.screen';
import OtpVerify from 'screens/otp_verify/otp_verify.screen';
import Startedpage from 'screens/get_started/get_started.screen';
import Notification from 'screens/notification/notification.screen';
import Account from 'screens/account/account.screen';
import Orders from 'screens/orders/orders.screen';
import SplashScreen from 'components/test/splash_screen/splash.screen';
import Home from 'screens/home/home.screen';
import Booking from 'screens/booking/booking.screen';
import AddAddress from 'screens/add_address/address.screen';
import Successful from 'screens/successful/successful.screen';
import Cancelled from 'screens/cancelled/cancelled.screen';
import TransferRequest from 'screens/transfer_request/transfer_request.screen';
import Payment from 'screens/payment/payment.screen';
import Reviews from 'screens/reviews/reviews.screen';
import SelectCoupon from 'screens/coupon/select_coupon.screen';
import OrderDetails from 'screens/order_details/order_details.screen';
import DeliverSarees from 'screens/deliver_sarees/deliver_sarees.screen';
import PrivacyScreen from 'screens/privacy/privacy.screen';
import TermsAndCondition from 'screens/terms and condition/terms_and_condition.screen';
import toast, { Toaster } from 'react-hot-toast';

// admin-Panel
import Dashboard from 'admin_panel/admin_screens/dashboard/dashboard.admin_screen';
import Users from 'admin_panel/admin_screens/users/users.admin_screen';
import Bookings from 'admin_panel/admin_screens/bookings/bookings.admin_screen';
import BookingDetails from 'admin_panel/admin_screens/booking_details/booking_details.screen';
import UserDetails from './admin_panel/admin_screens/user_details/user_details.screen';
import Payout from 'admin_panel/admin_screens/payout/payout.admin_screen';
import NotificationAdmin from 'admin_panel/admin_screens/notification/notification.admin_screen';
import LiveLocation from './admin_panel/admin_screens/location/live_location.admin_screen';
import { useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'utils/constant.utils';
import AdminCouponScreen from 'admin_panel/admin_screens/coupons/admin_coupon.screen';
// import Conversation from 'screens/chat_screen/chat.screen';
// const token = localStorage.getItem('token');

function App() {
  const role = localStorage.getItem('role');

  if (role && role === 'admin') {
    document.body.classList.remove('body_class_add');
  } else {
    document.body.classList.add('body_class_add');
  }

  if (role === 'admin') {
    document.documentElement.style.backgroundColor = '#fff';
  } else {
    document.documentElement.style.backgroundColor = '#282c34';
  }
   const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    region:'in',
    id: 'google-map-script',
  });
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Routes>
            <Route
              path="/notification"
              element={
                <Main>
                  <Notification />
                </Main>
              }></Route>
            <Route
              path="/orders"
              element={
                <Main>
                  <Orders />
                </Main>
              }></Route>
            <Route path="/" element={<SplashScreen />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/otp_verify" element={<OtpVerify />}></Route>
            <Route path="/get_started" element={<Startedpage />}></Route>
            <Route path="/test" element={<Test />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/booking" element={<Booking />}></Route>
            {isLoaded && (
            <Route
              path="/address"
              element={
                <Main>
                  <AddAddress />
                </Main>
              }></Route>
            ) }
            <Route
              path="/successful"
              element={
                <Main>
                  <Successful />
                </Main>
              }></Route>
            <Route path="/cancelled" element={<Cancelled />}></Route>
            <Route
              path="/payment"
              element={
                <Main>
                  <Payment />
                </Main>
              }></Route>
            <Route path="/reviews" element={<Reviews />}></Route>
            <Route
              path="/coupon"
              element={
                <Main>
                  <SelectCoupon />
                </Main>
              }></Route>
            <Route
              path="/order_details"
              element={
                <Main>
                  <OrderDetails />
                </Main>
              }></Route>
            <Route
              path="/review"
              element={
                <Main>
                  <Reviews />
                </Main>
              }></Route>
            <Route
              path="/account"
              element={
                <Main>
                  <Account />
                </Main>
              }></Route>
            <Route path="/deliver_sarees" element={<DeliverSarees />}></Route>
            <Route
              path="/transfer_request"
              element={
                <Main>
                  <TransferRequest />
                </Main>
              }></Route>
            <Route
              path="/privacy"
              element={
                <Main>
                  <PrivacyScreen />
                </Main>
              }></Route>
            <Route
              path="/terms"
              element={
                <Main>
                  <TermsAndCondition />
                </Main>
              }></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/users" element={<Users />}></Route>
            <Route path="/bookings" element={<Bookings />}></Route>
            <Route path="/booking_details" element={<BookingDetails />}></Route>
            <Route path="/user_details" element={<UserDetails />}></Route>
            <Route path="/payout" element={<Payout />}></Route>
            <Route path="/coupons" element={<AdminCouponScreen/>}></Route>
            {isLoaded && (
              <Route path="/live_location" element={<LiveLocation />}></Route>
            )}
            <Route
              path="/notification_admin"
              element={<NotificationAdmin />}></Route>
          </Routes>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: '',
              duration: 2000,
              style: {
                background: '#363636',
                color: '#fff',
              },
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
          />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
