import { SingleDatePicker } from '../../../../utils/imports.utils';
import { useSetState } from 'utils/functions.utils';
import './admin_header.scss';
import { endDate, startDate } from 'utils/redux.utils';
import { useSelector } from 'react-redux';
import moment from 'moment';
interface AdminHeaderProps {
  text: string;
  type?: string;
}

const AdminHeader = (props: AdminHeaderProps) => {
  const date: any = useSelector((store: any) => store.date);
  const currentDate = moment().format('DD-MM-YYYY')
  return (
    <div className="admin_header">
      <div className="title_name">{props.text}</div>
      {props.text === 'Dashboard' && (
        <div className="date_picker_container">
          <SingleDatePicker
            value={date.startDate}
            placeholder={"Start Date"}
            onChange={(value: any) => startDate(value)}
            iconOnPress={() => startDate(null)}
            onFocusPress={() => startDate(null)}
          />
          <SingleDatePicker
            value={date.endDate}
            placeholder={date.startDate? currentDate : 'End Date'} 
            onChange={(value: any) => endDate(value)}
            iconOnPress={() => endDate(null)}
            onFocusPress={() => endDate(null)}

          />
        </div>
      )}

      {/* <div className="profile_wrapper">
        <div className="search_wrapper">
          <Searchbar placeholder={'search'} className={' search_content'} />
        </div>
        <div className="icon_panel">
          <img src={Assets.notification} alt="no img" />
          <img src={Assets.message_box} alt="no img" />
          <img src={Assets.comment} alt="no img" />
          <img src={Assets.user_picture} alt="no img" />
        </div>
      </div> */}
    </div>
  );
};

export default AdminHeader;
