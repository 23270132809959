import React from 'react';
import TestComponent from 'components/test/test.component';
import Assets from 'imports/assets.import';

export default function Test() {
  return (
    <div>
      
    </div>
  );
}
