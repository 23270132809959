import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSetState, geocode, useQuery } from 'utils/functions.utils';
import {
  Functions,
  Models,
  Assets,
  OutlineInput,
  Header,
  PrimaryButton,
  BottomSheetComponent,
  GoogleMapsPicker,
  MapPicker,
  Validation,
  Navbutton,
  Colors,
} from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './address.screen.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Notiflix from 'notiflix';
import OutsideClickHandler from 'react-outside-click-handler';
import PuffLoader from 'react-spinners/PuffLoader';
import { setUser } from '../../utils/redux.utils';
import { Autocomplete } from '@react-google-maps/api';

interface IAddAddress {
  text?: String;
}

const AddAddress = (props: IAddAddress) => {
  // Redux
  const user = useSelector((state: any) => state.user);

  // route
  const navigate = useNavigate();
  const location = useLocation();

  // query
  const query = useQuery();
  let edit: any = query.get('edit');
  let path: any = query.get('path');

  // State
  const [state, setState] = useSetState({
    show: true,
    menu: false,
    errorArray: [],
    add_address_show: false,
    disabled: false,
    latlong_data: '',
    latlong_address_data: '',
    house_number: '',
    apartment: '',
    phone_number: '',
    pincode:'',
    loading: true,
    address_data: '',
    edit_address: '',
    select_address: '',
    edit_button: true,
    user_data: '',
    name: '',
    editLatLong: false,
    current_location: false,
    editLatLong_data: '',
    address_confirm:true,
    autocomplete:null,
    email:''
  });

  // variables
  let latitude, longitude;

  //Hooks
  useEffect(() => {
    // getGeoLocation();
    getAddress();
    getUser();
    setTimeout(() => {
      setState({ map_show: true });
    }, 3000);
  }, []);

  // Network req

  // get Address
  const getAddress = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.address.getAddress();
      setState({ select_address: res.data.address[0] });
      if (res.data?.address?.length === 0) {
        setState({ show: true });
      } else {
        setState({ show: false });
      }
      setState({ address_data: res.data });
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      setState({ user_data: res.data });
      setState({ name: res.data.name ? res.data.name : '' ,email: res.data.email ? res.data.email : '' });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // create address
  const createAddress = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        house_number: state.house_number,
        phone_number: state.phone_number,
        apartment: state.apartment,
        map_address: state.latlong_address_data,
        name: state.name,
        email:state.email,
        pincode:state.pincode
      };
      if(state.apartment !==""){
        body.apartment=state.apartment
      }
      await Validation.createAddress.validate(body, { abortEarly: false });
      const latlong = {
        location_type: 'point',
        coordinates: [state.latlong_data.lat, state.latlong_data.long],
      };
      body.location = latlong;
      console.log('state', state.latlong_data);
      console.log('body', body);
      delete body.name;
      const res: any = await Models.address.createAddress(body);
      const userBody = {
        name: state.name,
      };
      const editUser: any = await Models.auth.editUser(userBody);
      setUser(editUser.data);
      getUser();
      setState({ add_address_show: !state.add_address_show });
      setState({
        edit_addres: '',
        select_address: '',
        phone_number: '',
        house_number: '',
        apartment: '',
        pincode:'',
        email:'',
      });
      getAddress();
    } catch (error: any) {
      setState({ disabled: false });
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: '' });
      }, 2000);
    } finally {
      Functions.notiflixRemove();
    }
  };


  // defaultAddress

  const makeDefaultAddress = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.address.defaultAddress(
        state.select_address,
      );
      navigate(-1);
      getAddress();
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // delete Address

  const deleteAddress = async (item) => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.address.deleteAddress(item);
      getAddress();
      getUser();
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const updateAddress = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        phone_number: state.phone_number,
        house_number: state.house_number,
        apartment: state.apartment,
        map_address: state.latlong_address_data,
        name: state.name,
        pincode:state.pincode,
        email:state.email,
      };
      if(state.apartment !==""){
        body.apartment=state.apartment
      }
      await Validation.createAddress.validate(body, { abortEarly: false });
      const latlong = {
        location_type: 'point',
        coordinates: [state.latlong_data.lat, state.latlong_data.long],
      };
      body.location = latlong;
      delete body.name;
      const res: any = await Models.address.editAddress(
        state.edit_address._id,
        body,
      );
      const userBody = {
        name: state.name,
      };
      const editUser: any = await Models.auth.editUser(userBody);
      setUser(editUser.data);
      setState({ editLatlng: false });
      setState({ edit_button: true });
      getAddress();
      getUser()
      setState({ add_address_show: !state.add_address_show });
    } catch (error: any) {
      setState({ disabled: false });
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: '' });
      }, 2000);
    } finally {
      Functions.notiflixRemove();
    }
  };

  

  //Logic
  const editAddress = async (value: any, index: number) => {
    let latlongData = {
      lat: value.location.coordinates[0],
      long: value.location.coordinates[1],
    };
    setState({ editLatLong_Data: latlongData });
    setState({ editLatLong: true });
    let data = value;
    setState({ edit_address: value });
    setState({ show: true, edit_button: false });
    setState({ name: user.user.name });
    setState({ add_address_show: !state.add_address_show });
    setState({
      phone_number: data.phone_number,
      house_number: data.house_number,
      apartment: data.apartment,
      pincode:data?.pincode,
      email:state.user_data?.email
    });
  };



  const defaultAddress = async (index: number, value: any) => {
    setState({ select_address: value });
  };

  const showAdd = () => {
    setState({ show: false,address_confirm:true });
    if (edit == 'true') {
      setState({ add_address_show: true });
    }
  };
  const hideAdd = () => {
    setState({ show: true, address_confirm: !state.address_confirm });
  };
  const openMenu = () => {
    setState({ menu: !state.menu });
  };
  const locationBtnWidthCalc=(targetWidth:number)=>{
    let width = (document.body.clientWidth / 2) - targetWidth;
    return width
  }

  // autocomplete
  const center = { lat: 50.064192, lng: -130.605469 };
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };
  const onLoadAutocomplete = (autocomplete:any)=>{
    setState({autocomplete})
   }
   const handlePlace = () => {
    if (state.autocomplete !== null) {
      const location = {
        lat: state.autocomplete.getPlace().geometry.location.lat(),
        long: state.autocomplete.getPlace().geometry.location.lng(),
      };
      setState({ editLatLong_Data: location , editLatLong: true});
      getAddressFromLatLong(location.lat,location.long)
    } else {
      Functions.notiflixFailure('Autocomplete is not loaded yet!');
    }
  };
  const getAddressFromLatLong = async (lat, lng) => {
    const response: any = await geocode(lat, lng);
    setState({ latlong_address_data: response });
  };

  return (
    <div className="address_container">
      <div className="address_main_wrapper">
        {state.show ? (
          <>
            <div className="address_screen_header_wrapper">
              <div className='address_screen_nav_button'>
                <Navbutton
                  icon={Assets.arrowIndicate}
                  onClick={() => navigate(-1)}
                  className={'address_screen_nav_button_color'}
                />
              </div>
              <div
                style={{ left: locationBtnWidthCalc(110) }}
                className={` ${
                  state.show
                    ? 'google_map_auto_complete'
                    : 'address_screen_nav_button_hide'
                }`}>
                <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={handlePlace} >
                  <input
                    type="text"
                    className='autocomplete_placeholder'
                    placeholder="Search your places..."
                    style={{
                      border: `1px solid transparent`,
                      width: `250px`,
                      height: `45px`,
                      padding: `20px`,
                      borderRadius: `6px`,
                      boxShadow: `0px 1px 5px 0px #9c312b`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      zIndex: 2,
                    }}
                  />
       
                </Autocomplete>
              </div>
            </div>
            <div
              style={{ left: locationBtnWidthCalc(110) }}
              className={`${
                state.show
                  ? 'address_screen_current_location'
                  : 'address_screen_current_location_hide'
              }`}>
              <div
                className="address_screen_nav_button_color_locations"
                onClick={() =>
                  setState({ current_location: !state.current_location,editLatLong: false })
                }>
                <div className="location_img">
                  <img src={Assets.location} height={20} width={20} />
                </div>
                <div>Use current location</div>
              </div>
              {/* <Navbutton
                widthTrue={true}
                className={'address_screen_nav_button_color_location'}
                icon={Assets.my_location}
                onClick={() =>
                  setState({ current_location: !state.current_location })
                }
              /> */}
            </div>
          </>
        ) : null}

        {state.map_show ? (
          <div
            className="address_map"
            style={state.show ? { height: '90%' } : { height: '100%' }}>
            <MapPicker
              latlongData={state.editLatLong_Data}
              currentLocation={state.current_location}
              editLatlong={state.editLatLong}
              latlong={(value: any) => {
                setState({ latlong_data: value });
              }}
              address={(value: any) => {
                setState({ latlong_address_data: value });
              }}
            />
          </div>
        ) : null}
        {state.show ? (
          <>
            {state.latlong_address_data ? (
              <div className="address_form_button">
                <div className="latlong_address">
                  <div className="latlong_address_title">Address</div>
                  {state.latlong_address_data && state.latlong_address_data}
                </div>
                <PrimaryButton
                  text={'Enter complete address'}
                  onClick={() => {
                    showAdd();
                  }}
                  style={{
                    background:
                      'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
                    boxShadow: '-1px 4px 16px -4px rgba(156,49,43,1)',
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}>
                <PuffLoader
                  color={Colors.primaryLightColor}
                  loading={true}
                  size={100}
                />
                <div style={{ marginTop: 20, textAlign: 'center' }}>
                  Loading Maps Please wait...
                </div>
              </div>
            )}
          </>
        ) : (
          <BottomSheetComponent
            open={open}
            onDismiss={hideAdd}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 1,
            ]}>
            {state.add_address_show ? (
              <>
               { state.address_confirm ? (
                <>
                 <div className="address_screen_confirm_wrapper">
                   <div className='address_sreen_confirmation_header'>
                     Confirm Address
                   </div>
                <div className='address_screen_confirm_text'>
                  {state.latlong_address_data}
                </div>
                </div>
                <div className='address_screen_bottom_line'></div>
                <div className="address_confirm_button_wrapper">
                <div>
                <PrimaryButton
                onClick={()=>setState({ show: !state.show })}
                text={'Change'}
                className={'address_screen_change_button'}
                />
                </div>
                <div>
               <PrimaryButton
                onClick={()=> setState({ address_confirm : !state.address_confirm })}
                text={'Confirm'}
                className={'address_screen_confirm_button'}
               />
               </div>
               </div>
                </>
               ) : ( 
                  <>
                <div className="address_wrapper">
                  <div className="address_header_container">
                    <Header
                      title="Add Address"
                      onClick={() => {
                        setState({ add_address_show: !state.add_address_show });
                      }}
                    />
                  </div>
                  <div className="address_house_number">
                    <OutlineInput
                      error={state.errorArray}
                      icon={Assets.error}
                      onChange={(email) => {
                        setState({ email });
                      }}
                      type="text"
                      value={state.email}
                      name="email"
                      label="Email *"
                    />
                  </div>
                  <div className="address_house_number">
                    <OutlineInput
                      error={state.errorArray}
                      icon={Assets.error}
                      onChange={(name) => {
                        setState({ name });
                      }}
                      type="text"
                      value={state.name}
                      name="name"
                      label="Name *"
                    />
                  </div>
                  <div className="address_house_number">
                    <OutlineInput
                     icon={Assets.error}
                      error={state.errorArray}
                      onChange={(house_number) => {
                        setState({ house_number });
                      }}
                      type="text"
                      value={state.house_number}
                      name="house_number"
                      label="House No & Floor *"
                    />
                  </div>

                  <div className="address_building_name">
                    <OutlineInput
                     icon={Assets.error}
                      error={state.errorArray}
                      onChange={(apartment) => {
                        setState({ apartment });
                      }}
                      type="text"
                      value={state.apartment}
                      name="apartment"
                      label="Street / Apartment Name*"
                    />
                  </div>
                  <div className="address_floor_number">
                    <OutlineInput
                     icon={Assets.error}
                      error={state.errorArray}
                      onChange={(phone_number) => {
                        setState({ phone_number });
                      }}
                      type="number"
                      value={state.phone_number}
                      name="phone_number"
                      label="Phone Number *"
                    />
                  </div>
                  <div className="address_floor_number">
                    <OutlineInput
                     icon={Assets.error}
                      error={state.errorArray}
                      onChange={(pincode) => {
                        setState({ pincode });
                      }}
                      type="number"
                      value={state.pincode}
                      name="pincode"
                      label="Pincode *"
                    />
                  </div>
                  <div
                    style={{ display: 'none' }}
                    className="address_checkbox_container">
                    <div className="address_checkbox_icon">
                      <input type="checkbox" name="save checkbox" />
                    </div>
                    <div className="address_save_address_text">
                      Save the address for next order
                    </div>
                  </div>
                  {state.edit_button ? (
                    <div
                      onClick={() => createAddress()}
                      className="address_add_button">
                      <PrimaryButton
                        text="Add Address"
                        style={{
                          background:
                            'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
                          boxShadow: '-1px 4px 16px -4px rgba(156,49,43,1)',
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => updateAddress()}
                      className="address_add_button">
                      <PrimaryButton
                        text="Edit Address"
                        style={{
                          background:
                            'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
                          boxShadow: '-1px 4px 16px -4px rgba(156,49,43,1)',
                        }}
                      />
                    </div>
                  )}
                </div>
                  </>
               ) }
                </>
              
            ) : (
              <>
                <div className="address_select_address_container">
                  <div className="select_address_header">
                    <Header
                      onClick={() => setState({ show: !state.show })}
                      title="Add Address"
                    />
                  </div>
                  <div className="select_saved_address_container">
                    <div className="select_saved_address_subheading">
                      Saved Address
                    </div>
                    {state.address_data.address &&
                      state.address_data.address.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="address_screen_container"
                                style={
                                  index == state.address_data.address.length - 1
                                    ? { border: 'none' }
                                    : {}
                                }>
                                <div className="select_address_container">
                                  <div
                                    onClick={() => defaultAddress(index, item)}
                                    className="select_address_radiobox">
                                    <img
                                      src={
                                        state.select_address._id === item._id
                                          ? Assets.radio_active
                                          : Assets.radio_inactive
                                      }
                                    />
                                  </div>
                                  <div
                                    onClick={() => defaultAddress(index, item)}
                                    className="select_address_wrapper">
                                    <div className="select_address_type">
                                      {state.address_data?.name}
                                    </div>
                                    <div className="select_address_text">
                                      {item.house_number}
                                    </div>
                                    <div className="select_address_text">
                                      {item.apartment}
                                    </div>
                                    <div className="select_address_text">
                                      {item?.phone_number}
                                    </div>
                                  </div>
                                </div>

                                <div className="address_screen_edit_and_delete_wrapper">
                                  <div
                                    onClick={() => editAddress(item, index)}
                                    className="address_screen_edit_button">
                                    Edit
                                  </div>
                                  <div
                                    onClick={() => deleteAddress(item)}
                                    className="address_screen_edit_button">
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        },
                      )}
                  </div>
                  <div className="select_add_address_button">
                    <PrimaryButton
                      onClick={() => {
                        {
                          state.address_data?.address.length > 0
                            ? setState({
                                add_address_show: !state.add_address_show,
                                phone_number: '',
                                apartment: '',
                                house_number: '',
                                edit_button: true,
                                show: true,
                              })
                            : setState({
                                add_address_show: !state.add_address_show,
                                phone_number: '',
                                apartment: '',
                                house_number: '',
                                edit_button: true,
                              });
                        }
                      }}
                      text="+ Add new address"
                      className="add_address_button"
                    />
                  </div>
                  <div
                    onClick={() =>
                      `${
                        state.user_data.address.length > 0
                          ? makeDefaultAddress()
                          : navigate(-1)
                      }`
                    }
                    className="select_confirm_button">
                    <PrimaryButton
                      text={`${
                        state.user_data?.address?.length > 0
                          ? 'Confirm'
                          : 'Back'
                      }`}
                      style={{
                        background:
                          'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
                        boxShadow: '-1px 4px 16px -4px rgba(156,49,43,1)',
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </BottomSheetComponent>
        )}
      </div>
    </div>
  );
};

export default AddAddress;
