import { combineReducers } from 'redux';
import testReducer from 'store/reducers/test.reducer';
import chatReducer from 'store/reducers/chat.reducer';
import userReducer from 'store/reducers/user.reducer';
import bookingReducer from 'store/reducers/booking.reducer';
import orderRedcuer from 'store/reducers/order.reducer';
import dateRedcuer from 'store/reducers/date.reducer';

export default combineReducers({
  test: testReducer,
  chat: chatReducer,
  user: userReducer,
  booking: bookingReducer,
  order:orderRedcuer,
  date:dateRedcuer,
});
