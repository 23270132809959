import React, { useEffect,useRef } from 'react';
import {
  Assets,
  BottomSheetComponent,
  Header,
  Footer,
  Models,
  Functions,
  TextArea,
  PrimaryButton,
} from '../../utils/imports.utils';
import './account.screen.scss';
import Switch from 'react-switch';
import { useSetState } from 'utils/functions.utils';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';
import { setUser } from 'utils/redux.utils';
import Confirmation from '../../common_components/ui/confirmation/confirmation.component';
import { RWebShare } from "react-web-share";
import { useSelector } from 'react-redux';



export default function Account(props) {
  // state
  const [state, setState] = useSetState({
    data: '',
    delete_request: false,
    logout: false,
    feedback_content:"",
    feedback_bottom_sheet:false,
    loading:false
  });

  // route
  const navigate = useNavigate();

  // redux
  const user:any = useSelector((state:any)=>state.user)

  useEffect(() => {}, []);

  const handleChange = async (checked) => {
    Functions.notiflixLoader();
    let statusCheck = checked;
    const body = {
      availability: statusCheck,
    };
    try {
      const res: any = await Models.auth.editAvailability(body);
      setUser(res.data);
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };



  const deleteRequest = async () => {
    Functions.notiflixLoader();
    try {
      const body = {
        account_delete_request: true,
      };
      const res: any = await Models.auth.editAvailability(body);
      setState({ request_success: true, delete_request: false });
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const cacheClear = () => {
    {/*// @ts-ignore */}
    window?.ReactNativeWebView?.postMessage(JSON.stringify({ "message": "cache" }));
  }

  const logout = async () => {
    Notiflix.Loading.pulse('signout...');
    await localStorage.clear();
    setUser({});
    setState({logout:false})
    navigate('/login');
    Functions.notiflixRemove();
  };

  const bottomSheet=()=>{
    setState({ feedback_bottom_sheet : !state.feedback_bottom_sheet})
  }

  const createFeedback = async () => {
    setState({ loading: true });
    if (state.feedback_content.length === 0) {
      Functions.notiflixFailure('Enter your feedback');
      setState({ loading: false });
    } else {
      try {
        const body = {
          message: state.feedback_content,
        };
        const res: any = await Models.feedback.createFeedback(body);
        setState({
          loading: false,
          feedback_bottom_sheet: !state.feedback_bottom_sheet,
        });
      } catch (err: any) {
        Functions.notiflixFailure(err);
      }
    }
  };

  return (
    <div className="account_container">
      <div className="account_header_container">
        <Header title="Account" />
      </div>
      <div className="account_list_container">
        {props.user.user.role === 'pleater' && (
          <div className="account_list_flex_wrapper">
            <div className="account_avaliability_flex_wrapper">
              <div className="account_list_icon">
                <img width={24} height={24} src={Assets.timeicon} className="symbol_wrap" />
              </div>
              <div className="account_list_name">Availability</div>
            </div>
            <div className="account_avaliability_switch">
              <Switch
                checked={props.user.user.availability}
                onChange={handleChange}
                onColor="#3CCF4E"
                onHandleColor="#FFFFFA"
                handleDiameter={50}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                height={26}
                width={50}
                className="react-switch"
                id="material-switch"
              />
            </div>
          </div>
        )}
        {props.user.user.role === 'pleater' && (
          <div
            onClick={() => navigate('/payment')}
            className="account_list_element">
            <div className="account_list_icon">
              <img width={24} height={24} src={Assets.payment} className="symbol_wrap" />
            </div>
            <div className="account_list_element_name_edit">Payment</div>
          </div>
        )}
        <div
          className="account_list_element"
          onClick={() => navigate('/address?edit=true')}>
          <div className="account_list_icon_edit">
            <img width={24} height={24} src={Assets.edit_new_icon} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name_edit">Edit Address</div>
        </div>
        {/* {props.user.user.role === 'pleater' && ( */}
        <div
          onClick={() => setState({ delete_request: true })}
          className="account_list_element">
          <div className="account_list_icon">
            <img width={24} height={24} src={Assets.account_delete} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name">
            Account delete request
          </div>
        </div>
        {/* )} */}
        <div
          onClick={() => navigate('/terms')}
          className="account_list_element">
          <div className="account_list_icon">
            <img width={24} height={24} src={Assets.terms_condition} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name">Terms & Conditions</div>
        </div>
        {props.user.user.role !== 'pleater' && (
          <div>
          <div onClick={() => setState({feedback_bottom_sheet:!state.feedback_bottom_sheet})} className="account_list_element">
            <div className="account_list_icon">
              <img width={24} height={24}  src={Assets.feedback} className="symbol_wrap" />
            </div>
            <div className="account_list_element_name">Feedback</div>
          </div>

        {/* bottom sheet start */}
        <BottomSheetComponent
            open={state.feedback_bottom_sheet}
            onDismiss={bottomSheet}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 1,
            ]}>
              <div className='account_screen_feedback_wrapper'>
             <Header onClick={()=> setState({feedback_bottom_sheet : !state.feedback_bottom_sheet })} title={'Feedback'}/>
             <div className='account_screen_feedback_input_wrapper'>
             <TextArea placeholder={'Enter your feedback'} rows={3} onChange={(e:any)=>setState({feedback_content:e.target.value})} value={state.feedback_content}/>
             </div>
             <div style={{paddingRight:'10px',paddingLeft:'10px'}}>
             <PrimaryButton disabled={state.loading} onClick={()=>createFeedback()} text={'Send feedback'}/>
             </div>
              </div>
       </BottomSheetComponent>
        { /* bottom sheet end */}
        </div>
          
        )}
        <div onClick={() => cacheClear()} className="account_list_element">
          <div className="account_list_icon">
            <img width={24} height={24}  src={Assets.trash_new} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name">Clear cache</div>
        </div>
        <div
          onClick={() => navigate('/privacy')}
          className="account_list_element">
          <div className="account_list_icon">
            <img width={24} height={24} src={Assets.privacy} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name">Privacy</div>
        </div>

        {props.user.user.role !== 'pleater' && (
          <div className="account_list_element">
            <div className="account_list_icon">
              <img width={24} height={24} src={Assets.refer_earn} className="symbol_wrap" />
            </div>
            <div className="account_list_element_name">
              <RWebShare
                data={{
                  text: `Want to save on your next pleat? Use my referral code ${user.user.referral_code} during sign up for 50% discount on your first order!`,
                  url: 'https://play.google.com/store/apps/details?id=com.pleat',
                  title: 'Pleat',
                }}
                >
                <div>Refer & earn</div>
              </RWebShare>
            </div>
          </div>
        )}
        <a href="tel:9043040220" className="account_list_element">
          <div className="account_list_icon">
            <img src={Assets.call} width={24} height={24} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name">Contact us</div>
        </a>
        <div
          onClick={() => setState({ logout: true })}
          className="account_list_element">
          <div className="account_list_icon">
            <img width={24} height={24}  src={Assets.signout} className="symbol_wrap" />
          </div>
          <div className="account_list_element_name">Sign out</div>
        </div>
        <div className="build_number_container">
          <div className="build_number">Build 2.8</div>
        </div>
      </div>
      <div>
        {state.delete_request ? (
          <>
            <Confirmation
              outSideClick={(value: any) => setState({ delete_request: value })}
              onClick={() => deleteRequest()}
              back={() => setState({ delete_request: false })}
              buttonText={'Confirm'}
              title={'Delete account request'}
              text={'Are you sure you want to delete this account'}
            />
          </>
        ) : null}
        {state.request_success ? (
          <>
            <Confirmation
              outSideClick={(value: any) =>
                setState({ request_success: value })
              }
              onClick={() => setState({ request_success: false })}
              button={true}
              title={'Account delete request has been  successfully submited'}
              text={'Your request will be processing in 7 days'}
            />
          </>
        ) : null}
        {state.logout && (
          <Confirmation
            outSideClick={(value: any) => setState({ logout: value })}
            onClick={() => logout()}
            back={() => setState({ logout: false })}
            buttonText={'Confirm'}
            title={'Logout'}
            text={'Are you sure you want to logout'}
          />
        )}
      </div>
    
      <div className="account_footer_container">
        <Footer />
      </div>
    </div>
  );
}
