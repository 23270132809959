import React from 'react';
import Swiper from 'react-id-swiper';
import "swiper/css/swiper.css";

const SwiperComponent = (props) => {
  return (
    <Swiper {...props.params}>
      {props.children}
    </Swiper>
  );
}
export default SwiperComponent;