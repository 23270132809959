import React, { useEffect } from 'react';
import {
  Navbutton,
  Assets,
  PrimaryButton,
  Models,
  Validation,
  Functions,
} from '../../utils/imports.utils';
import './otp_verify.screen.scss';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { useSetState, useQuery, isMobileApp } from 'utils/functions.utils';
import { useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';
import { useSearchParams } from 'react-router-dom';
import '../../common_components/hoc/mobile_screen.scss';

function OtpVerify() {
  // router
  const navigate = useNavigate();

  // query
  const query = useQuery();
  let id: any = query.get('id');
  let phone: any = query.get('phone');
  const [searchParams, setSearchParams] = useSearchParams();

  // state
  const [state, setState] = useSetState({
    otp: '',
    counter: 120,
    errorArray: [],
    disabled: false,
    invalid_otp: false,
  });

  const handleChange = (value) => {
    setState({ otp: value.toString() });
  };

  // api call
  const otpVerify = async () => {
    setState({ disabled: true });
    Functions.notiflixLoader()
    try {
      const body = {
        id: id,
        otp: state.otp,
      };
      await Validation.otpVerifySchema.validate(body, { abortEarly: false });
      const res: any = await Models.auth.otpVerify(body);
      localStorage.setItem('token', res.token);
      localStorage.setItem('user_id', res.data._id);
      localStorage.setItem('role', res.data?.role);
      const postMessageData: any = {
        token: res.token,
        message: "user_token"
      }
      {/*// @ts-ignore */ }
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          token: res.token,
          message: "user_token"
        }),
      );
      searchParams.delete('id');
      searchParams.delete('phone');
      setSearchParams(searchParams);
      if (res.data.role === 'pleater') {
        window.location.pathname = '/orders';
      } else if (res.data.role === 'admin') {
        if(isMobileApp()){
          window.location.pathname = '/home';
        }else{
          window.location.pathname = '/dashboard';
        }
      } else {
        window.location.pathname = '/home';
      }
    } catch (error: any) {
      setState({ disabled: false });
      if (error === 'Invalid otp') {
        setState({ invalid_otp: true });
        setTimeout(() => {
          setState({ invalid_otp: false });
        }, 3000);
        Functions.notiflixFailure(error);
      }
      setState({
        error: error?.message,
        path: error?.path,
        errorArray: JSON.parse(JSON.stringify(error))?.inner,
      });
      setTimeout(() => {
        setState({ errorArray: '' });
      }, 3000);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const resendOtp = async () => {
    Functions.notiflixLoader()
    setState({ counter: 120 });
    try {
      const body = {
        phone: `+91${phone}`,
      };
      const res: any = await Models.auth.login(body);
    } catch (err) {
      console.log('err', err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // logic
  const numberSplit = () => {
    let number: any = phone;
    const numberSplit = number.split('').slice(-2).join('');
    return numberSplit;
  };

  // useEffect

  useEffect(() => {
    if (localStorage.token) {
      navigate('/home');
    }
    const timer: any =
      state.counter > 0 &&
      setInterval(() => setState({ counter: state.counter - 1 }), 1000);
    return () => clearInterval(timer);
  }, [state.counter]);

  return (
    <div className='mobile_screen'>
    <div className="otp_verify_container ">
      <div className="otp_verify_navButton" onClick={() => navigate('/login')}>
        <Navbutton className={'navButton_border'} icon={Assets.arrowIndicate} />
      </div>
      <div className="otp_verify_heading">Verification code!</div>
      <div className="otp_verify_content">
        We sent you a verification code to your mobile ********{numberSplit()}
      </div>
      <div
        className={
          state.invalid_otp
            ? 'otp_verify_input_field_error'
            : 'otp_verify_input_field'
        }>
        <OTPInput
          value={state.otp}
          onChange={handleChange}
          autoFocus
          OTPLength={4}
          otpType="number"
          name="otp"
        />
        <div className="otp_resend_wrapper">
          <div className="otp_resend_seconds">
            {state.counter <= 0 ? (
              <>
                <div onClick={() => resendOtp()} className="resend_button">
                  Resend
                </div>
              </>
            ) : (
              <>
                <div className="resend">Resend:</div>
                <div className="counter">{state.counter}s</div>
              </>
            )}
          </div>
        </div>
        <div className="otp_submit_button">
          {state.errorArray &&
            state.errorArray.map(
              (error: any) =>
                'otp' === error?.path && (
                  <div className="otp_verify_error_message">
                    {'otp' === error?.path && error.message}
                  </div>
                ),
            )}
          <PrimaryButton
            disabled={state.disabled}
            onClick={() => otpVerify()}
            text={'Submit'}
            style={{
              background:
                'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
              boxShadow: '-1px 4px 16px -4px rgba(156,49,43,1)',
            }}

          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default OtpVerify;
