import React from 'react';
import { isMobileApp } from 'utils/functions.utils';
import { Functions, Models, Navbutton, Assets } from 'utils/imports.utils';
import './header.component.scss';

interface IHeader {
  text?: String;
  title?: String;
  onPress?: any;
  onClick?: any;
  data?: any;
}

const Header = (props: IHeader) => {
  return (
    <div className="header_container" style={{paddingTop:isMobileApp()?40:10}}>
      <div className="header_back_button">
        {props.onClick? (
          <>
            <Navbutton icon={Assets.arrowIndicate} onClick={props.onClick} />
          </>
        ):null}
      </div>
      <div className="header_text">{props.title}</div>
    </div>
  );
};

export default Header;
