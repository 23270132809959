import React, { useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import {
  geocode,
  notiflixFailure,
  notiflixRemove,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Navbutton,
  Assets,
  PrimaryButton,
  Dropdown,
  Functions,
  GoogleMapsPicker,
  Models,
  TextArea,
  AsyncDropdown,
} from 'utils/imports.utils';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './admin_invite_modal.scss';
import _ from 'lodash';
import InputAdmin from '../input_admin/input_admin.ui';
import { GoogleMap, useJsApiLoader,Marker, Autocomplete } from '@react-google-maps/api';
import AdminModel from 'admin_panel/imports/models.admin_import';
import { COURIER_DELIVERY_TYPES, DELIVERY_VENDOR } from 'utils/constant.utils';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { SingleDatePicker } from '../../../../utils/imports.utils';
import moment from 'moment';

const AdminInviteModal = forwardRef((props: any, ref) => {
  // Reference
  const modalRef: any = useRef();
  const query = useQuery();
  const Map: any = useRef(/** @type google.maps.Map */ null);
  let paramsId: any = query.get('id');

  // State
  const [state, setState] = useSetState({
    isModalOpen: false,
    checkbox_data: [],
    reschedule_data: [],
    pleater_id: '',
    mapShow:false,
    map:null,
    center: {
      lat: 13.0827,
      lng: 80.2707,
    },
    location:'',
    locationContent:'',
    hoouseNo:'',
    apartmentName:'',
    mobile:'',
    addressId:'',
    autocomplete:null,
    userLocation:'',
    bookingData:'',
    contentFocus:false,
    notificationDetails:'',
    code:'',
    sareeQuantity:0,
    sareeLimit:0,
    max:0,
    offerValue:0,
    userMax:0,
    orderLimit:0,
    title:'',
    text:'',
    users:[],
    validFrom:null,
    validTo:null,
    repeat:false,
    search_user:'',
    dropdownData:[]
  });

  let latitude: any, longitude: any;

  const containerStyle = {
    width:'497px',
    height:'497px',
    borderRadius:'10px'
  };

  // Network req
  useImperativeHandle(ref, () => ({
    openModal() {
      setState({ isModalOpen: true });
    },
    closeModal() {
      setState({ isModalOpen: false });
    },
  }));

  let reschedule_data = [
    {
      value: 'Reschedule existing booking',
      label: 'Reschedule existing booking',
    },
    {
      value: 'Assign Pleater Randomly',
      label: 'Assign Pleater Randomly',
    },
    {
      value: 'Assign Pleater Manually',
      label: 'Assign Pleater Manually',
    },
  ];
  // handleCheckbox
  const handleCheckbox = (item: any, index: number) => {
    let data = state.checkbox_data;
    if (data.includes(item.value)) {
      data = [];
      setState({ checkbox_data: data });
    } else {
      data = [];
      data.push(item.value);
    }
    setState({ checkbox_data: data });
  };

  // reshedule_model
  const handleReschedule = () => {
    if (state.checkbox_data.includes('Reschedule existing booking')) {
      props.reschedule();
    } else if (state.checkbox_data.includes('Assign Pleater Manually')) {
      if (state.pleater_id.length > 0) {
        props.adminReschedule(state.pleater_id);
      } else {
        Functions.notiflixFailure('Select the pleater');
      }
    } else {
      props.adminReschedule();
    }
    setState({
      pleater_id: '',
      checkbox_data: [],
      isModalOpen: false,
      reschedule_data: [],
    });
  };

  // pleaterData
  if (props.type === 'reshedule_model') {
    var pleaterData = props.pleater?.map((item: any) => {
      return {
        value: item.name,
        label: item.name,
        id: item._id,
      };
    });
    pleaterData = pleaterData.filter((item) => item.id !== props.pleater_id);
  }

  // VENDOR DROPDOWN
  const vendorData = [
    {
      value: 'AUTO',
      label: 'AUTO',
    },
    {
      value: 'DUNZO',
      label: 'DUNZO',
    },
    {
      value: 'PORTER',
      label: 'PORTER',
    },
  ];

  const vendorDropDown = () => {
    if (_.has(state.booking_data, 'delivery_vendor')) {
      if (state.booking_data.delivery_vendor === DELIVERY_VENDOR.SHIPROCKET) {
        return [{
          value: 'SHIPROCKET',
          label: 'SHIPROCKET',
        }]
      }
      else return vendorData
    }
    else  return vendorData
  }

  // map
  const onUnmount = React.useCallback(function callback(map) {
    setState({map:null})
  }, [])

  const handleBoundsChanged = () => {
    const mapCenter = state.map.getCenter();
    const location = {
      lat:mapCenter.lat(),
      lng:mapCenter.lng()
    }
    setState({location})
  }

  const getAddressFromLatLong = async (lat, lng) => {
    const response: any = await geocode(lat, lng);
    setState({ locationContent: response });
  };

  const onDragEnd = () => {
    getAddressFromLatLong(state.location.lat, state.location.lng);
  };

  const handlePlace = () => {
    if (state.autocomplete !== null) {
      const location = {
        lat: state.autocomplete.getPlace().geometry.location.lat(),
        lng: state.autocomplete.getPlace().geometry.location.lng(),
      };
      setState({ location ,center:location});
      getAddressFromLatLong(location.lat,location.lng)
    } else {
      Functions.notiflixFailure('Autocomplete is not loaded yet!');
    }
  };

  const onLoadAutocomplete = (autocomplete:any)=>{
   setState({autocomplete})
  }

  const updateUserAddress = async () => {
    try {
      if (!state.houseNo.length) {
        Functions.notiflixFailure('Please enter house no / floor');
      } else if (!state.apartmentName.length) {
        Functions.notiflixFailure('Please enter street / apartment name');
      } else if (!state.mobile.length) {
        Functions.notiflixFailure('Please enter mobile number');
      } else {
        const body = {
          phone_number: state.mobile,
          apartment: state.apartmentName,
          house_number: state.houseNo,
          map_address: state.locationContent,
          location: {
            location_type: 'point',
            coordinates: [state.location.lat, state.location.lng],
          },
        };
        await Models.address.editAddress(state.addressId, body);
        if(props.userId) await Models.orders.editNotes({booking_id:paramsId,user_address:body})
        window.location.reload();
      }
    } catch (err: any) {
      console.log('err', err);
    }
  };

  const getUserDetails = async () => {
    try {
      const body = {
        user_id: props.userId || paramsId,
      };
      const res: any = await AdminModel.users.getUser(body);
      setState({
        mobile: res.data.address[0]?.phone_number,
        apartmentName: res.data?.address[0]?.apartment,
        houseNo: res.data?.address[0]?.house_number,
        addressId:res.data.address[0]?._id,
        userLocation: res.data?.address[0]?.location.coordinates
      });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };
  
  const userAddress  = () => {
     if(state.userLocation) {
      let obj: any = {
        lat: state.userLocation[0],
        lng: state.userLocation[1],
      };
      setState({ center: obj, location: obj });
      getAddressFromLatLong(state.userLocation[0],state.userLocation[1])
     }
  }

  const getBooking = async () => {
    try {
      const body = {
        booking_id: paramsId,
      };
      const res: any = await AdminModel.bookings.getBookingID(body)
      setState({ booking_data: res.data })
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  }

  const clearStates = () => {
    setState({
      code: '',
      sareeQuantity: 0,
      max: 0,
      offerValue: 0,
      userMax: 0,
      orderLimit: 0,
      sareeLimit:0,
      title: '',
      text: '',
      users: [],
      validFrom: null,
      validTo: null,
      repeat: false,
      isModalOpen:false,
      dropDownData:[]
    })
  }

  // handle coupon users
  const handleCouponUsers = (user: any) => {
    let data: any = []
    let dropdownData: any = []
    user.forEach((item: any) => {
      if (item?.value) data.push(item?.value)

    })
    setState({ users: data })
    user.forEach((element: any) => {
      dropdownData.push({
        label: element.label,
        value: element.value
      })
    })
    setState({ dropdownData })
  };

  useEffect(() => { 
    if (state.mapShow) userAddress()
  }, [state.mapShow]);

  useEffect(()=>{
    if(props.type === 'changeAddress') getUserDetails()
    if(paramsId) getBooking()
  },[])

  useEffect(()=>{
  vendorDropDown()
  },[state.booking_data])
  // formatted time
  const timeFormatted = (time) => {
    const parsedTime = moment(time, "HH:mm");
    const formattedTime = parsedTime.format("h:mm A");
    return formattedTime
  }
  useEffect(() => {
    if (props.type === 'coupon_modal' && props.couponDetails) {
      let data = props.couponDetails
      setState({
        code: data.code,
        sareeQuantity: data.saree_quantity,
        max: data.max,
        offerValue: data.offer_value,
        sareeLimit:data.saree_limit,
        userMax: data.user_max ? data.user_max : 0,
        orderLimit: data.order_limit,
        title: data.title,
        text: data.text,
        validFrom: data.valid_from,
        validTo: data.valid_to,
        repeat: data.repeat ? true : false,
      })
      if(!_.isEmpty(data.users)) {
        let usersList:any = []
        data.users.forEach((item:any)=> {
          usersList.push({
            label: `${item.phone.slice(3).trim()} ${item.name ? '-' : ''} ${
              item.name || ''
            }`,
            value:item._id
          })
        })
        setState({dropdownData:usersList})
      }
    }
  }, [props.couponDetails])

  return (
    <div className="Model">
      {props.type === 'payout_model' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'payout_model_wrapper',
          }}>
          <div className="invite_container">
            <div className="invite_wrapper">
              <div className="invite_header">
                <div className="invite_heading" style={{ fontSize: '18px' }}>
                  Payout Confirmation
                </div>
                <Navbutton
                  icon={Assets.black_close}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                  style={{ height: '30px', width: '30px' }}
                />
              </div>
              <div className="invite_subheading">
                {`do you like transfer this ${props.modalData?.amount} to
                ${props.modalData?.pleater_name} pleater`}
              </div>

              <div className="invite_send_button">
                <PrimaryButton
                  text={'Cancel'}
                  className={'send_invite_button'}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                  width={'20%'}
                />
                <PrimaryButton
                  text={'Ok'}
                  className={'send_invite_button'}
                  onClick={() => props.onClick()}
                  width={'20%'}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {props.type === 'user_model' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal',
          }}>
          <div className="invite_container">
            <div className="invite_wrapper">
              <div className="invite_header">
                <div className="invite_heading" style={{ fontSize: '18px' }}>
                  Are you sure
                </div>
                <Navbutton
                  icon={Assets.black_close}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                  style={{ height: '30px', width: '30px' }}
                />
              </div>
              <div className="invite_subheading"></div>

              <div className="invite_send_button">
                <PrimaryButton
                  text={'Cancel'}
                  className={'send_invite_button'}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                  width={'20%'}
                />
                <PrimaryButton
                  text={'Ok'}
                  className={'send_invite_button'}
                  onClick={() => props.onClick()}
                  width={'20%'}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {props.type === 'reshedule_model' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ checkbox_data: [] });

            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'reschedule_wrapper',
          }}>
          <div className="invite_container">
            <div className="invite_wrapper">
              <div className="invite_header">
                <div className="invite_heading">Reschedule Order</div>
                <Navbutton
                  icon={Assets.black_close}
                  onClick={() => {
                    setState({ checkbox_data: [] });

                    setState({ isModalOpen: false });
                  }}
                  style={{
                    height: '40px',
                    width: '40px',
                    background: '#FAFAFA',
                    color: '#FAFAFA',
                    border: 'none',
                    borderRadius: '26px',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className="invite_subheading  ">
                {reschedule_data.map((item: any, index: number) => (
                  <div
                    className="reschedule_button_warpper"
                    onClick={() => {
                      handleCheckbox(item, index);
                    }}
                    key={index}>
                    <img
                      src={
                        state.checkbox_data.includes(item.value)
                          ? Assets.admin_radio_active
                          : Assets.admin_radio_inactive
                      }
                      alt=""
                    />
                    <div className="reschedule_content">{item.label}</div>
                  </div>
                ))}
                {state.checkbox_data[0] === 'Assign Pleater Manually' && (
                  <div className="pleater_manually">
                    <div className="pleater_title">Pleater</div>
                    <Dropdown
                      width={'100%'}
                      borderRadius={'5px'}
                      background={'#ffffff'}
                      border={'#E4E4E4 1px solid'}
                      data={pleaterData}
                      height={'auto'}
                      onChange={(value: any) =>
                        setState({ pleater_id: value.id })
                      }
                    />
                  </div>
                )}
                {state.checkbox_data[0] && (
                  <div
                    style={{ marginTop: '20px' }}
                    className="pleater_manually">
                    <div className="pleater_title">Vendor</div>
                    <Dropdown
                      width={'100%'}
                      borderRadius={'5px'}
                      background={'#ffffff'}
                      border={'#E4E4E4 1px solid'}
                      data={state.booking_data && vendorDropDown()}
                      height={'auto'}
                      onChange={(value: any) => props.vendor(value)}
                    />
                  </div>
                )}
              </div>

              <div className="invite_send_button">
                {_.isEmpty(state.checkbox_data) ? (
                  <PrimaryButton
                    text={'Reschedule'}
                    className={'send_invite_button'}
                    disabled
                    width={'30%'}
                    fontSize={'14px'}
                    fontWeight={'700'}
                  />
                ) : (
                  <PrimaryButton
                    text={'Reschedule'}
                    className={'send_invite_button'}
                    onClick={handleReschedule}
                    width={'30%'}
                    fontSize={'14px'}
                    fontWeight={'700'}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {props.type === 'cancel_booking' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'payout_model_wrapper',
          }}>
          <div className="invite_container">
            <div className="invite_wrapper">
              <div className="invite_header">
                <div className="invite_heading" style={{ fontSize: '18px' }}>
                  Booking Cancel
                </div>
                <Navbutton
                  icon={Assets.black_close}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                  style={{ height: '30px', width: '30px' }}
                />
              </div>
              <div className="invite_subheading">
                Are you sure cancel this booking
              </div>

              <div
                style={{ paddingTop: '15px' }}
                className="invite_send_button">
                <PrimaryButton
                  color={'#000000'}
                  backgroundColor={'#E4E4E4'}
                  text={'Cancel'}
                  className={'send_invite_button'}
                  onClick={() => {
                    setState({ isModalOpen: false });
                  }}
                  width={'20%'}
                />
                <PrimaryButton
                  text={'Ok'}
                  className={'send_invite_button'}
                  onClick={() => props.onClick()}
                  width={'20%'}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {props.type === 'changeAddress' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false, mapShow: false,locationContent:'' ,location:''});
          }}
          closeOnOverlayClick
          center
          showCloseIcon={true}
          classNames={{
            overlay: 'customOverlay',
            modal: 'reschedule_wrapper',
          }}>
          <div className={`${!state.mapShow ? 'user_address_wrapper' : ''}`}>
            {!state.mapShow ? (
              <>
                <div className="user_address_heading">Change user address</div>
                <div className="user_address_field_wrapper">
                  <div className="input_field_label">House No & Floor *</div>
                  <div className="input_wrapper">
                    <InputAdmin
                      name="name"
                      type="text"
                      value={state.houseNo}
                      onChange={(houseNo: any) => setState({ houseNo })}
                    />
                  </div>
                </div>
                <div className="user_address_field_wrapper">
                  <div className="input_field_label">
                    Street / Apartment Name *
                  </div>
                  <div className="input_wrapper">
                    <InputAdmin
                      name="nam"
                      type="text"
                      value={state.apartmentName || ''}
                      onChange={(apartmentName: any) =>
                        setState({ apartmentName })
                      }
                    />
                  </div>
                </div>
                <div className="user_address_field_wrapper">
                  <div className="input_field_label">Phone Number *</div>
                  <div className="input_wrapper">
                    <InputAdmin
                      name="phone"
                      type="text"
                      value={state.mobile}
                      onChange={(mobile: any) => setState({ mobile })}
                    />
                  </div>
                </div>
                {state.locationContent.length > 0 && (
                  <div className="location_content_container">
                    <div className="location_content_heading">Location :</div>
                    <div className="location_content_text">
                      {state.locationContent}
                    </div>
                  </div>
                )}
                {state.location && (
                  <div className="location_content_container">
                    <div className="latlng_heading">Lat & Lng :</div>
                    <div className="location_content_text">{`${state.location.lat} , ${state.location.lng}`}</div>
                  </div>
                )}
                <div
                  onClick={() => setState({ mapShow: !state.mapShow })}
                  className="user_address_map_wrapper">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={Assets.location} width={22} height={22} />
                  </div>
                  <div className="user_addres_map_location_content">
                    Pick your location
                  </div>
                </div>
                <div className="user_address_button_wrapper">
                  <PrimaryButton
                    onClick={() => updateUserAddress()}
                    text={'Save'}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}>
                  {/* {isLoaded ? ( */}
                    <>
                      <GoogleMap
                        ref={Map}
                        mapContainerStyle={containerStyle}
                        center={state.center}
                        zoom={14}
                        onLoad={(map: any) => setState({ map })}
                        onDragEnd={onDragEnd}
                        onBoundsChanged={handleBoundsChanged}
                        options={{
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                        onUnmount={onUnmount}>
                        <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={handlePlace}>
                          <input
                            type="text"
                            placeholder="Search your places..."
                            style={{
                              marginTop:'10px',
                              boxSizing: `border-box`,
                              border: `1px solid transparent`,
                              width: `240px`,
                              height: `32px`,
                              padding: `0 12px`,
                              borderRadius: `3px`,
                              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                              fontSize: `14px`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                              position: 'absolute',
                              left: '50%',
                              marginLeft: '-120px',
                            }}
                          />
                        </Autocomplete>
                        <Marker
                          position={state.location}
                          animation={google.maps.Animation.DROP}
                        />
                      </GoogleMap>
                      <div className="location_content_wrapper">
                        {state.locationContent && (
                          <div className="location_content_text">
                            {state.locationContent}
                          </div>
                        )}
                      </div>
                      {state.locationContent && (
                        <div
                          style={{
                            padding: '0 10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}>
                          <PrimaryButton
                            onClick={() => {
                              setState({ mapShow: false });
                            }}
                            style={{ height: '45px' }}
                            text={'Next'}
                          />
                        </div>
                      )}
                    </>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
      {props.type === 'sendNotification' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            props.onCancel()
            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'notification_modal_wrapper',
          }}>
          <div className='notification_heading_content'>
            Send Notification
          </div>
          <div className='notification_send_to_wrapper'>
            {!props.paramsId && (
              <>
                <div className='notification_sendTo_heading'>Send to</div>
                <Dropdown
                  width={'312px'}
                  border={'1.5px solid#E4E4E4'}
                  borderRadius={'10px'}
                  background={'#ffff'}
                  color={'#11142D'}
                  data={props.notification_data}
                  value={props.sendTo}
                  onChange={(search: any) => {
                    props.sendToOnChange(search.value)
                  }}
                />
              </>
            )}
            <div className='notification_send_to_title_heading'>
              Title
            </div>
            <div className='notification_send_to_title'>
              <InputAdmin
                className='notification_send_to_title_input'
                type={'text'}
                name={'Title'}
                value={props.title}
                onChange={(data: any) => props.titleOnChange(data)}
                placeholder={'Enter your title here...'}
              />
            </div>
            <div className='notification_send_to_content_heading'>
              Message
            </div>
            <textarea
              onChange={(e) => props.messageOnChange(e.target.value)}
              value={props.message}
              onBlur={() => setState({ contentFocus: false })}
              onFocus={() => setState({ contentFocus: true })}
              className={`notification_send_text_area ${state.contentFocus ? 'notification_textarea_focus' : null}`} />
            <div className='notification_send_to_button_wrapper'>
              <div className='notification_send_to_button_flex_wrapper'>
                <div className='notification_button_width'>
                  <PrimaryButton
                    onClick={() => props.onCancel()}
                    color={'#768089'} text={'Cancel'}
                    backgroundColor='#F2F2F2' />
                </div>
                <div className='notification_button_width'>
                  <PrimaryButton
                    onClick={() => props.onSend()}
                    color={'#FFFFFF'} text={'Send'}
                    backgroundColor='#A1342D' />
                </div>
              </div>
            </div>
          </div>

        </Modal>
      )}
      {props.type === 'scheduleNotification' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            props.onCancel()
            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'notification_modal_schedule_wrapper',
          }}>
          <div className='notification_schedule_heading_content'>
            Schedule Notification
          </div>
          <div className='notification_schedule_days_heading'>
            Schedule On
          </div>
          <div className='notification_schedule_days_wrapper'>
            {props.days.map((item: any, i: number) => {
              return (
                <div key={i}
                  className={`${i !== 0 ? props.daysArray.includes('*') ?
                    'disable_days_wrapper' :
                    null : null}
                   notification_schedule_days_flex_wrapper`}>
                  <div onClick={() => {
                    i === 0 ?
                      props.daysOnChange(item.value) :
                      props.daysArray.includes('*') ? null :
                        props.daysOnChange(item.value)
                  }}
                    style={{
                      width: 24,
                      height: 24,
                      cursor: 'pointer'
                    }}>
                    <img src={props.daysArray.includes(item.value) ?
                      Assets.notification_admin_checkbox_active :
                      Assets.notification_admin_checkbox_disable}
                      width={24}
                      height={24} />
                  </div>
                  <div className='notification_schedule_days_content'>
                    {item.label}
                  </div>
                </div>
              )
            })}
          </div>
          {/* time wrapper */}
          <div className='notification_schedule_time_wrapper'>
            <div className='notification_time_picker_wrapper'>
              <div className='notification_time_picker_heading'>
                Schedule Time
              </div>
              <div className='notification_time_picker'>
                <TimePicker autoFocus={false} format="h:mm a" onChange={(value: any) => props.timeOnChange(value)} value={props.timeValue} />
              </div>
            </div>
            <div>
              {!props.paramsId && (
                <div className='notification_time_picker_wrapper'>
                  <div className='notification_time_picker_heading'>
                    Send to
                  </div>
                  <div className='notification_time_picker'>
                    <Dropdown
                      width={'215px'}
                      border={'1.5px solid#E4E4E4'}
                      borderRadius={'10px'}
                      background={'#ffff'}
                      color={'#11142D'}
                      data={props.notification_data}
                      value={props.sendTo}
                      onChange={(search: any) => {
                        props.sendToOnChange(search.value)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* title */}
          <div className='notification_schedule_title_heading'>
            Title
          </div>
          <div className='notification_schedule_title_wrapper'>
            <InputAdmin
              className='notification_send_to_title_input'
              type={'text'}
              name={'Title'}
              value={props.title}
              onChange={(data: any) => props.titleOnChange(data)}
              placeholder={'Enter your title here...'}
            />
          </div>
          <div className='notification_schedule_textarea_wrapper'>
            <div className='notification_schedule_textarea_heading'>Message</div>
            <textarea
              onChange={(e) => props.messageOnChange(e.target.value)}
              value={props.message}
              onBlur={() => setState({ contentFocus: false })}
              onFocus={() => setState({ contentFocus: true })}
              className={`notification_send_text_area ${state.contentFocus ? 'notification_textarea_focus' : null}`} />
          </div>
          <div className='notification_schedule_button_wrapper'>
            <div className='notification_schedule_button_flex_wrapper'>
              <div className='notification_schedule_button_width'>
                <PrimaryButton onClick={()=> props.onCancel()} color={'#768089'} text={'Cancel'} backgroundColor={'#F2F2F2'}/>
              </div>
              <div className='notification_schedule_button_width'>
                <PrimaryButton onClick={()=> props.onSend()} color={'#FFFFFF'} text={'Send'} backgroundColor={'#A1342D'}/>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {props.type === 'admin_notification_details' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false });
          }}
          closeOnOverlayClick
          center
          showCloseIcon={true}
          classNames={{
            overlay: 'customOverlay',
            modal: 'admin_notification_detail',
          }}>
          <div className='notification_details_heading'>
            Notification details
          </div>
            <div className='notification_details_send_wrapper'>
              <div className='notification_details_sendTo_flex_wrapper'>
                <div className="notification_details_sendTo_heading">
                  Sent to
                </div>
                <div className='notification_details_sendTo_content'>
                  {props.notificationDetails ? props.notificationDetails?.status : '---'}
                </div>
              </div>
              {props.notificationDetails?.time && (
                <div className='notification_details_sendTo_flex_wrapper'>
                  <div className="notification_details_sendTo_heading">
                    Time
                  </div>
                  <div className='notification_details_sendTo_content'>
                    {timeFormatted(props.notificationDetails.time)}
                  </div>
                </div>
              )}
            </div>
          {props.notificationDetails?.day && (
            <>
              <div className='notification_details_tile_heading'>
                Days
              </div>
              <div className='notification_details_tile_content'>
                {props.notificationDetails?.day}
              </div>
            </>
          )}
          <div className='notification_details_tile_heading'>
             Title
          </div>
          <div className='notification_details_tile_content'>
             {props.notificationDetails?.title}
          </div>
          <div className='notification_details_content_heading'>
             Message
          </div>
          <div className='notification_details_content'>
          {props.notificationDetails?.content}
          </div>
        </Modal>
      )}
       {props.type === 'coupon_modal' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false});
            clearStates()
          }}
          closeOnOverlayClick
          center
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay',
            modal: 'coupon_modal_wrapper',
          }}>

          <div className='coupon_modal_heading'>
            {props.editCoupon ? 'Edit Coupon' : 'Create Coupon'}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '24px' }}>
            <div className='coupon_modal_field_wrapper'>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Coupon Code *</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.code}
                    placeholder='Enter your code'
                    type='text'
                    name="code"
                    onChange={(value: string) => setState({ code: value })} />
                </div>
              </div>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Eligible Saree Count *</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.sareeQuantity}
                    placeholder='Enter eligible saree count'
                    type='number'
                    name="saree_quantity"
                    onChange={(value: string) => setState({ sareeQuantity: value })} />
                </div>
              </div>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Maximum Num Of Sarees *</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.sareeLimit}
                    placeholder='Enter maximum num of sarees'
                    type='number'
                    name="saree_limit"
                    onChange={(value: string) => setState({ sareeLimit: value })} />
                </div>
              </div>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Coupon Maximum Limit *</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.max}
                    placeholder='Enter Coupon maximum limit'
                    type='number'
                    name="max"
                    onChange={(value: string) => setState({ max: value })} />
                </div>
              </div>
              {state.repeat && (
                <div className='coupon_modal_input_field_wrapper'>
                  <div className='coupon_modal_input_heading'>Usage Limit Per User *</div>
                  <div className='coupon_modal_input_field'>
                    <InputAdmin value={state.userMax}
                      placeholder='Enter usage limit per user'
                      type='number'
                      name="user_max"
                      onChange={(value: string) => setState({ userMax: value })} />
                  </div>
                </div>
              )}
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Description *</div>
                <div className='coupon_modal_input_field'>
                  <TextArea value={state.text}
                    placeholder='Type here'
                    type='text'
                    name="text"
                    onChange={(e:any) => setState({ text: e.target.value })} />
                </div>
              </div>
            </div>
            <div className='coupon_modal_field_wrapper_two'>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Total Order Limit *</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.orderLimit}
                    placeholder='Enter total order limit'
                    type='number'
                    name="order_limit"
                    onChange={(value: string) => setState({ orderLimit: value })} />
                </div>
              </div>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>Coupon Title *</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.title}
                    placeholder='Enter coupon title'
                    type='text'
                    name="title"
                    onChange={(value: string) => setState({ title: value })} />
                </div>
              </div>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>{`Discount Percentage(%) *`}</div>
                <div className='coupon_modal_input_field'>
                  <InputAdmin value={state.offerValue}
                    placeholder='Enter discount value'
                    type='number'
                    name="offer_value"
                    onChange={(value: string) => setState({ offerValue: value })} />
                </div>
              </div>
              <div className='coupon_modal_repeat_field_wrapper'>
                <div onClick={() => setState({ repeat: !state.repeat })} className='coupon_modal_checkbox'>
                  <img src={state.repeat ? Assets.coupon_modal_active_checkbox : Assets.coupon_modal_disable_checkbox} width={24} height={24} />
                </div>
                <div className='coupon_modal_repeat_content'>
                  Repeat
                </div>
              </div>
              <div className='coupon_modal_input_field_wrapper'>
                <div className='coupon_modal_input_heading'>User</div>
                <div className='coupon_modal_input_field'>
                  <AsyncDropdown
                    loadOptions={props.loadOptions}
                    data={props.userData}
                    value={state.dropdownData}
                    onChange={(user: any) => {
                      handleCouponUsers(user)
                    }
                    }
                    width={'350px'}
                    borderRadius={'8px'}
                    placeholder={'Type user name here'}
                  />
                </div>
              </div>
              <div className='coupon_modal_date_wrapper'>
                <div className='coupon_modal_date_field_wrapper'>
                  <div className='coupon_modal_date_field_heading'>
                    Valid From *
                  </div>
                  <div className='coupon_modal_date_field'>
                    <SingleDatePicker
                      onFocusPress={() => setState({ validFrom: null })}
                      iconOnPress={() => setState({ validFrom: null })}
                      value={state.validFrom}
                      placeholder='Valid from'
                      onChange={(value: string) => setState({ validFrom: value })} />
                  </div>
                </div>
                <div className='coupon_modal_date_field_wrapper'>
                  <div className='coupon_modal_date_field_heading'>
                    Valid To *
                  </div>
                  <div className='coupon_modal_date_field'>
                    <SingleDatePicker
                      startDate={state.validFrom}
                      validateDate={true}
                      onFocusPress={() => setState({ validTo: null })}
                      iconOnPress={() => setState({ validTo: null })}
                      value={state.validTo}
                      placeholder='Valid to'
                      onChange={(value: string) => setState({ validTo: value })} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`coupon_modal_button_wrapper`}>
            <div className='coupon_modal_button_flex_wrapper'>
              <PrimaryButton onClick={()=>clearStates()} width={'165px'} text={'Cancel'} color={'#768089'} backgroundColor='#F2F2F2' />
              <PrimaryButton onClick={() => props.createCoupon({
                code: state.code,
                saree_quantity: state.sareeQuantity,
                max: state.max,
                offer_value: state.offerValue,
                user_max: state.userMax,
                order_limit: state.orderLimit,
                saree_limit:state.sareeLimit,
                title: state.title,
                users: state.users,
                text: state.text,
                valid_from: state.validFrom,
                valid_to: state.validTo,
                repeat: state.repeat
              })}
                width={'165px'}
                text={props.editCoupon ? 'Edit' : 'Create'}
                color={'#ffff'}
                backgroundColor={'#A1342D'} />
            </div>
          </div>
        </Modal>
      )}
       {props.type === 'coupon_details' && (
        <Modal
          open={state.isModalOpen}
          ref={modalRef}
          onClose={() => {
            setState({ isModalOpen: false});
            clearStates()
          }}
          closeOnOverlayClick
          center
          showCloseIcon={true}
          classNames={{
            overlay: 'customOverlay',
            modal: 'coupon_details_wrapper',
          }}>

          <div className='coupon_details_heading'>
            Coupon Details
          </div>
          <div className='coupon_details_flex_wrapper'>
            <div className='coupon_details_element_wrapper'>
              <div className='coupon_details_field_space'>
                <div className='coupon_details_field_content'>
                  Coupon Code
                </div>
                <div className='coupon_details_field_text'>
                  {props.couponDetails?.code || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                Coupon Maximum Limit 
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.max || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                  Usage Limit Per User
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.user_max || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                Maximum Num Of Sarees
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.saree_limit || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                  Coupon Title
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.title || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                  Description
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.text || '---'}
                </div>
              </div>
            </div>
            <div className='coupon_details_element_wrapper'>
              <div>
                <div className='coupon_details_field_content'>
                Eligible Saree Count
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.saree_quantity || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                  {`Discount Percentage(%)`}
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.offer_value || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                Total Order Limit
                </div>
                <div className='coupon_details_field_text'>
                {props.couponDetails?.order_limit || '---'}
                </div>
              </div>
              <div>
                <div className='coupon_details_field_content'>
                  Repeat
                </div>
                <div className='coupon_details_field_text'>
                  {props.couponDetails.repeat ? 'True' : 'False'}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' ,gap:'30px'}}>
                <div>
                  <div className='coupon_details_field_content'>
                    Valid From
                  </div>
                  <div className='coupon_details_field_text'>
                   {moment(props.couponDetails?.valid_from).format('DD/MM/YYYY')}
                  </div>
                </div>
                <div>
                  <div className='coupon_details_field_content'>
                    Valid To
                  </div>
                  <div className='coupon_details_field_text'>
                  {moment(props.couponDetails?.valid_to).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Modal>
      )}
    </div>
  );
});

export default AdminInviteModal;
