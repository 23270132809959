import React, { useEffect } from 'react';
import './orders.screen.scss';
import { useSetState } from 'utils/functions.utils';
import { useNavigate } from 'react-router-dom';
import {
  PrimaryButton,
  Models,
  Footer,
  Tabs,
  Constants,
  OrdersCardComponent,
  StepperComponent,
  CurrentOrderComponent,
  Header,
  Functions,
} from '../../utils/imports.utils';
import { storeActiveTab, storeActiveTabData } from '../../utils/redux.utils';
import { useSelector } from 'react-redux';
import { COURIER_DELIVERY_TYPES } from 'utils/constant.utils';

function Orders() {
  // tab
  const tabData = ['Current', 'History'];

  // redux

  const order = useSelector((state: any) => state.order);

  // state
  const [state, setState] = useSetState({
    tabs_active: '',
    data: [],
    pathname: '',
    address_data: '',
    user_data: '',
    tab_data: '',
    tab_active: 'Current',
  });

  // route
  const navigate = useNavigate();
  const activeTab = async (value) => {
    storeActiveTab(value);
    Functions.notiflixLoader();
    let body: any = {};
    let response: any;
    try {
      switch (value) {
        case 'Current':
          body.search = '';
          response = await Models.orders.getManyOrders(body);
          let filterData = response.data.filter((element: any) => {
            return element.status !== 'DELIVERED';
          });
          let sortedData = filterData.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
          setState({ data: sortedData });
          storeActiveTabData(sortedData);
          break;
        case 'History':
          body.search = 'DELIVERED';
          response = await Models.orders.getManyOrders(body);
          let sort = response.data.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
          storeActiveTabData(sort);
          setState({ data: sort });
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getUser = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      setState({ user_data: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // hooks
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <div className="order_screen_header_wrapper">
        <Header data={state.user_data.role} title={'Orders'} />
      </div>
      <div className="order_screen_wrapper">
        <div className="pleater_buttons_wrap">
          <Tabs
            onChange={order.active_tab}
            selected={(value: any) => activeTab(value)}
            data={tabData}
          />
        </div>
        {state.data.length > 0 ? (
          <>
            {}
            {state.data.map((item: any, index: number) => {
              return (
                <div style={{marginTop:`${index === 0 ? '0px':'40px'}`}} key={index} className="orders_card_wrap">
                  <OrdersCardComponent
                    expressDelivery={item.delivery_mode ? item.delivery_mode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY ? true : false : false}
                    data={item.pickup_sarees}
                    status={true}
                    quantity={item.quantity}
                    address={item.user_address}
                    totalAmount={item.amount}
                    payType={item.payment_type}
                    orderId={item.id}
                    _id={item._id}
                    orderStatus={item.status}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <>
            <OrdersCardComponent status={false} />
          </>
        )}
      </div>
      <div className="details_footer_container">
        <Footer />
      </div>
    </div>
  );
}

export default Orders;
