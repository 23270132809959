import instance from 'utils/axios.utils';
import { v2Route } from 'utils/axios.utils';

const orders = {
  createOrders: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/create_booking';
      v2Route()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  storeOrders: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/store_booking';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
 
  getManyOrders: (data?) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/get_many_booking';
      v2Route()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getOrderById: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/get_booking';
      v2Route()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editOrder: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/delivery_saree';
      v2Route()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editNotes: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/edit_booking';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  findAvailablePleater: (body?:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/available_pleaters';
      v2Route()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  getTrackStatus: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/get_track_status';
      instance()
        .post(url,data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getQuote: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/get_quote';
      v2Route()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  courierQuote: (body?:any) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'booking/shiprocket/check_courier_serviceability';
      v2Route()
        .post(url,body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default orders;
