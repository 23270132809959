import React, { useEffect } from 'react';
import { Assets, Functions, Models } from 'utils/imports.utils';
import './footer.component.scss';
import { useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';
import { isIos, useSetState } from 'utils/functions.utils';
import { useSelector } from 'react-redux';

interface IFooter {
  text?: String;
}

const Footer = (props: IFooter) => {
  // redux
  const userData = useSelector((state: any) => state.user);
  // route

  const navigate = useNavigate();
  // api call

  // state

  const [state, setState] = useSetState({
    data: '',
    pathname: '',
    footer_show:false
  });

  const getUSer = async () => {
   // Functions.notiflixLoader();
    try {
      const res: any = await Models.auth.getUser();
      setState({ data: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  useEffect(() => {
    setState({ pathname: window.location.pathname.split('/')[1] });
    if (localStorage.token) {
      getUSer();
      setState({ footer_show: true });
    }
  }, [state.pathname]);

  return (
    <div
      className="footer_container"
      style={{ paddingBottom: isIos() ? 30 : 10 }}>
      {state.footer_show ? (
        <>
          {userData.user.role !== 'pleater' && (
            <div className="footer_home_icon" onClick={() => navigate('/home')}>
              <img
                src={
                  state.pathname === 'home'
                    ? Assets.home_active
                    : Assets.home_inactive
                }
              />
            </div>
          )}

          <div
            onClick={() => navigate('/orders')}
            className="footer_orders_icon">
            <img
              src={
                state.pathname === 'orders' ||
                state.pathname === 'order_details'
                  ? Assets.order_active
                  : Assets.orders_inactive
              }
            />
          </div>
          {userData.user.role === 'pleater' && (
            <>
              {/*<div
          onClick={() => navigate('/address')}
          className="footer_orders_icon">
          <img src={Assets.address_pin} />
        </div> */}
              <div
                onClick={() => navigate('/payment')}
                className="footer_orders_icon">
                <img
                  src={
                    state.pathname === 'payment'
                      ? Assets.payment_active
                      : Assets.payments
                  }
                />
              </div>
            </>
          )}

          <div
            onClick={() => navigate('/notification')}
            className="footer_notification_icon">
            <img
              src={
                state.pathname === 'notification'
                  ? Assets.notification_active
                  : Assets.notification_inactive
              }
            />
          </div>
          <div
            onClick={() => navigate('/account')}
            className="footer_list_icon">
            <img
              src={
                state.pathname === 'account' ||
                state.pathname === 'privacy' ||
                // state.pathname === 'payment' ||
                state.pathname === 'terms'
                  ? Assets.account_active
                  : Assets.list_inactive
              }
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Footer;
