import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import Assets from 'imports/assets.import';
import './checkbox.ui.scss';

interface ICheckBoxProps {
  onPress?: any;
  checked?: boolean;
  selected?: any;
  multiple?: boolean;
  active?: any;
  disabled?: any;
  image?:boolean
}

const CheckBox = (props: ICheckBoxProps) => {
  return (
    <div>
      <div className="checked_icon_wrapper">
        {props.checked ? (
          <div className={props.image?"checked":""}>
          <img
            src={props.active}
            height={14}
            width={14}
            className="checked-icon"
            alt=""
          />
           </div>
        ) : (
          <div className={props.image?"unchecked":""}>

          <img
            src={props.disabled}
            height={14}
            width={14}
            className="checked-icon"
            alt=""
          />
          </div>
        )}
      </div>
      {/* // ) : (
      //   <div className="checkbox"></div>
      // )} */}
    </div>
  );
};

export default CheckBox;
