import React, { useEffect } from 'react';
import { useSetState,capitalizeFirstLetter } from 'utils/functions.utils';
import './order_status.component.scss';
import {
  Assets,
  PrimaryButton,
  SwiperComponent,
  StepperComponent,
} from '../../utils/imports.utils';

interface OrderStatusProps {
  requestId?: any;
  quantity?: any;
  status?: any;
  data?: any;
  className?: any;
  expressDelivery?: boolean
}

const OrderStatusComponent = (props: OrderStatusProps) => {
  // logic
  const params = {
    spaceBetween: 30,
    centeredSlides: true,
    // autoplay: {
    //   delay: 2500,
    //   disableOnInteraction: false,
    // },
    // pagination: {
    //   el:'.swiper-pagination',
    //   clickable: true,
    //   renderBullet: (index, className) => {
    //     return '<span class="' + className + '"></span>';
    //   },
    // },
    noSwiping:true 
  };

  return (
    <div className="order_card_status">
      <div className="order_card_status_container">
        {props.expressDelivery && (
          <div className='express_delivery_badge_container'>
            <div>
              <img src={Assets.express_courier} width={10} height={10} />
            </div>
            <div className='express_courier_badge_content'>
              EXPRESS DELIVERY
            </div>
          </div>
        )}
        <div className="swiper_wrap">
          <SwiperComponent params={params}>
            {props.data.map((item: any, index: number) => {
              // return item.images.map((images: any, index: number) => {
              return (
                <div key={index}>
                  <img src={item.images[0]} className={props.className} />
                </div>
              );
              // });
            })}
            {/* <img src={Assets.sareeImage} className='saree_image' />
                    <img src={Assets.sample_work} className='saree_image' /> */}
          </SwiperComponent>
        </div>
        <div className="address_wrap">
          <div className="request-id_quantity_wrap">
            <div className="request-id_wrap">{props.requestId}</div>
            <div className="quantity_wrap">Quantity : {props.quantity}</div>
          </div>
          <div className="order_status_wrapper">
            <div className="order_status_text">Status</div>
            <div
              className={
                props.status === 'CANCELLED' ||
                props.status === 'ORDER CANCELLED'
                  ? 'order_status_failed'
                  : 'order_status_status'
              }>
              {' '}
              {capitalizeFirstLetter(
                props.status === 'ORDER CANCELLED'
                  ? 'Pickup delayed'
                  : props.status === 'CANCELLED'
                  ? 'Dispatch delayed'
                  :  props.status === 'REACHED' 
                  ?  "reached us" 
                  : props.status.toLowerCase(),
              )}
            </div>
            {/* <StepperComponent status={props.status} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusComponent;
