import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets, PrimaryButton } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './transfer_request.screen.scss';
import { useNavigate } from 'react-router-dom';
import PaymentTransfer from '../../common_components/ui/payment_transfer/payment_transfer.component';
import OutsideClickHandler from 'react-outside-click-handler';
interface ITransferRequest {
  text?: String;
  availableBalance?: number;
  close?: any;
  outSideClick?: any;
  onChange?: any;
}

const TransferRequest = (props: ITransferRequest) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({
    payment_transfer: false,
    amount: '',
  });

  // route

  const navigate = useNavigate();

  //Hooks
  useEffect(() => {}, []);

  // Network req
  const createTransferRequest = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        amount: state.amount,
      };
      const res: any = await Models.payout.createPayout(body);
      setState({ payment_transfer: true });
      window.location.reload();
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  //Logic
  const testLogic = () => {};

  return (
    <div>
      <div className="transfer_container">
        {state.payment_transfer ? (
          <>
            <div className="transfer_wrapper">
              <div className="transfer_image">
                <img src={Assets.success_tick} className="success_tick" />
              </div>
              <div className="transfer_title">Transfer Requested!</div>
              <div className="transfer_message">
                Your money will be credited to your account once the request is
                processed.
              </div>
            </div>
            <div className="transfer_footer">
              <PrimaryButton
                onClick={() => navigate('/payment')}
                text="Payment"
              />
            </div>
          </>
        ) : (
          <>
            <PaymentTransfer
              outSideClick={(value: any) => props.onChange(value)}
              close={props.close}
              availableBalance={props.availableBalance}
              onClick={() => createTransferRequest()}
              value={state.amount}
              onChange={(amount: any) => setState({ amount })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TransferRequest;
