import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import {
  Assets,
  Dropdown,
  Functions,
  PrimaryButton,
} from 'utils/imports.utils';
import './user_details.screen.scss';
import AdminModel from 'admin_panel/imports/models.admin_import';
import _ from 'lodash';
import AdminInviteModal from 'admin_panel/admin_common_components/ui/admin_invite_modal/admin_invite_modal';
import Switch from 'react-switch';

const UserDetails = (props: any) => {

  // query
  const query = useQuery();
  let paramsId: any = query.get('id');
  const addressRef:any = useRef()

  // state
  const [state, setState] = useSetState({
    userData: '',
    bookingData: [],
    role: '',
    area: '',
  });


  // navigate
  const navigate = useNavigate();

  const userRole: any = [
    {
      label: 'Pleater',
      value: 'pleater',
    },
    {
      label: 'User',
      value: 'user',
    },
  ];
  const areaData: any = [
    {
      label: 'Chennai',
      value: 'chennai',
    },
    {
      label: 'Bangalore',
      value: 'bangalore',
    },
    {
      label: 'Coimbatore',
      value: 'coimbatore',
    },
  ];
  // getUserDetails
  const getUserDetails = async () => {
    Functions.notiflixLoader();
    try {
      const body = {
        user_id: paramsId,
      };
      const res: any = await AdminModel.users.getUser(body);
      setState({ userData: res.data ,userDetails:res.data});
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };
  const getManyBooking = async () => {
    Functions.notiflixLoader();

    try {
      const query: any = {};
      if (paramsId) {
        query.user = paramsId;
      }
      const res: any = await AdminModel.bookings.getManybookings(query);
      setState({ bookingData: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const adminEditUser = async () => {
    Functions.notiflixLoader();

    try {
      const query: any = {};
      if (paramsId) {
        query.user_id = paramsId;
      }
      if (state.role) {
        query.role = state.role;
      }
      if (state.area) {
        query.area = state.area;
      }
      const res: any = await AdminModel.users.adminEditUsers(query);
      setState({ userData: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  //  handle pleater availability
  const handleChange = async (availability: boolean) => {
    Functions.notiflixLoader();
    const body = {
      availability,
      user_id:paramsId
    };
    try {
      await AdminModel.users.adminEditUsers(body);
      getUserDetails()
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  useEffect(() => {
    getUserDetails();
    getManyBooking();
  }, []);
  

  useEffect(() => {
    if (state.role || state.area) {
      adminEditUser();
    }
  }, [state.role, state.area]);
  //hooks
  useEffect(() => {
    if (!paramsId) {
      navigate('/users');
    }
    if (!localStorage.token) {
      navigate('/login');
    }
  }, []);
  return (
    <div className="user_detail_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="users_wrapper">
        <AdminHeader text={'User Details'} />
        <div className="user_detail_wrapper">
          <div className="user_header">
            <div className="user_title">
              {state.userData && state.userData?.role === 'pleater'
                ? capitalizeFirstLetter(`${state.userData.role}`)
                : 'User'}
            </div>
            {state.userData && (
              <div className="user_role_area">
                <div className="user_role">
                  <Dropdown
                    value={
                      _.isEmpty(state.userData.role) ? { label: 'User' }: {
                        label: capitalizeFirstLetter(`${state.userData.role}`)}
                    }
                    data={userRole}
                    onChange={(search: any) => setState({ role: search.value })}
                    width={'100%'}
                    borderRadius={'10px'}
                  />
                </div>
                <div className="user_area">
                  <Dropdown
                    value={!_.isEmpty(state.userData?.area) && {label: capitalizeFirstLetter(`${state.userData?.area}`)}}
                    data={areaData}
                    onChange={(search: any) => setState({ area: search.value })}
                    width={'100%'}
                    borderRadius={'10px'}
                    placeholder={'Select Area...'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="user_detail">
            <div className="user_details_content">
              <div className="user_detail_upper">
                <div className="user_date">
                  <div className="user_date_text user_header">Date</div>
                  <div
                    className="user_date_value user_content"
                    style={{ marginTop: '10px' }}>
                    {moment(state.userData.created_at).format('DD-MM-YYYY')}
                  </div>
                </div>
                <div className="user_date">
                  <div className="user_date_text  user_header">Role status</div>
                  <div
                    className="user_date_value  user_content"
                    style={{ marginTop: '10px' }}>
                    {state.userData && state.userData?.role === 'pleater'
                      ? capitalizeFirstLetter(`${state.userData.role}`)
                      : 'User'}
                  </div>
                </div>

                <div className="user_customer">
                  <div className="user_customer_text  user_header">Name</div>
                  <div
                    className="user_customer_value user_content"
                    style={{ marginTop: '10px' }}>
                    {state.userData?.name
                      ? capitalizeFirstLetter(
                          state.userData?.name.toLowerCase(),
                        )
                      : '---'}
                  </div>
                </div>
              </div>

              {state.userData && state.userData?.role === 'pleater' ? (
                ''
              ) : (
                <div className="user_detail_down">
                  <div className="user_order">
                    <div className="user_order_text  user_header">Orders</div>
                    <div className="user_order_value user_content">
                      {state.bookingData?.length}
                    </div>
                  </div>
                </div>
              )}
              
              {state.userData && state.userData?.role === 'pleater' && (
                <div className="user_detail_down">
                  <div className="user_order">
                    <div className="user_order_text  user_header">Availability</div>
                    <div className="user_order_value user_content">
                      <Switch
                        checked={state.userData.availability}
                        onChange={handleChange}
                        onColor="#3CCF4E"
                        onHandleColor="#FFFFFA"
                        handleDiameter={50}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                        height={26}
                        width={50}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="booking_notification_list">
              {state.userData && state.userData?.role === 'pleater' ? (
                ''
              ) : (
                <div className="booking_list">
                  {state.bookingData?.length > 0 ? (
                    <PrimaryButton
                      text={'View Booking List'}
                      fontFamily={'Inter'}
                      fontSize={'12px'}
                      fontWeight={600}
                      width={'100%'}
                      padding={'15px 10px'}
                      onClick={() => navigate(`/bookings?id=${paramsId}`)}
                    />
                  ) : (
                    <PrimaryButton
                      color={'#ffff'}
                      text={'View Booking List'}
                      backgroundColor={'#A1342D'}
                      fontFamily={'Inter'}
                      fontSize={'12px'}
                      fontWeight={600}
                      width={'100%'}
                      padding={'15px 10px'}
                      style={{ cursor: 'default', opacity: '0.3 ' }}
                    />
                  )}
                </div>
              )}
              <div className="booking_notification">
                <PrimaryButton
                  text={'Send Notification'}
                  fontFamily={'Inter'}
                  fontSize={'12px'}
                  fontWeight={600}
                  width={'100%'}
                  padding={'15px 10px'}
                  onClick={() =>
                    navigate(`/notification_admin?id=${paramsId}`)
                  }
                />
              </div>
              {/* change address */}
              {state.userData?.address?.length > 0 && (
              <div className="booking_notification">
                <PrimaryButton
                  text={'Change Address'}
                  fontFamily={'Inter'}
                  fontSize={'12px'}
                  fontWeight={600}
                  width={'100%'}
                  padding={'15px 10px'}
                  onClick={() =>
                    addressRef.current.openModal()
                  }
                />
              </div>
              )}
                {/* change address */}
              {!_.isEmpty(state.userData) && (
              <AdminInviteModal type={'changeAddress'} ref={addressRef}/>
              )}
            </div>
            <div className="booking_pleater_customer_detail">
              <div className="user_details">
                <div className="user_detail_header">
                  {state.userData && state.userData?.role === 'pleater'
                    ? capitalizeFirstLetter(
                        `${state.userData.role.toLowerCase()} Details`,
                      )
                    : 'User Details'}
                </div>
                <div className="user_profile_detail">
                  <img
                    className="user_profile_picture"
                    src={Assets.user_picture}
                    alt="no_icon"
                  />
                  <div className="user_name">
                    {state.userData.name
                      ? capitalizeFirstLetter(
                          state.userData.name.toLowerCase(),
                        )
                      : '---'}
                  </div>
                </div>
                <div className="user_contents">
                  <div className="user_detail_content">
                    <img src={Assets.mobile} alt="no_icon" />
                    <div className="user_mobile">{state.userData?.phone}</div>
                  </div>
                  {!_.isEmpty(state.userData) && (
                    <div className="user_detail_address">
                      {state.userData?.address[0]?.map_address && (
                        <>
                          <img src={Assets.admin_map} alt="no_icon" />
                          <div className="user_address">
                            {state.userData?.address[0]?.map_address}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
