import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import './login.screen.scss';
import {
  OutlineInput,
  Assets,
  PrimaryButton,
  Validation,
  Models,
  Functions,
  CheckBox,
} from '../../utils/imports.utils';
import '../../common_components/hoc/mobile_screen.scss';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';
import SplashScreen from 'components/test/splash_screen/splash.screen';

function Login() {
  // navigate

  const navigate = useNavigate();

  // state
  const [state, setState] = useSetState({
    phone: '',
    errorArray: [],
    disabled: false,
    use_referral:false,
    referral_code:'',
    skip_hide:false
  });

  // api request

  const handleClick = async () => {
    setState({ disabled: true });
    Functions.notiflixLoader();
    try {
      const body:any = {
        phone: `${state.phone}`,
      };
     if (state.use_referral) {
       body.referral_code = state.referral_code;
     }
      await Validation.otpLoginSchema.validate(body, { abortEarly: false });
      body.phone = `+91${state.phone}`;
      const res: any = await Models.auth.login(body);
      navigate(`/otp_verify?id=${res.id}&phone=${state.phone}`);
    } catch (error: any) {
      if (typeof error === 'string') {
        setState({ disabled: false });
        Functions.notiflixFailure(error);
      } else {
        setState({ disabled: false, skip_hide: true });
        setState({
          error: error?.message,
          path: error?.path,
          errorArray: JSON.parse(JSON.stringify(error))?.inner,
        });
        setTimeout(() => {
          setState({ errorArray: '', skip_hide: false });
        }, 2000);
      }
    } finally {
      Functions.notiflixRemove();
    }
  };

  // hooks

 

  return (
    <div className="mobile_screen">
      <div className="login_container ">
        <div className="login_img">
          <img src={Assets.logo1} alt="login_image" />
        </div>
        <div className="login_wrapper">
          <div className="login_heading">Welcome!</div>
          <div className="login_input_field_content">
            Enter your mobile number to send OTP
          </div>
          <div className="login_input_field">
            <OutlineInput
              error={state.errorArray}
              icon={Assets.error}
              label="Mobile"
              type="number"
              name={'phone'}
              value={state.phone}
              onChange={(phone) => {
                setState({ phone });
              }}
            />
          </div>
          <div className="login_screen_checkbox_conatiner">
            <div
              onClick={() => setState({ use_referral: !state.use_referral })}
              style={{ cursor: 'pointer' }}>
              <CheckBox
                checked={state.use_referral}
                active={Assets.checkbox_active}
                disabled={Assets.checkbox_disable}
              />
            </div>
            <div className="referral_code">Use Referral code</div>
          </div>
          {state.use_referral && (
            <div style={{ marginTop: '20px' }} className="login_input_field">
              <OutlineInput
                label="Referral code"
                error={state.errorArray}
                icon={Assets.error}
                type="text"
                name={'referral_code'}
                value={state.referral_code}
                onChange={(referral_code: any) => {
                  setState({ referral_code });
                }}
              />
            </div>
          )}
          <div className="login_button" onClick={() => handleClick()}>
            <PrimaryButton
              disabled={state.disabled}
              style={{
                background:
                  'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
                boxShadow: '-1px 4px 16px -4px rgba(156,49,43,1)',
              }}
              text={'Login'}
            />
          </div>
          {!state.skip_hide && (
            <div className="login_screen_skip_wrapper">
              <div
                onClick={() => navigate('/home')}
                className="login_screen_skip_flex_wrapper">
                <div className="login_screen_skip_content">Skip login</div>
                <div className="login_screen_skip_icon">
                  <img
                    width={16}
                    height={16}
                    src={Assets.arrow}
                    alt="skip_img"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
