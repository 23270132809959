import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import {
  Assets,
  Footer,
  Models,
  SampleWork,
  PrimaryButton,
  OrderStatusComponent,
  Functions,
} from 'utils/imports.utils';
import './home.screen.scss';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';
import { SwiperComponent } from '../../utils/imports.utils';
import LogRocket from 'logrocket';
import '../../common_components/hoc/mobile_screen.scss';
import { COURIER_DELIVERY_TYPES } from 'utils/constant.utils';


const Home = (props: any) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // route
  const navigate = useNavigate();

  // State
  const [state, setState] = useSetState({
    loading: true,
    sample_work: '',
    order_data: '',
    todaydeal_data: [],
    todaydeal_image: [],
    orderLoader: false,
  });

  //Hooks
  useEffect(() => {
    if (localStorage.token) {
      getSampleWorks();
      getBooking();
      getTodaydeal();
    } else {
      getTodaydeal();
    }
  }, []);

  // Network req
  

  const getSampleWorks = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.samplework.getSamplesWork();
      setState({ sample_work: res.data.docs });
      setState({ loading: false });
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getTodaydeal = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.todaydeal.getTodaydeal();
      setState({ todaydeal_data: res.data.docs });
    } catch (error: any) {
      Functions.notiflixFailure(error);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const getBooking = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await Models.orders.getManyOrders();
      let sortedData = await res?.data.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setState({ order_data: sortedData, orderLoader: true });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // logic
  const params = {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el:
        state.todaydeal_data[0]?.image?.length > 1 ? '.swiper-pagination' : '',
      clickable: true,
      renderBullet: (index, className) => {
        return '<span class="' + className + '"></span>';
      },
    },
    noSwiping: state.todaydeal_data[0]?.image?.length > 1 ? false : true,
  };

  const bookingButton = () => {
    navigate('/booking')
  };

  return (
    <div className="mobile_screen">
      {/* {state.orderLoader && ( */}
        <>
          <div className="home_screen_wrapper">
            <div className="home_container">
              <div className="home_header">
              <img className="home_header_logo" src={Assets.header}/>
                </div>
              <div className="home_wrapper">
                <div className="home_deals_container">
                  {state.todaydeal_data.length > 0 && (
                    <div className="home_subheadings_today">Today's Deal</div>
                  )}
                  {state.todaydeal_data.length > 0 && (
                    <>
                      <div className="home_deals_wrapper">
                        <SwiperComponent params={params}>
                          {state.todaydeal_data.map((item: any) => {
                            let image = item.image;
                            return image.map((images: any, index: number) => {
                              return (
                                <div key={index}>
                                  <img
                                    src={images}
                                    alt="today deals"
                                    className="home_screen_saree_image"
                                  />
                                </div>
                              );
                            });
                          })}
                        </SwiperComponent>
                      </div>
                    </>
                  )}
                </div>
                {state.order_data.length > 0 ? (
                  <>
                    <div className="home_order_status_container">
                      {state.order_data.length > 0 ? (
                        <>
                          <div className="home_order_status_header">
                            <div className="home_order_status_heading">
                              Order Status
                            </div>
                            <div
                              onClick={() => navigate('/orders')}
                              className="home_order_status_seeall_text">
                              See all
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="home_order_status_wrapper">
                        {state.order_data &&
                          state.order_data.map((item: any, index: number) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  onClick={() =>
                                    navigate(`/order_details?id=${item._id}`)
                                  }
                                  className={`${
                                    state.order_data.length === 1
                                      ? 'order_status_wrap_full'
                                      : 'order_status_wrap'
                                  }`}>
                                  <OrderStatusComponent
                                    expressDelivery={item.delivery_mode ? item.delivery_mode === COURIER_DELIVERY_TYPES.EXPRESS_DELIVERY ? true : false : false}
                                    className={`${
                                      state.order_data.length === 1
                                        ? 'saree_image_full'
                                        : 'saree_image'
                                    }`}
                                    quantity={item.quantity}
                                    requestId={item.id}
                                    status={item.status}
                                    data={item.pickup_sarees}
                                  />
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>

                    <div className="">
                      {state.sample_work.length > 0 && (
                        <div className="home_sample_container">
                          <div className="home_subheadings">
                            Our sample works
                          </div>
                          <div className="home_screen_sample_work_wrapper">
                            <div className="home_sample_wrapper">
                              {state.sample_work &&
                                state.sample_work.map(
                                  (item: any, index: number) => {
                                    return (
                                      <div key={index}>
                                        <SampleWork
                                          title={item.title}
                                          source={item.images}
                                          type={item.text}
                                        />
                                      </div>
                                    );
                                  },
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="order_empty">
                    <img src={Assets.order} height={200} width={200} />
                    <div className="order_empty_text">
                      <div>No available bookings</div>
                    </div>
                  </div>
                )}
                <PrimaryButton
                  onClick={() => bookingButton()}
                  className={'booking_button'}
                  icon={Assets.booking_icon}
                  text={'Book Now'}
                  style={{
                    background:
                      'linear-gradient(274.76deg, #9C312B -6.73%, #CC4941 72.34%)',
                    boxShadow: '-1px 4px 16px -1px rgba(156,49,43,1)',
                  }}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      {/* )} */}
    </div>
  );
};

export default Home;
