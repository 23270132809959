import Assets from 'imports/assets.import';
import React, { useRef } from 'react';
import { Functions, InviteModal, Models } from 'utils/imports.utils';
import './order_details_card_component.scss';
import { useSetState } from 'utils/functions.utils';

interface IOrderCardDetails {
  data: any;
  saree_num: number;
  saree_data: any;
  id: any;
}
const OrderDetailsCard = (props: IOrderCardDetails) => {
  

  //ref
  const notesRef: any = useRef();

  // state
  const [state, setState] = useSetState({
    notes: props.saree_data.notes,
  });
  const updateOrder = async () => {
    Functions.notiflixLoader('updating...');
    if (state.notes.length === 0)
      Functions.notiflixFailure('notes is empty'), Functions.notiflixRemove();
    else {
      try {
        const body: object = {
          booking_id: props.id,
          pickup_saree_id: props.saree_data._id,
          notes: state.notes,
        };
        await Models.orders.editNotes(body);
        notesRef.current.closeModal();
        window.location.reload();
      } catch (err: any) {
        Functions.notiflixFailure('Failed to edit notes');
        console.log('err', err);
      } finally {
        Functions.notiflixRemove();
      }
    }
  };
  return (
    <div>
      <div
        style={{
          width: `${props.data.pickup_sarees.length === 1 ? '350px' : '300px'}`,
        }}
        className="order_details_screen_container">
        <div className="order_details_screen_wrapper">
          <div className="order_details_saree_heading">
            Saree{' '}
            {props.saree_num >= 9
              ? props.saree_num + 1
              : `0${props.saree_num + 1}`}
          </div>
          <div className="order_details_screen_image_header">Saree image</div>
          <div className="order_details_screen_image_flex_wrapper">
            {props.saree_data?.images?.map((image: any, index: number) => {
              return (
                <div key={index}>
                  <img
                    className="order_details_saree_image_property"
                    width={'42px'}
                    height={'42px'}
                    src={image}
                    alt="saree_image"
                  />
                </div>
              );
            })}
          </div>
          <div className="order_details_screen_info_wrapper">
            <div className="order_details_screen_info_wrapper_heading">
              Additional info
            </div>
            <div className="order_details_screen_info_list_flex_wrapper">
              <div className="order_details_screen_info_list_heading">
                Hip size
              </div>
              {props.saree_data?.body_size ? (
                <div className="order_details_screen_info_list_answer">
                  {props.saree_data?.body_size}
                </div>
              ) : (
                <>-</>
              )}
            </div>
            <div className="order_details_screen_info_list_flex_wrapper">
              <div className="order_details_screen_info_list_heading">
                Body height
              </div>
              {props.saree_data?.body_height ? (
                <>
                  <div className="order_details_screen_info_list_answer">
                    {props.saree_data?.body_height}
                  </div>
                </>
              ) : (
                <>-</>
              )}
            </div>
            <div className="order_details_screen_info_list_flex_wrapper">
              <div className="order_details_screen_info_list_heading">
                Number of pleats
              </div>
              {props.saree_data?.number_of_pleat ? (
                <>
                  <div className="order_details_screen_info_list_answer">
                    {props.saree_data?.number_of_pleat}
                  </div>
                </>
              ) : (
                <>-</>
              )}
            </div>
            {props.saree_data.notes?.length > 0 ? (
              <>
                <div className="order_details_screen_notes_wrapper">
                  <div className="order_details_screen_notes_flex_wrapper">
                    <div className="order_details_screen_notes_heading">
                      Notes
                    </div>
                    <div
                      onClick={() => notesRef.current.openModal()}
                      className="order_details_screen_edit_notes_wrapper">
                      <img
                        width={'18px'}
                        height={'18px'}
                        src={Assets.edit}
                        alt="edit_icon"
                      />
                    </div>
                  </div>
                  <div className="order_details_screen_notes_answer">
                    {props.saree_data?.notes}
                  </div>
                </div>
                <InviteModal
                  onClick={() => updateOrder()}
                  value={state.notes}
                  onChange={(value: any) => setState({ notes: value })}
                  ref={notesRef}
                  type={'edit_notes'}
                />
              </>
            ) : (
              <>
                <div className="order_details_screen_notes_wrapper">
                  <div className="order_details_screen_notes_flex_wrapper">
                    <div className="order_details_screen_notes_heading">
                      Notes
                    </div>
                    <div>-</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
