import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  useQuery,
  useSetState,
} from 'utils/functions.utils';
import { Assets, Dropdown, Functions, PrimaryButton } from 'utils/imports.utils';
import './booking_details.screen.scss';
import AdminModel from 'admin_panel/imports/models.admin_import';
import AdminInviteModal from 'admin_panel/admin_common_components/ui/admin_invite_modal/admin_invite_modal';
import _ from 'lodash';
import { sareeRate } from 'utils/constant.utils';

const BookingDetails = (props: any) => {
  // ref
  const ModalRef: any = useRef();
  const cancelRef:any = useRef();
  const addressRef:any = useRef();
  // navigate
  const navigate = useNavigate();
  // query
  const query = useQuery();
  let bookingParamId: any = query.get('id');

  // bookingStatus
  const bookingStatus: any = [
  
    {
      value: 'ORDER PLACED',
      label: 'Order Placed',
    },
    {
      value: 'WAITING FOR PICKUP',
      label: 'Waiting For Pickup',
    },
    {
      value: 'PICKED UP',
      label: 'Picked Up',
    },
    {
      value: 'REACHED',
      label: 'Reached',
    },
    {
      value: 'WAITING FOR DISPATCH',
      label: 'Waiting For Dispatch',
    },
    {
      value: 'DISPATCH',
      label: 'Dispatch',
    },
    {
      value: 'DELIVERED',
      label: 'Delivered',
    },
    {
      value: 'REFUND INITIATED',
      label: 'Refund Initiated',
    },
    {
      value: 'REFUNDED',
      label: 'Refund',
    },
    {
      value: 'ORDER CANCELLED',
      label: 'Order Cancelled',
    },
    {
      value: 'CANCELLED',
      label: 'Cancelled',
    },
    {
      value: 'QUEUED',
      label: 'Queued',
    },
  ];

  // state
  const [state, setState] = useSetState({
    booking_data: [],
    pleater_data: [],
    pleater_params_id: '',
    get_delivery_addres: false,
    bookingStatus:{},
    bookingUpdate:false,
    vendor:'',
    quantity_count:'',
  });



  // getBookingDetails
  const getBookingDetails = async (data?: any) => {
    if (!data) {
      Functions.notiflixLoader();
    }
    try {
      const body = {
        booking_id: bookingParamId,
      };
      const res: any = await AdminModel.bookings.getBookingID(body);
      setState({ booking_data: res.data, bookingStatus: res.data.status ,bookingUpdate:false,quantity_count:res.data.quantity });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // getAllUsers
  const getAllUsers = async () => {
    Functions.notiflixLoader();
    try {
      const res: any = await AdminModel.users.getAllUsers();
      const data = _.filter(res.data, ['role', 'pleater']);
      setState({ pleater_data: data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // reshudule
  const rescheduleExisting = async () => {
    if(_.isEmpty(state.vendor)) {
      Functions.notiflixFailure('Please select vendor')
    }
    else {
      Functions.notiflixLoader();
      try {
        const body = {}
        if(state.vendor !== 'AUTO') body['vendor'] = state.vendor;
        const res: any = await AdminModel.bookings.rescheduleExisting(body,bookingParamId);
        Functions.notiflixSuccess(res.message);
      } catch (err: any) {
        Functions.notiflixFailure(err);
      } finally {
        Functions.notiflixRemove();
      }
    }
  };

  // adminAssignPleater
  const adminAssignPleater = async (pleater_id?: any) => {
    if (_.isEmpty(state.vendor)) {
      Functions.notiflixFailure('Please select vendor');
    } else {
      Functions.notiflixLoader();
      try {
        const body: any = {
          booking_id: bookingParamId,
        };
        if (pleater_id) {
          body.pleater_id = pleater_id;
        }
        if (state.vendor !== 'AUTO') body['vendor'] = state.vendor;
        const res: any = await AdminModel.bookings.adminAssignPleater(body);
        Functions.notiflixSuccess(res.message);
      } catch (err: any) {
        Functions.notiflixFailure(err);
      } finally {
        Functions.notiflixRemove();
      }
    }
  };

  // editBooking
  const editBooking = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {
        booking_id: bookingParamId,

      };
      if (state.bookingStatus) {
        body.status=state.bookingStatus ;
      }
      const res: any = await AdminModel.bookings.editBooking(body);
      setState({ booking_data: res.data, bookingStatus: res.data.status ,bookingUpdate:false });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  // dropdownStyle
 const backgroundColor = (query: any) => {
   switch (state.bookingStatus) {
     case 'DELIVERED':
     case 'REACHED':
       if (query === 'bg') {return 'rgba(67, 174, 129, 0.1)'}
       if (query === 'color') {return '#43ae81'}
       break;
     case 'ORDER PLACED':
     case 'WAITING FOR PICKUP':
     case 'WAITING FOR DISPATCH':
     case 'PICKED UP':
     case 'DISPATCH':
       if (query === 'bg') {return 'rgba(253, 159, 0, 0.1)'}
       if (query === 'color') {return '#fd9f00'}
       break;
     case 'CANCELLED':
     case 'ORDER CANCELLED':
     case 'REFUND INITIATED':
     case 'REFUNDED':
       if (query === 'bg') {return 'rgba(244, 81, 30, 0.1)'}
       if (query === 'color') {return '#f4511e'}
       break;
     case 'QUEUED':
       if (query === 'bg') {return 'rgba(251, 192, 45, 0.1)'}
       if (query === 'color') {return '#fbc02d'}
       break;
   }
 };

 const pleaterAddress = () => {
   return `${state.booking_data.pleater?.address[0].house_number}, ${state.booking_data.pleater?.address[0].apartment}`
 }
 const userAddress = ()=>{
  return `${state.booking_data.user?.address[0].house_number}, ${state.booking_data.user?.address[0].apartment}`
}

  // hooks
  useEffect(() => {
    let interval = setInterval(() => {
      setState({ timeout: Math.random() });
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  // Refresh useEffect
  useEffect(() => {
    if(state.bookingStatus === 'REFUNDED' || state.bookingStatus === 'REFUND INITIATED' 
    || state.bookingStatus === 'ORDER CANCELLED'){
      getBookingDetails('timeout');
    }
  }, [state.timeout]);

  //hooks
  useEffect(() => {
    getBookingDetails();
  }, []);

  const cancelBooking = async () => {
    Functions.notiflixLoader();
    try {
      await AdminModel.bookings.cancelBooking(state.booking_data.delivery_task_id);
      window.location.reload();
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const quantityEdit = async () => {
    Functions.notiflixLoader();
    try {
      if (state.quantity_count) {
        const body = {
          booking_id: bookingParamId,
          quantity: state.quantity_count,
          quantity_change: true,
        };
        const res: any = await AdminModel.bookings.editBooking(body);
        setState({ booking_data: res.data, bookingStatus: res.data.status, quantity_count:res.data.quantity });
      }
    } catch (err: any) {
      Functions.notiflixFailure(err);
      console.log('err',err)
    }
    finally {
      Functions.notiflixRemove();
    }
  };

  const showQuantitPrice = () => {
    let result:any
    if (_.has(state.booking_data, 'coupon')) {
      result = `${state.booking_data.quantity} * ${sareeRate.firstOne} = ${
        state.booking_data.amount
      } (${Math.round(state.booking_data.coupon.offer_value)}%)`;
    }
    else {
      result = `${state.booking_data.quantity} * ${sareeRate.firstOne}`;
    }
    return result;
  };

  const vendorIcon = (item:any)=>{ 
    if(item) {
     if(item.vendor === 'DUNZO') return Assets.dunzo_icon
      else return Assets.porter_icon
    }
  }


  useEffect(() => {
    if (!bookingParamId) {
      navigate('/bookings');
    }
    if (!localStorage.token) {
      navigate('/login');
    }
  }, []);

  useEffect(()=>{
    if (state.bookingUpdate && state.bookingStatus) {
    editBooking()
    }
  },[state.bookingUpdate])

  return (
    <div className="booking_detail_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="bookings_wrapper">
        <AdminHeader text={'Booking Details'} />
        <div className="booking_detail_wrapper">
          <div className="booking_header_id">
            Booking ID {state.booking_data?.id}
          </div>
          <div className="booking_detail">
            <div className="booking_details_content">
              <div className="booking_details">
                <div className="booking_details_user">
                  <div className="booking_pleater">
                    <div className="booking_pleater_text  booking_header">
                      Pleater
                    </div>
                    <div
                      className="booking_pleater_value booking_content"
                      style={{ marginTop: '17px' }}>
                      {state.booking_data.pleater?.name}
                    </div>
                  </div>
                  <div className="booking_date">
                    <div className="booking_date_text booking_header">Date</div>
                    <div
                      className="booking_date_value booking_content"
                      >
                      {moment(state.booking_data.created_at).format(
                        'DD-MM-YYYY',
                      )}
                    </div>
                  </div>
                  {state.booking_data &&
                    _.has(state.booking_data, 'tracking_url') && (
                      <div style={{marginTop:'10px'}} className="booking_date">
                        <div className="booking_date_text booking_header">
                          Tracking url
                        </div>
                        <div
                          className="booking_date_value booking_content"
                          style={{ marginTop: '0px' }}>
                          <a
                            target="_blank"
                            href={state.booking_data.tracking_url}>
                            {state.booking_data.tracking_url}
                          </a>
                        </div>
                      </div>
                    )}
                    <div>
                    <div className='booking_delivery_address_text  booking_header' style={{marginBottom:'15px',marginTop:'10px'}}>
                     Quantity
                  </div>
                  <div  className='quantity_wrapper'>
                    <input type='number' value={state.quantity_count} onChange={(e:any)=>setState({quantity_count:e.target.value})} className='quantity_input' />
                    <div onClick={()=>quantityEdit()}  className='quantity_button'>
                      Update
                    </div>
                  </div>
                    </div>
                </div>

                <div className="booking_details_address">
                  <div className="booking_customer_status">
                    <div className="booking_customer">
                      <div className="booking_customer_text  booking_header">
                        Customer
                      </div>
                      <div
                        className="booking_customer_value booking_content"
                        style={
                          state.booking_data.user?.name
                            ? { marginTop: '17px' }
                            : { margin: '17px 0 0 12px' }
                        }>
                        {state.booking_data.user?.name
                          ? state.booking_data.user?.name.split(' ')[0]
                          : '-----'}
                      </div>
                    </div>
                    <div className="booking_status">
                      <div className="booking_status_text  booking_header">
                        Booking status
                      </div>

                      {state.bookingStatus && (
                        <Dropdown
                          value={
                            !_.isEmpty(state.bookingStatus) && {
                              label: capitalizeFirstLetter(
                                state.bookingStatus.toLowerCase(),
                              ),
                            }
                          }
                          data={bookingStatus}
                          onChange={(search: any) =>
                            setState({
                              bookingStatus: search.value,
                              bookingUpdate: true,
                            })
                          }
                          width={'100%'}
                          fontSize={'12px'}
                          fontFamily={'Inter'}
                          fontWeigth={600}
                          placeholder={'Select Status...'}
                          height={'auto'}
                          color={backgroundColor('color')}
                          borderRadius={'5px'}
                          border={'none'}
                          background={backgroundColor('bg')}
                        />
                      )}
                    </div>
                  </div>

                  <div  className="booking_delivery_address">
                    <div className="booking_delivery_address_text  booking_header">
                      Delivery Address
                    </div>
                    <div className="booking_delivery_address_value">
                      {state.booking_data.user_address?.map_address}
                    </div>
                  </div>
                </div>
              </div>
              <div className="booking_detail_order_summary">
                <div className="order_summary_header">Order Summary</div>
                <div className="order_summary_wrapper">
                  {state.booking_data.pickup_sarees?.map(
                    (data: any, index: number) => (
                      <div key={index}>
                        {index === 0 &&
                          data.images?.map((item: any, index: number) => {
                            return (
                              <div className="order_summary_img" key={index}>
                                {index === 0 ? (
                                  <img src={item} alt="no_icon" />
                                ) : null}
                              </div>
                            );
                          })}
                      </div>
                    ),
                  )}

                  <div className="order_summary_content">
                    <div className="order_summary_content_header">
                      Silk Saree
                    </div>
                    <div className="order_summary_quantity">
                      <div className="order_summary_quantity_header">
                        Quantity
                      </div>
                      <div className="order_items_value">
                        <div className="order_items">
                          {state.booking_data.quantity}
                        </div>
                        <div className="order_items_amount">
                          {state.booking_data && showQuantitPrice()}
                        </div>
                        <div className="order_amount">
                          &#8377;
                          {
                            state.booking_data.amount}
                        </div>
                      </div>
                    </div>
                    <div className="order_summary_price">
                      <div className="order_summary_price_header">Price</div>
                      <div className="order_price">
                        &#8377;{state.booking_data.amount}
                      </div>
                    </div>

                    <div className="line"></div>
                    <div className="order_summary_total">
                      <div className="empty"></div>
                      <div className="order_summary_total_title">Total</div>
                      <div className="order_summary_total_price">
                        &#8377;{' '}
                        {
                          state.booking_data.amount}
                      </div>
                    </div>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking_reschedule">
              <div className="booking_reschedule_button">
                {state.bookingStatus === 'REFUNDED' ||
                state.bookingStatus === 'REFUND INITIATED' ||
                state.bookingStatus === 'ORDER CANCELLED' ||
                state.bookingStatus === 'CANCELLED' ? (
                  <PrimaryButton
                    icon={Assets.reschedule}
                    color={'#ffff'}
                    text={'Reschedule Order'}
                    backgroundColor={'#A1342D'}
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    fontWeight={600}
                    width={'100%'}
                    padding={'15px 10px'}
                    onClick={() => {
                      ModalRef.current.openModal(), getAllUsers();
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <PrimaryButton
                    icon={Assets.reschedule}
                    color={'#ffff'}
                    text={'Reschedule Order'}
                    backgroundColor={'#A1342D'}
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    fontWeight={600}
                    width={'100%'}
                    padding={'15px 10px'}
                    style={{ cursor: 'default', opacity: '0.3 ' }}
                  />
                )}
                <div style={{ width: '80%' }}>
                  {state.bookingStatus === 'REFUNDED' ||
                  state.bookingStatus === 'REFUND INITIATED' ||
                  state.bookingStatus === 'ORDER CANCELLED' ||
                  state.bookingStatus === 'CANCELLED' ? (
                    <AdminInviteModal
                      pleater_id={state.booking_data.pleater?._id}
                      reschedule={rescheduleExisting}
                      adminReschedule={adminAssignPleater}
                      type={'reshedule_model'}
                      ref={ModalRef}
                      fontFamily={'Inter'}
                      fontSize={'14px'}
                      fontWeight={600}
                      width={'100%'}
                      padding={'15px 10px'}
                      pleater={state.pleater_data}
                      onClick={() => ModalRef.current.openModal()}
                      style={{ cursor: 'pointer', opacity: '1' }}
                      vendor={(vendor: any) =>
                        setState({ vendor: vendor.value })
                      }
                    />
                  ) : null}
                </div>
              </div>
              <div className="booking_refund_button">
                {state.bookingStatus === 'REFUNDED' ||
                state.bookingStatus === 'REFUND INITIATED' ||
                state.bookingStatus === 'ORDER CANCELLED' ? (
                  <PrimaryButton
                    color={'#000000'}
                    backgroundColor={'#E4E4E4'}
                    text={'Refund'}
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    fontWeight={600}
                    width={'100%'}
                    padding={'15px 10px'}
                    style={{ cursor: 'pointer', opacity: '1' }}
                  />
                ) : (
                  <PrimaryButton
                    color={'#000000'}
                    backgroundColor={'#E4E4E4'}
                    text={'Refund'}
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    fontWeight={600}
                    width={'100%'}
                    padding={'15px 63px'}
                    style={{ cursor: 'default', opacity: '0.3 ' }}
                  />
                )}
                {state.bookingStatus !== 'DELIVERED' && (
                  <PrimaryButton
                    icon={Assets.reschedule}
                    color={'#ffff'}
                    text={'Cancel'}
                    backgroundColor={'#A1342D'}
                    fontFamily={'Inter'}
                    fontSize={'14px'}
                    fontWeight={600}
                    width={'100%'}
                    padding={'15px 10px'}
                    onClick={() => cancelRef.current.openModal()}
                    style={{ cursor: 'pointer', marginTop: '20px' }}
                  />
                )}
                <AdminInviteModal
                  type={'cancel_booking'}
                  ref={cancelRef}
                  fontFamily={'Inter'}
                  fontSize={'14px'}
                  fontWeight={600}
                  width={'100%'}
                  padding={'15px 10px'}
                  onClick={() => cancelBooking()}
                  style={{ cursor: 'pointer', opacity: '1' }}
                />
              </div>
            </div>
            <div className="booking_pleater_customer_detail">
              <div className="booking_pleater_details">
                <div className="booking_pleater_header">Pleater Details</div>
                <div className="booking_profile_detail">
                  <img
                    className="booking_profile_picture"
                    src={Assets.user_picture}
                    alt="no_icon"
                  />
                  <div className="booking_name">
                    {state.booking_data.pleater?.name}
                  </div>
                </div>
                <div className="booking_contents">
                  <div className="booking_detail_content">
                    <img src={Assets.mobile} alt="no_icon" />
                    <div className="booking_mobile">
                      {state.booking_data.pleater?.phone}
                    </div>
                  </div>
                  <div className="booking_detail_address">
                    <img src={Assets.admin_map} alt="no_icon" />
                    <div className="booking_address">
                      {state.booking_data && pleaterAddress()}
                    </div>
                  </div>
                  <div className="booking_detail_address">
                    <img src={Assets.admin_map} alt="no_icon" />
                    <div className="booking_address">
                      {state.booking_data.pleater_address?.map_address}
                    </div>
                  </div>
                </div>
              </div>
              <div className="booking_customer_details">
                <div className="booking_customer_header">Customer Details</div>
                <div className="booking_profile_detail">
                  <img
                    className="booking_profile_picture"
                    src={Assets.user_picture}
                    alt="no_icon"
                  />
                  <div className="booking_name">
                    {state.booking_data.user?.name}
                  </div>
                </div>
                <div className="booking_contents">
                  <div className="booking_detail_content">
                    <img src={Assets.mobile} alt="no_icon" />
                    <div className="booking_mobile">
                      {state.booking_data.user?.phone}
                    </div>
                  </div>
                  <div className="booking_detail_address">
                    <img src={Assets.admin_map} alt="no_icon" />
                    <div className="booking_address">
                      {state.booking_data && userAddress()}
                    </div>
                  </div>
                  <div className="booking_detail_address">
                    <img src={Assets.admin_map} alt="no_icon" />
                    <div className="booking_address">
                      {state.booking_data?.user?.address[0]?.map_address}
                    </div>
                  </div>
                  <div className="view_direction">
                    {state.booking_data.pleater?.name ? (
                      <PrimaryButton
                        icon={Assets.live_location_active}
                        color={'#ffff'}
                        text={'View Direction'}
                        backgroundColor={'#A1342D'}
                        fontFamily={'Inter'}
                        fontSize={'14px'}
                        fontWeight={600}
                        width={'100%'}
                        padding={'15px 10px'}
                        onClick={() => {
                          navigate(
                            `/live_location?id=${bookingParamId}&key=${true}`,
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <PrimaryButton
                        color={'#ffff'}
                        text={'View Booking List'}
                        backgroundColor={'#A1342D'}
                        fontFamily={'Inter'}
                        fontSize={'12px'}
                        fontWeight={600}
                        width={'100%'}
                        padding={'15px 10px'}
                        style={{ cursor: 'default', opacity: '0.3 ' }}
                      />
                    )}
                  </div>
                  {state.booking_data && state.booking_data.status === 'CANCELLED' || 
                   state.booking_data.status === 'ORDER CANCELLED' && (
                  <div className='view_direction' style={{marginTop:'15px'}}>
                  <PrimaryButton
                        icon={Assets.location_white}
                        color={'#ffff'}
                        text={'Change Address'}
                        backgroundColor={'#A1342D'}
                        fontFamily={'Inter'}
                        fontSize={'14px'}
                        fontWeight={600}
                        width={'100%'}
                        padding={'15px 10px'}
                        onClick={() => {
                           addressRef.current.openModal()
                        }}
                        style={{ cursor: 'pointer' }}
                      />

                      {!_.isEmpty(state.booking_data) && (
                      <AdminInviteModal userId ={state.booking_data?.user?._id}  ref={addressRef} type={'changeAddress'}/>
                      )}
                  </div>
                  )}
                </div>
              </div>
              {!_.isEmpty(state.booking_data.delivery_partners_list) && (
              <div className="booking_customer_details">
                <div className="booking_customer_header">Delivery Partners</div>
                {state.booking_data &&
                  _.has(state.booking_data, 'delivery_partners_list') &&
                  _.chain(state.booking_data.delivery_partners_list).uniqBy((e:any)=>e.mobile).value().map(
                    (item: any, index: number) => {
                      return (
                        <div
                          style={{
                            marginTop: `${index !== 0 ? '30px' : '0px'}`,
                          }}>
                          <div className="booking_profile_detail">
                            <img
                              className="booking_profile_picture"
                              src={Assets.user_picture}
                              alt="no_icon"
                            />
                            <div className="booking_name">{item.name}</div>
                          </div>
                          <div className="booking_contents">
                            <div className="booking_detail_content">
                              <img src={Assets.mobile} alt="no_icon" />
                              <div className="booking_mobile">
                                {item.mobile}
                              </div>
                            </div>
                            <div className="booking_detail_address">
                              <img width={18} height={18} src={state.booking_data && vendorIcon(item)} alt="no_icon" />
                              <div className="booking_address">
                                {item.vendor}
                              </div>
                            </div>
                            {item.booking_type && (
                              <div className="booking_detail_address">
                                <img width={18} height={18} src={Assets.pickup_icon} alt="no_icon" />
                                <div className="booking_address">
                                  {item.booking_type}
                                </div>
                              </div>
                            )}
                            {item.created_at && (
                              <div className="booking_detail_address">
                                <img src={Assets.clock} alt="no_icon" />
                                <div className="booking_address">
                                  {moment(item.created_at).format(
                                    'DD-MM-YYYY h:mm a',
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    },
                  )}
              </div>
               )}
              {!_.isEmpty(state.booking_data.cancel_reasons) && (
              <div className="booking_customer_details">
                <div className="booking_customer_header">Cancel Reasons</div>
                {
               state.booking_data.cancel_reasons.map((item: any, index: number) => {
                return (
                  <div className={`${index !== 0 && 'border_cancel_reasons'}`} style={{marginTop:`${index !== 0 ? '5px':'0px'}`}} key={index}>
                  <div className="cancel_reasons_wrapper">
                    <div className="cancel_reasons_text">
                      <img width={18} height={18} src={Assets.reason_icon} />
                    </div>
                    <div className="cancel_reasons_text">{item.reason}</div>
                  </div>
                  <div style={{ marginTop: "5px" }} className="cancel_reasons_wrapper">
                    <div className="cancel_reasons_text">
                      <img width={18} height={18} src={state.booking_data && vendorIcon(item)} />
                    </div>
                    <div className="cancel_reasons_text">{item.vendor.toLowerCase()}</div>
                  </div>
                  <div style={{ marginTop: "5px" }} className="cancel_reasons_wrapper">
                    <div className="cancel_reasons_text">
                      <img width={18} height={18} src={Assets.clock} />
                    </div>
                    <div className="cancel_reasons_text">{moment(item.created_at).format('DD-MM-YYYY HH:mm a')}</div>
                  </div>
                </div>
             );
           })
          }
        </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
