import Sidebar from '../../admin_common_components/ui/sidebar/sidebar_component.ui';
import './dashboard.admin_screen.scss';
import {  Functions } from '../../../utils/imports.utils';
import AdminHeader from '../../admin_common_components/ui/admin_header/admin_header';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminModel from 'admin_panel/imports/models.admin_import';
import { useSetState } from 'utils/functions.utils';
import Barchart from 'admin_panel/admin_component/bar_chart/bar_chart.component';
import { useSelector } from 'react-redux';
import moment from 'moment';

interface IDashboard {
  text?: String;
}
function Dashboard(props: IDashboard) {
  const navigate:any = useNavigate();
  const date: any = useSelector((store: any) => store.date);
  const barchartRef:any = useRef();

  // state
  const [state, setState] = useSetState({
    dashboardData: {},
    labels:[]
  });

  // getManyDashboard
  const getManyDashboard = async () => {
    Functions.notiflixLoader();
    try {
      const body: any = {};
      if (date.startDate && date.endDate) {
        body.startDate = moment(date.startDate).startOf('day').toString();
        body.endDate = moment(date.endDate).endOf('day').toString();
      } else if (date.startDate) {
        body.startDate = moment(date.startDate).startOf('day').toString();
        body.endDate = moment().endOf('day').toString();
      } else if (date.endDate) {
        body.startDate = moment().startOf('day').toString();
        body.endDate = moment(date.endDate).endOf('day').toString();
      }
      const res: any = await AdminModel.dashboard.getManyDashboard(body);
      setState({ dashboardData: res.data });
    } catch (err: any) {
      Functions.notiflixFailure(err);
    } finally {
      Functions.notiflixRemove();
    }
  };

  const dashboardData: any = [
    { label: 'Users', countValue: state.dashboardData.totalUsers },
    { label: 'Orders', countValue: state.dashboardData.totalOrders },
    { label: 'Sarees', countValue: state.dashboardData.totalSarees },
    { label: 'Revenue', countValue: '₹' + state.dashboardData && state.dashboardData.totalRevenues },
  ];

  const barChartLabels = () => {
    if (date.startDate  || date.endDate) {
      let months: any = [];
      const startDate = date.startDate ? moment(date.startDate) : moment();
      const endDate = date.endDate ? moment(date.endDate) : moment();
      if (startDate < endDate) {
        let date = startDate.startOf('month');
        while (date < endDate.endOf('month')) {
          months.push(date.format('MM/YY'));
          // add next month
          date.add(1, 'month');
        }
      }
      setState({ labels: months });

    } else {
      let labels:any  = []
      let num = 0
      while (num < 12) {
        const monthAndYear = moment().month(num).format('MM/YY')
        labels.push(monthAndYear)
        num++
      }
      setState({ labels });
    }
  };

  // barchartData
  const barchartData: any = () => {
    if (
      state.dashboardData && state.dashboardData.barchartOrders &&
      state.dashboardData.barchartUsers && state.dashboardData.barchartRevenues &&
      state.dashboardData.barchartSarees
    ) {
      let barchartOrders: any = [];
      let barchartUsers: any = [];
      let barchartRevenues: any = [];
      let barchartSarees: any = [];

      for (let key of state.labels) {
        if (state.dashboardData.barchartUsers[key]) {
          barchartUsers.push(state.dashboardData.barchartUsers[key]);
        } else {
          barchartUsers.push(0);
        }
        if (state.dashboardData.barchartOrders[key]) {
          barchartOrders.push(state.dashboardData.barchartOrders[key]);
        } else {
          barchartOrders.push(0);
        }
        if (state.dashboardData.barchartRevenues[key]) {
          barchartRevenues.push(state.dashboardData.barchartRevenues[key]);
        } else {
          barchartRevenues.push(0);
        }
        if (state.dashboardData.barchartSarees[key]) {
          barchartSarees.push(state.dashboardData.barchartSarees[key]);
        } else {
          barchartSarees.push(0);
        }
      }
      let data: any = {
        barchartRevenues: barchartRevenues,
        barchartSarees: barchartSarees,
        barchartOrders: barchartOrders,
        barchartUsers: barchartUsers,
      };
      return data;
    }
  };

  useEffect(() => {
    if (!localStorage.token) {
      navigate('/login');
    }
  }, []);
  
  useEffect(() => {
    getManyDashboard()
    barChartLabels()
  }, [date.startDate, date.endDate]);

  return (
    <div className="dashboard_container">
      <div className="sidebar-header">
        <Sidebar />
      </div>
      <div className="dashboard_wrapper">
        <AdminHeader text={'Dashboard'} />
        <div className="dashboard_counter_barchart">
          <div className="counter_section">
            <div className="count_value_wrapper">
              {dashboardData.map((item: any, index: number) => (
                <div className={index===3?'card_wrapper_active card_border':'card_wrapper_active'} key={index}>
                <div className="card_header">{item.label}</div>
                <div className="card_counter">{item.countValue}</div>
              </div>
              
              ))}
            </div>
          </div>
          <div className="barchart_container">
            <div className="barchart_wrapper">
              <div className="barchart_panel">
                <div className="barchart_subheading">Users</div>
                {barchartData()?.barchartUsers&&
                <Barchart labels={state.labels} ref={barchartRef} data={barchartData().barchartUsers} />}
              </div>
              <div className="barchart_panel">
                <div className="barchart_subheading">Orders</div>
                {barchartData()?.barchartOrders&&
                <Barchart labels={state.labels} ref={barchartRef} data={barchartData().barchartOrders} />}
              </div>
            </div>
            <div className="barchart_wrapper">
              <div className="barchart_panel">
                <div className="barchart_subheading">Sarees</div>
                {barchartData()?.barchartSarees&&
                <Barchart labels={state.labels} ref={barchartRef} data={barchartData().barchartSarees} />}
              </div>
              <div className="barchart_panel">
                <div className="barchart_subheading">Revenues</div>
                {barchartData()?.barchartRevenues&&
                <Barchart labels={state.labels} ref={barchartRef} data={barchartData().barchartRevenues} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
